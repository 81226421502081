import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { MessagesBox } from './MessagesBox';

const queryClient = new QueryClient();

export function ChatBotWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <MessagesBox />
    </QueryClientProvider>
  );
}
