/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ReactElement } from 'react';
import {
  FieldValues, Path, useFormContext,
} from 'react-hook-form';

import { BaseError } from '../Errors';

interface RadioCardProps<T extends FieldValues> {
  id: string
  label: string
  value: string
  path: Path<T>
  iconOptions: {
    width: number
    height: number
    alt: string
    src: string
  }
}

export type RadioCardOption<T extends FieldValues> = Omit<RadioCardProps<T>, 'path'>;

function RadioCard<T extends FieldValues>({
  id, label, path, value, iconOptions,
}: RadioCardProps<T>) {
  const { register } = useFormContext<T>();
  const {
    alt, height, src, width,
  } = iconOptions;

  return (
    <label htmlFor={id} className="d-flex gap-4 card-radio-button w-100">
      <input type="radio" id={id} className="form-check-input" value={value} {...register(path)} />

      <div className="form-check-label d-flex flex-column gap-2">
        <img className="mt-3" src={src} alt={alt} width={width} height={height} />
        <span>{label}</span>
      </div>
    </label>
  );
}

interface RadioCardFieldProps<T extends FieldValues> {
  label: string
  options: RadioCardOption<T>[]
  path: Path<T>,
  renderPopover?: () => JSX.Element
  cardWrapper?: (card: ReactElement<RadioCardProps<T>>) => JSX.Element
  labelClassName?: string
}

export function RadioCardField<T extends FieldValues>({
  label, path, options, cardWrapper, labelClassName, renderPopover,
}: RadioCardFieldProps<T>) {
  const { getFieldState } = useFormContext<T>();
  const { error } = getFieldState(path);

  return (
    <div>
      <div className={labelClassName}>
        <span className="fs-sm fw-bold text-primary mb-2 me-1 d-inline-block">{label}</span>

        { renderPopover?.() }
      </div>

      <div className="d-flex gap-4">
        {options.map(({
          id, value, iconOptions, label: optionLabel,
        }) => {
          const cardComponent = (
            <RadioCard
              key={id}
              id={id}
              iconOptions={iconOptions}
              label={optionLabel}
              path={path}
              value={value}
            />
          );

          return cardWrapper ? cardWrapper(cardComponent) : cardComponent;
        })}
      </div>

      <BaseError message={error?.message} />
    </div>
  );
}
