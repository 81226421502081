import { RecommendationSuccessEventDetail } from '../../components/RecommenderForms/common/events';
import { ProductKind } from '../../components/RecommenderForms/common/types';

const NAVIGABLE_RECOMMENDATIONS: ProductKind[] = ['apv_insured_university', 'apv_full_flexible', 'apv_with_insurance'];

window.addEventListener('DOMContentLoaded', () => {
  const navigationContinueButton = document.querySelector<HTMLButtonElement>('[data-recommender-navigation="continue"]');

  if (!navigationContinueButton) return;

  window.addEventListener('recommendation.success', (event) => {
    const { detail } = event as CustomEvent<RecommendationSuccessEventDetail>;

    if (NAVIGABLE_RECOMMENDATIONS.includes(detail.recommendation)) {
      navigationContinueButton.classList.remove('d-none');
    } else {
      navigationContinueButton.classList.add('d-none');
    }
  });
});
