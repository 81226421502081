import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ApvLifeSimulationForm } from './ApvLife';

const queryClient = new QueryClient();

export function ApvLifeWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <ApvLifeSimulationForm />
    </QueryClientProvider>
  );
}
