import React from 'react';

type DocumentsUploadButtonProps = {
  handleUploadFiles: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export function DocumentsUploadButton({ handleUploadFiles }: DocumentsUploadButtonProps) {
  const inputId = 'fileField';

  return (
    <>
      <input
        id={inputId}
        onChange={handleUploadFiles}
        className="d-none"
        type="file"
        accept="application/pdf"
        multiple
      />
      <label className="dropzone mb-6" htmlFor={inputId}>
        <img src="/static/img/icons/circle-file-upload.svg" width={146} alt="" />
        <div className="dropzone-text">
          <span className="fw-bold text-decoration-underline">Sube aquí</span>
          {' '}
          o arrastra tus archivos para adjuntarlos
        </div>
      </label>
    </>
  );
}
