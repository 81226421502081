import React from 'react';
import clsx from 'clsx';
import { ProductRenderInfo } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const InsuredUniversityRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/carrying-pencil.svg',
  iconSrc: '/static/img/icons/open-book.svg',
  description: 'Seguro de vida con ahorro que te permite financiar la educación superior de tus hijos y asegurarla en caso de que no estés',
  buttonText: 'Simular',

  benefits: (mode) => [
    <span>
      <span className={clsx(mode === 'rich' && 'fw-bold')}>Recibe tus ahorros</span>
      {' '}
      cuando tu hijo cumpla 18 años
    </span>,
    <span>
      Asegura que tus hijos puedan
      <span className={clsx(mode === 'rich' && 'fw-bold')}>financiar su educación superior</span>
    </span>,
    <span>
      <span className={clsx(mode === 'rich' && 'fw-bold')}>Múltiples alternativas de inversión</span>
      {' '}
      para conseguir tus objetivos
    </span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
