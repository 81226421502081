import { api, authOptions } from '../../../utils/api';
import { PRODUCT_RECOMMENDATION_ENDPOINTS } from '../common/endpoints';
import { BaseProductRecommendationPayload, ProductRecommendationResponse } from '../common/types';

export interface ProjectsRecommendationFormPayload extends BaseProductRecommendationPayload {
  goal: number | null
}

export async function getProjectsRecommendationProduct(payload: ProjectsRecommendationFormPayload) {
  return api.post<ProductRecommendationResponse>(
    PRODUCT_RECOMMENDATION_ENDPOINTS.PROJECTS,
    payload,
    {
      ...authOptions,
      headers: {
        ...authOptions.headers,
      },
    });
}
