/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import clsx from 'clsx';
import { DownloadSimulationDocsValues, downloadSimulationDocsSchema, useDownloadSimulationDocs } from './useDownloadSimulationDocs';
import { CheckboxField, InputField } from '../Forms/Fields';
import { DownloadDocs, DownloadDocsOptions } from '../../utils/constants';
import { SimulationClass } from '../../utils/infographs';
import { LoadingSpinner } from '../common';
import { DownloadErrorAlert } from './DownloadErrorAlert';
import { SwitchField } from '../Forms/Fields/SwitchField';

/**
 * Lists the simulations where **Currency** field will be set as UF by default
 */
const UF_CURRENCY_SIMULATIONS: SimulationClass[] = [
  'APV_LIFE',
  'FULL_FLEXIBLE',
  'INSURED_UNIVERSITY',
  'MUTUAL_FUNDS_MONTHLY_CONTRIBUTION',
  'MUTUAL_FUNDS_TIME_FRAME',
];

function hasPolicyTable(simulation: SimulationClass) {
  const simulationsWithSelectors: SimulationClass[] = ['INSURED_UNIVERSITY', 'FULL_FLEXIBLE', 'APV_LIFE'];

  return simulationsWithSelectors.includes(simulation);
}

export interface DownloadSimulationDocsFormProps {
  simulation: SimulationClass
}

export function DownloadSimulationDocsForm({ simulation }: DownloadSimulationDocsFormProps) {
  const withDocumentSelector = hasPolicyTable(simulation);

  const form = useForm<DownloadSimulationDocsValues>({
    defaultValues: {
      download_as_uf: UF_CURRENCY_SIMULATIONS.includes(simulation),
      documents: {
        // `infograph` option will be set `true` as default if there is not document selector
        infograph: !withDocumentSelector,
        projection_table: false,
      },

    },
    resolver: zodResolver(downloadSimulationDocsSchema),
  });
  const simulationIdInput = useRef<HTMLInputElement>(null);

  const { downloadDocsMutation } = useDownloadSimulationDocs();

  const { first_name, last_name, documents } = form.watch();
  const isFilled = first_name
  && last_name
  && Object.values(documents).some(Boolean)
  && simulationIdInput.current?.value;

  const onSubmit = async (values: DownloadSimulationDocsValues) => {
    const simulationId = simulationIdInput.current?.value;

    if (!simulationId) return;

    await downloadDocsMutation.mutateAsync({
      values,
      simulation,
      simulationId: Number(simulationId),
    });
  };

  // Displays overlay during mutation loading on place without scrolling
  useEffect(() => {
    if (downloadDocsMutation.isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [downloadDocsMutation.isLoading]);

  return (
    <>
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(onSubmit)}
        >
          <section className="mb-6 px-8">
            <header className="col-12 mb-5">
              <h3 className="fs-5 fw-bold text-primary text-center">Descarga la simulación del cliente completando sus datos</h3>
            </header>

            <section className="container">
              <div className="row justify-content-center gy-4 gx-4">
                {/* This field is fulfilled on simulation submit */}
                <input
                  ref={simulationIdInput}
                  id="id_simulation"
                  type="hidden"
                />

                <div className={clsx('col-12 ', withDocumentSelector ? 'col-lg-2' : 'col-lg-3')}>
                  <InputField<DownloadSimulationDocsValues>
                    id="first_name"
                    path="first_name"
                    placeholder="Nombre"
                  />
                </div>

                <div className={clsx('col-12 ', withDocumentSelector ? 'col-lg-2' : 'col-lg-3')}>
                  <InputField<DownloadSimulationDocsValues>
                    id="last_name"
                    path="last_name"
                    placeholder="Apellido"
                  />
                </div>

                <div className="col-12 col-lg-2 d-flex justify-content-lg-center align-items-center">
                  <SwitchField<DownloadSimulationDocsValues>
                    id="download_as_uf"
                    path="download_as_uf"
                    label="Descargar en UF"
                    className="mb-0"
                  />
                </div>

                {withDocumentSelector && (
                  <div className="col-12 col-lg-2">
                    <CheckboxField<DownloadSimulationDocsValues, DownloadDocsOptions>
                      options={DownloadDocs.asCheckboxOptions()}
                      pathsMap={{
                        infograph: 'documents.infograph',
                        projection_table: 'documents.projection_table',
                      }}
                      orientation="vertical"
                      extraClassName="gap-2"
                    />
                  </div>
                )}

                <div className={clsx('col-12 ', withDocumentSelector ? 'col-lg-2' : 'col-lg-3')}>
                  <button
                    disabled={!isFilled || downloadDocsMutation.isLoading}
                    id="generate-simulation-btn"
                    data-gtm-track="on"
                    data-gtm-id="btn_descarga_simulacion"
                    type="submit"
                    className="btn btn-primary disabled-with-color py-4 text-white fw-bold w-100"
                  >
                    {downloadDocsMutation.isLoading
                      ? <LoadingSpinner />
                      : <span>Descargar</span>}
                  </button>
                </div>
              </div>

              {downloadDocsMutation.isError && <DownloadErrorAlert />}
            </section>
          </section>
        </form>
      </FormProvider>

      {downloadDocsMutation.isLoading && (
        <div className="loading-overlay">
          <img className="loader" src="/static/img/icons/loader.svg" alt="" />
        </div>
      )}
    </>
  );
}
