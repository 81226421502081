import { BaseProductRecommendationPayload } from './types';

type RecommendationPayloadFromQueryParams = Omit<BaseProductRecommendationPayload, 'children' | 'gender' | 'monthly_savings'> & {
  children: string[],
  university_cost: number
  goal: number
  ideal_pension: number
  gender: string
  monthly_savings: number
};

export function mapQueryParamsToRecommendationPayload(queryParams: URLSearchParams) {
  const payload: RecommendationPayloadFromQueryParams = {
    additional_savings: queryParams.get('additional_savings')?.split(',') ?? [],
    children: queryParams.get('children')?.split(',') ?? [],
    date_of_birth: queryParams.get('date_of_birth') ?? '',
    gender: queryParams.get('gender') ?? '',
    monthly_savings: Number(queryParams.get('monthly_savings') ?? 0),
    name: queryParams.get('name') ?? '',
    net_income: Number(queryParams.get('net_income') ?? 0),
    savings_with_insurance: queryParams.get('savings_with_insurance') === 'TRUE',
    goal: Number(queryParams.get('goal') ?? 0),
    ideal_pension: Number(queryParams.get('ideal_pension') ?? 0),
    university_cost: Number(queryParams.get('university_cost') ?? 0),
  };

  return payload;
}
