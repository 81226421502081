/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React, {
  ChangeEvent, ComponentProps, MutableRefObject,
} from 'react';
import {
  FieldValues, Path, useFormContext,
} from 'react-hook-form';
import { BaseError, ErrorAdornment } from '../Errors';

function getErrorClassName() {
  return 'border border-2 border-danger';
}

interface InputFieldProps<T extends FieldValues> extends ComponentProps<'input'> {
  id: string
  path: Path<T>
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
  className?: string
  label?: string
  withErrorAdornment?: boolean
  endAdornment?: () => JSX.Element
  customRef?: MutableRefObject<HTMLInputElement | null>
}

export function InputField<T extends FieldValues>({
  id,
  label,
  path,
  className,
  onChange,
  withErrorAdornment,
  endAdornment,
  customRef, ...inputProps
}: InputFieldProps<T>) {
  const {
    register, getFieldState, trigger, formState,
  } = useFormContext<T>();
  const { error } = getFieldState(path);

  const validateField = async () => formState.isSubmitted && await trigger(path);

  const fieldProps = register(path, {
    onChange,
    onBlur: () => validateField(),
  });

  return (
    <div className="w-100">
      {label && (
        <label htmlFor={id} className="fs-sm fw-bold text-primary w-100">
          <span className="mb-2 d-inline-block">{label}</span>
        </label>
      )}

      <div className="position-relative">
        <input
          {...inputProps}
          id={id}
          className={clsx(className ?? 'form-control', error && getErrorClassName())}
          {...fieldProps}
          ref={(node) => {
            if (customRef) {
              // eslint-disable-next-line no-param-reassign
              customRef.current = node;
            }

            fieldProps.ref(node);
          }}
        />
        {endAdornment && <div className="end-adornment">{endAdornment()}</div>}
        {error && withErrorAdornment && <ErrorAdornment />}
      </div>
      <BaseError message={error?.message as string} />
    </div>
  );
}
