import React from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  Product, ProductRenderInfo, ProductKind, ProductRecommendationResponse,
  BaseProductRecommendationPayload,
} from '../common/types';
import { ProductCard } from '../common/components/ProductCard';
import { APVLifeProductRenderInfo } from './APVLife';
import { EmptyRecommendationState } from '../common/components/EmptyState';
import { ProductSuggestions } from '../common/components/ProductSuggestions';
import { PENSION_RECOMMENDATION_KEY } from '../Pension/usePensionForm';
import { FullFlexibleProductRenderInfo } from './FullFlexible';
import { InsuredUniversityRenderInfo } from './InsuredUniversity';
import { APVStartProductRenderInfo } from './APVStart';
import { RRVVProductRenderInfo } from './RRVV';
import { BiceInvestmentsDerivation } from './BiceInvestmentsDerivation';
import { BiceLifeplanProductRenderInfo } from './BiceLifeplan';
import { ProtectionProductRenderInfo } from './Protection';
import { HealthProductRenderInfo } from './Health';
import { mapPayloadToQueryParams } from '../../../utils/queryParams';
import { PROJECTS_RECOMMENDATION_KEY } from '../Projects/useProjectsForm';
import { EDUCATION_RECOMMENDATION_KEY } from '../Education/useEducationForm';

const productInfoMap: Record<ProductKind, ProductRenderInfo> = {
  apv_with_insurance: APVLifeProductRenderInfo,
  apv_full_flexible: FullFlexibleProductRenderInfo,
  apv_insured_university: InsuredUniversityRenderInfo,
  apv_start: APVStartProductRenderInfo,
  rrvv: RRVVProductRenderInfo,
  apv_bi: BiceInvestmentsDerivation,
  bice_life_plan: BiceLifeplanProductRenderInfo,
  protection: ProtectionProductRenderInfo,
  health: HealthProductRenderInfo,
};

interface ProductResultRendererProps {
  recommendations: Product[],
  interests: Product[]
  formPayload: BaseProductRecommendationPayload
}

export function ProductRecommendations({
  recommendations,
  interests,
  formPayload,
}: ProductResultRendererProps) {
  // We need to simplify `children` property inside form payload to be able to
  // parse them as query parameters
  const simplifiedFormPayload = {
    ...formPayload,
    children: formPayload.children.map(({ date_of_birth }) => date_of_birth),
  };

  const suggestions = interests.map((interest) => {
    const isUniqueInterest = interests.length === 1;
    const {
      benefits, iconSrc, defaultImgSrc, hideButton, buttonText,
    } = productInfoMap[interest.kind];

    return ({
      url: mapPayloadToQueryParams(
        simplifiedFormPayload as unknown as Record<string, unknown>, interest.url
      ),
      // We want to render rich text only if `ProductCard` is rendered for suggestion
      // Else, for small cards suggestions, we want display just text.
      benefits: benefits(isUniqueInterest ? 'rich' : 'text'),
      title: interest.description,
      // If only one interest is provided, we want to display the big image because product
      // card will be rendered.
      // Else display icon for small interests cards
      imgSrc: isUniqueInterest
        ? (interest.image || defaultImgSrc(formPayload.savings_with_insurance))
        : iconSrc,
      buttonText: buttonText ?? 'Ver más',
      hideButton,

    });
  });

  return (
    <section className="d-flex flex-column gap-5">
      {recommendations.map((recommendation) => {
        const {
          benefits, defaultImgSrc, description, hideButton, buttonText,
        } = productInfoMap[recommendation.kind];

        return (
          <ProductCard
            key={recommendation.kind}
            title={recommendation.description}
            description={description}
            benefits={benefits('rich')}
            imgSrc={
              recommendation.image || defaultImgSrc(simplifiedFormPayload.savings_with_insurance)
}
            url={mapPayloadToQueryParams(
              simplifiedFormPayload as unknown as Record<string, unknown>, recommendation.url
            )}
            hideButton={hideButton}
            buttonText={buttonText ?? 'Simular'}
            recommended
          />
        );
      })}

      { suggestions.length !== 0 && <ProductSuggestions suggestions={suggestions} />}

      {productInfoMap[recommendations[0].kind]
        .complementaryInfo(simplifiedFormPayload.savings_with_insurance)}
    </section>
  );
}

interface ProductRecommendationRendererProps {
  formKey: typeof PENSION_RECOMMENDATION_KEY
  | typeof PROJECTS_RECOMMENDATION_KEY
  | typeof EDUCATION_RECOMMENDATION_KEY
}

export function ProductRecommendationRenderer({ formKey }: ProductRecommendationRendererProps) {
  const { data } = useQuery<ProductRecommendationResponse>([formKey]);

  if (!data) {
    return <EmptyRecommendationState />;
  }

  const { result_interests, result_products, ...formPayload } = data;

  return (
    <ProductRecommendations
      recommendations={result_products}
      interests={result_interests}
      formPayload={formPayload}
    />
  );
}
