import { AxiosError } from 'axios';
import { api, authOptions } from '../../utils/api';

export interface UploadFilesForSignPayload {
  customer_rut: string
  saapv_file: string
  extra_files: string[]
}

export interface UploadFilesForSignResponse {
  url: string;
  detail_url: string;
}

export type UploadFilesForSignError = AxiosError<{
  context: {
    status: number,
    data: {
      Code: string,
      Message: string,
    }
  }
}>;

const UPLOAD_FILES_FOR_SIGN_ENDPOINT = '/contract-upload/';

export const uploadFilesForSign = async (payload: FormData) => (
  api.post<UploadFilesForSignResponse>(UPLOAD_FILES_FOR_SIGN_ENDPOINT, payload, {
    ...authOptions,
    headers: {
      ...authOptions.headers,
      'Content-Type': 'multipart/form-data',
    },
  }));
