import {
  FieldValues, Path, PathValue, UseFormSetValue,
} from 'react-hook-form';
import { useEffect } from 'react';
import { SavingsOption } from '../utils/constants';

type ReseteableSavingsOptions = Extract<keyof typeof SavingsOption, 'ideal_pension' | 'savings_capacity'>;

interface UseResetSavingsOptionParams<T extends FieldValues> {
  savingOption: ReseteableSavingsOptions,
  setValue: UseFormSetValue<T>,
  idealPensionPath: Path<T>,
  monthlySavingsPath: Path<T>
}

/**
 * Watchs user's selection over the `SavingsOption` value and resets to 0 the other selection
 */
export function useResetSavingOption<T extends FieldValues>(
  options: UseResetSavingsOptionParams<T>
) {
  const {
    idealPensionPath,
    monthlySavingsPath,
    savingOption,
    setValue,
  } = options;

  // Reset handler functions associated with the selected `SavingType`
  const resetHandlers: Record<ReseteableSavingsOptions, () => void> = {
    // if `ideal_pension` is selected, then reset `monthly_savings`
    ideal_pension: () => {
      setValue(monthlySavingsPath, 0 as PathValue<T, Path<T>>);
    },

    // if `savings_capacity` is selected, then reset `ideal_pension`
    savings_capacity: () => {
      setValue(idealPensionPath, 0 as PathValue<T, Path<T>>);
    },
  };

  useEffect(() => {
    resetHandlers[savingOption]();
  }, [savingOption]);
}
