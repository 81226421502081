import { api, attachAuthOptions } from '../../utils/api';

const FEEDBACKS_ENDPOINT = '/feedbacks/';
const USER_FEEDBACK_ENDPOINT = '/feedbacks/user-feedback/';

export interface PostFeedbackPayload {
  score: number
  comment: string
  user: number
}

export interface GetFeedbackQuestionResponse {
  question: string
  active: boolean
}

export async function postUserFeedback(payload: PostFeedbackPayload) {
  return api.post<void>(USER_FEEDBACK_ENDPOINT, payload, attachAuthOptions());
}

export async function getFeedbackQuestion() {
  return api.get<GetFeedbackQuestionResponse[]>(FEEDBACKS_ENDPOINT, attachAuthOptions());
}
