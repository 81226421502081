/**
 * Takes an `url` and check if it contains a trailing slash.
 * If it does, removes it.
 * If not, keep the same url
 */
export function removeTrailingSlash(url: string) {
  return (
    url.endsWith('/')
      ? url.slice(0, url.length - 1)
      : url);
}
