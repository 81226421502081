/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EducationFormValues, educationFormSchema, useEducationForm } from './useEducationForm';
import {
  AppendableField,
  DatepickerField, InputField, NumericFormatField, RadioField,
  SelectField,
} from '../../Forms/Fields';
import {
  AdditionalSavings,
  BooleanOptions, GenderOptions, Genders,
  SavingsOption,
  SavingsTypeOptions,
  StringifiedBooleans,
} from '../../../utils/constants';
import { RadioBoxField } from '../../Forms/Fields/RadioBoxField';
import { ButtonLoading } from '../../common/ButtonLoading';

export function EducationForm() {
  const { handleSubmit, isLoading } = useEducationForm();
  const methods = useForm<EducationFormValues>({
    defaultValues: {
      gender: GenderOptions.MALE,
      children: StringifiedBooleans.TRUE,
      savings_option: SavingsOption.savings_capacity,
      wants_insurance_savings: StringifiedBooleans.TRUE,
      net_salary: 0,
      monthly_savings: 0,
      university_value: 0,
      has_other_investments: StringifiedBooleans.FALSE,
      other_investments: [],
    },
    resolver: zodResolver(educationFormSchema),
  });

  const savingsOption = methods.watch('savings_option');
  const hasChildren = methods.watch('children') === StringifiedBooleans.TRUE;
  const hasOtherInvestments = methods.watch('has_other_investments') === StringifiedBooleans.TRUE;

  const handleTriggerErrors = () => methods.formState.errors;

  const onSubmit = (values: EducationFormValues) => {
    handleSubmit(values);
  };

  // Cleanup counterpart field on saving option radio box selection
  useEffect(() => {
    if (savingsOption === 'savings_capacity') {
      methods.setValue('university_value', 0);
    }

    if (savingsOption === 'university_value') {
      methods.setValue('monthly_savings', 0);
    }
  }, [savingsOption]);

  // Cleanup `children_births` field on `no` radio button selection
  useEffect(() => {
    if (!hasChildren) {
      methods.setValue('children_births', []);
    }
  }, [hasChildren]);

  // Cleanup `other_investments` on `no` radio button selection
  useEffect(() => {
    if (!hasOtherInvestments) {
      methods.setValue('other_investments', []);
    }
  }, [hasOtherInvestments]);

  return (
    <FormProvider {...methods}>
      <form className="card-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField<EducationFormValues>
          id="fullname"
          path="fullname"
          label="¿Cuál es tu nombre?"
          placeholder="Ingresa su nombre"
        />

        <DatepickerField<EducationFormValues>
          id="date_of_birth"
          path="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
        />

        <RadioField<EducationFormValues>
          path="gender"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          label="¿Cuál es tu género?"
        />

        <div className="d-flex flex-column gap-4">
          <AppendableField
            hasDefaultField
            id="children_births"
            name="children_births"
            label="¿Cuál es la fecha de nacimiento de tu hijo/a?"
            defaultAppendButtonText="Agregar otro hijo"
            renderInput={(fieldId, index) => (
              <DatepickerField<EducationFormValues>
                key={fieldId}
                id={fieldId}
                path={`children_births.${index}`}
              />
            )}
          />
        </div>

        <RadioField<EducationFormValues>
          path="wants_insurance_savings"
          options={BooleanOptions.asRadioOptions().reverse()}
          label="¿Quieres ahorrar con seguro?"
        />

        <RadioBoxField<EducationFormValues>
          path="savings_option"
          options={
            SavingsTypeOptions
              .asRadioBoxOptions({ select: ['savings_capacity', 'university_value'] })
          }
          label="¿Cómo te gustaría ahorrar?"
        />

        {savingsOption === 'savings_capacity' && (
          <NumericFormatField<EducationFormValues>
            id="monthly_savings"
            label="¿Cuánto puedes ahorrar al mes?"
            path="monthly_savings"
            prefix="$ "
            placeholder="$"
          />
        )}

        {savingsOption === 'university_value' && (
          <NumericFormatField<EducationFormValues>
            id="university_value"
            label="¿Cuál es el valor aprox de la universidad de tu hijo?"
            path="university_value"
            prefix="$ "
            placeholder="$"
          />
        )}

        <NumericFormatField<EducationFormValues>
          id="net_salary"
          label="¿Cuál es tu sueldo líquido? (Opcional)"
          path="net_salary"
          prefix="$ "
          placeholder="$"
        />

        <RadioField<EducationFormValues>
          path="has_other_investments"
          options={BooleanOptions.asRadioOptions()}
          label="¿Tienes otros ahorros e inversiones? (Opcional)"
        />

        {hasOtherInvestments && (
          <AppendableField
            hasDefaultField
            id="other_investments"
            name="other_investments"
            renderInput={(fieldId, index) => (
              <SelectField<EducationFormValues>
                key={fieldId}
                id={fieldId}
                path={`other_investments.${index}`}
                options={AdditionalSavings.asSelectOptions()}
                placeholder="Selecciona"
                anchorBehavior="element"
              />
            )}
          />
        )}

        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          onClick={handleTriggerErrors}
          className="btn btn-secondary py-3 mt-2"
          spinnerProps={{
            size: 24,
          }}
        >
          Recomendar Producto
        </ButtonLoading>
      </form>
    </FormProvider>
  );
}
