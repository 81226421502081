import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { IsnModal } from './IsnModal';

const queryClient = new QueryClient();

export function IsnModalWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <IsnModal />
    </QueryClientProvider>
  );
}
