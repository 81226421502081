/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import clsx from 'clsx';
import {
  Controller, FieldValues, Path, useFormContext,
} from 'react-hook-form';
import { DateTime } from 'luxon';
import isEmpty from 'lodash.isempty';
import { BaseError } from '../Errors';
import { formatFromJSDateToPayload, formatFromPayloadToDateTime } from '../../../utils/dates';

function getErrorClassName() {
  return 'border border-2 border-danger';
}

interface LocalizationProviderWrapperProps {
  children: JSX.Element
}

function LocalizationProviderWrapper({ children }: LocalizationProviderWrapperProps) {
  return (
    <LocalizationProvider
      adapterLocale="ES"
      dateAdapter={AdapterLuxon}
    >
      { children }
    </LocalizationProvider>
  );
}

function OpenPickerIcon() {
  return <img src="/static/img/icons/calendar.svg" alt="calendar icon" />;
}

interface DatePickerFieldProps<T extends FieldValues> {
  id: string
  path: Path<T>
  label?: string
  minDate?: DateTime
  maxDate?: DateTime
  className?: string
}

export function DatepickerField<T extends FieldValues>({
  id,
  path,
  className,
  label,
  maxDate,
  minDate,
}: DatePickerFieldProps<T>) {
  const {
    control, getFieldState,
  } = useFormContext<T>();
  const { error } = getFieldState(path);

  const computeSelectedDate = (selectedDate: DateTime | null) => {
    if (!selectedDate) {
      return '';
    }

    return formatFromJSDateToPayload(selectedDate.toJSDate());
  };

  return (
    <div className="w-100">
      {label && (
        <label htmlFor={id} className="fs-sm fw-bold text-primary w-100">
          <span className="mb-2 d-inline-block">{label}</span>
        </label>
      )}

      <LocalizationProviderWrapper>
        <Controller
          name={path}
          control={control}
          render={({ field }) => (
            <DatePicker
              {...field}
              onChange={(selected) => {
                field.onChange(computeSelectedDate(selected));
              }}
              value={isEmpty(field.value) ? null : formatFromPayloadToDateTime(field.value)}
              name={path}
              closeOnSelect={false}
              format="dd/MM/yyyy"
              minDate={minDate}
              maxDate={maxDate}
              views={['year', 'month', 'day']}
              className={clsx(className ?? 'form-control', error && getErrorClassName())}
              slots={{
                openPickerIcon: OpenPickerIcon,
              }}
            />
          )}
        />
      </LocalizationProviderWrapper>

      <BaseError message={error?.message as string} />
    </div>
  );
}
