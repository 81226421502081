const TABLE_TILES_SELECTOR = '.table-tiles';
const TABLE_TILES_DIVIDER_SELECTOR = '.table-tiles-divider';

/**
 * Positions the table tile divider based on its closest table cell.
 */
function setupTableTileDivider(divider: HTMLElement) {
  const dividerCell = divider.closest<HTMLTableCellElement>('td, th');
  const dividerTop = (dividerCell?.offsetHeight || 0) + (dividerCell?.offsetTop || 0);

  // eslint-disable-next-line no-param-reassign
  divider.style.top = `${dividerTop}px`;
}

/**
 * Initializes the table tile by locating the divider element within the table.
 */
function initTableTiles(table: HTMLTableElement) {
  const divider = table.querySelector<HTMLElement>(TABLE_TILES_DIVIDER_SELECTOR);

  if (!divider) return;

  setupTableTileDivider(divider);

  window.addEventListener('resize', () => {
    setupTableTileDivider(divider);
  });
}

// Initialize table tiles
window.addEventListener('DOMContentLoaded', () => {
  const tableTiles = document.querySelectorAll<HTMLTableElement>(TABLE_TILES_SELECTOR);
  tableTiles.forEach((table) => initTableTiles(table));
});
