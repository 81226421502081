import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import {
  Message, MessageCreateOptions, MessagesListOptions, createMessage, getMessagesList,
} from '../utils/chatbot';

export const useMessages = (options: MessagesListOptions) => useQuery({
  queryKey: ['messages'],
  queryFn: () => getMessagesList(options),
});

export const useCreateMessage = () => {
  const queryClient = useQueryClient();

  const streamHandler = (data: string) => {
    queryClient.setQueryData<Message[]>(
      ['messages'],
      (old) => {
        const oldMessages = old === undefined ? [] : old.filter((message) => message.id !== 'stream');

        const newMessage: Message = {
          content: data,
          id: 'stream',
          role: 'assistant',
        };

        return (oldMessages === undefined ? [newMessage] : [...oldMessages, newMessage]);
      },
    );
  };

  return useMutation({
    mutationFn: (
      options: MessageCreateOptions,
    ) => createMessage(options, streamHandler),
    onMutate: async (message) => {
      // Disable the query and cancel re-fetches
      queryClient.setQueryDefaults(['messages'], { enabled: false });
      await queryClient.cancelQueries({ queryKey: ['messages'] });

      // Save previous messages
      const previousMessages = queryClient.getQueryData(['messages']);

      // Optimistically add the new message
      queryClient.setQueryData<Array<Message>>(
        ['messages'],
        (old) => {
          const newMessage: Message = {
            content: message.content,
            id: 'optimistic',
            role: 'user',
          };
          return (old === undefined ? [newMessage] : [...old, newMessage]);
        },
      );

      return { previousMessages };
    },
    onError: () => {
      const previousMessages = queryClient.getQueryData(['messages']);
      queryClient.setQueryData(['messages'], previousMessages || []);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: ['messages'] });
      queryClient.setQueryDefaults(['messages'], { enabled: true });
    },
  });
};
