import React, { ReactNode, useState } from 'react';
import { Modal } from 'react-bootstrap';

type InvalidDocumentModalProps = {
  message: ReactNode | string;
  handleClose: () => void;
};

export function InvalidDocumentModal({ message, handleClose }: InvalidDocumentModalProps) {
  const [show, setShow] = useState(true);
  const handleHide = () => setShow(false);

  return (
    <Modal show={show} onHide={handleHide} onExited={handleClose} centered>
      <Modal.Header className="p-6" closeButton closeLabel="Cerrar" />
      <Modal.Body className="p-6 pt-0 text-center">
        <img className="mb-6" src="/static/img/illustrations/desktop-error.svg" width={120} alt="" />
        <h3 className="fs-6 text-primary mb-4">Error en la carga de archivos</h3>
        <p className="text-subtitle fs-lg mb-6">{message}</p>
        <div className="row justify-content-center">
          <div className="col-md-6">
            <button className="btn btn-primary w-100" type="button" onClick={handleHide}>
              Reintentar
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
