/* eslint-disable react/no-array-index-key */
import React from 'react';

interface BulletList {
  title: string
  items: string[]
}

interface BulletListsProps {
  lists: BulletList[]
}

export function BulletLists({ lists }: BulletListsProps) {
  return (
    <section className="d-flex flex-column gap-5">
      {lists.map(({ title, items }) => (
        <div key={title}>
          <header className="mb-5">
            <h4 className="fs-lg text-primary fw-bold">{title}</h4>
          </header>

          <ul className="ps-5 mb-0">
            {items.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      ))}
    </section>
  );
}
