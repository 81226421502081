import { z } from 'zod';

export const PASSWORD_MIN_LENGTH = 8;

export const PASSWORD_VALIDATION_MESSAGES = {
  minLength: `La clave debe tener al menos ${PASSWORD_MIN_LENGTH} caracteres.`,
  nonOnlyNumericChars: 'La clave no puede ser completamente númerica.',
  nonCommon: 'La clave no puede ser una clave utilizada comúnmente.',
  nonPersonalInfo: 'La clave no puede ser igual a tu información personal.',
  confirmationSameAsPassword: 'La clave confirmación debe ser igual a la nueva clave',
};

export function validateMinLength(password: string) {
  const { success } = z
    .string()
    .min(PASSWORD_MIN_LENGTH, PASSWORD_VALIDATION_MESSAGES.minLength)
    .safeParse(password);

  return success;
}

export function validateNonOnlyNumericChars(password: string) {
  const { success } = z
    .string()
    .refine((v) => Number.isNaN(Number(v)), PASSWORD_VALIDATION_MESSAGES.nonOnlyNumericChars)
    .safeParse(password);

  return success;
}
