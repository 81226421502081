import React from 'react';
import { EducationForm } from './EducationForm';
import { WithQueryClient } from '../../common/WithQueryClient';
import { ProductRecommendationRenderer } from '../Products/ProductResultRenderer';

export function EducationFormWrapper() {
  return (
    <WithQueryClient>
      <div className="row gy-5">
        <div className="col-md-4">
          <EducationForm />
        </div>

        <div className="col-md-8">
          <ProductRecommendationRenderer formKey="EDUCATION-RECOMMENDATION-FORM" />
        </div>
      </div>
    </WithQueryClient>
  );
}
