export function renderCreateLeadForm() {
  const createLeadFormContainer = document.querySelector('#create-lead-form-container');

  if (!createLeadFormContainer) return;

  createLeadFormContainer.classList.replace('d-none', 'd-block');
}

export function updateSimulationButtonText() {
  const submitButton = document.getElementById('send-simulation');

  if (!submitButton) return;

  submitButton.innerText = 'Volver a simular';
}

export function isValidOtherGoal(goalType: string, customGoal: string) {
  const OTHERS_GOAL_VALUE = 'OTHER';
  const isOtherGoalType = goalType === OTHERS_GOAL_VALUE;
  const hasText = customGoal.length > 0;

  if (isOtherGoalType) {
    return hasText;
  }

  return true;
}

interface ToggleInputElementParams {
  input: HTMLInputElement,
  forceShow: boolean
}

export function toggleInputElement({ input, forceShow }: ToggleInputElementParams) {
  const isHidden = input.classList.contains('d-none');

  if (isHidden && forceShow) {
    input.classList.replace('d-none', 'd-block');
  } else {
    // Must force reset value on hiding
    // eslint-disable-next-line no-param-reassign
    input.value = '';
    input.classList.replace('d-block', 'd-none');
  }
}
