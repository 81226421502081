import { useEffect, useState } from 'react';
import {
  Currency, CurrencyOptionKey, Regime,
} from '../../utils/constants';

export const MAX_UF_SAVINGS = 50; // In CLF ("UF")
export const REGIME_A_MAX_YEARS_SAVINGS = 900; // In CLF ("UF")
export const REGIME_B_MAX_YEARS_SAVINGS = 600; // In CLF ("UF")

type useSavingsExceededModalParams = {
  savingsAmount: number
  regime: Regime
  currency: CurrencyOptionKey
};

// Exposes the show state and handlers to dispatch the Savings Exceeded modal when regime and
// savings amount conditions are met.
export function useSavingsExceededModal({
  currency,
  regime,
  savingsAmount,
}: useSavingsExceededModalParams) {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    const savingsAsUf = currency === 'CLP' ? Currency.transform(savingsAmount, 'CLF') : savingsAmount;

    if (regime === 'B' && savingsAsUf > MAX_UF_SAVINGS) {
      setShow(true);
    }
  };

  const handleClose = () => setShow(false);

  // Displays savings exceeded modal for user when regime changes and conditions are met
  useEffect(() => {
    handleShow();
  }, [regime]);

  return { show, handleShow, handleClose };
}
