/* eslint-disable no-param-reassign */
import React from 'react';
import {
  FieldValues, Path, PathValue, UseFormSetValue,
} from 'react-hook-form';
import { formatMoney } from '../../utils/formatters';

// Functions
function formatInputValueToMoney(input: HTMLInputElement) {
  const { value } = input;
  const restrictedValue = Number(value.replace(/\D/g, ''));
  const formattedValue = restrictedValue ? formatMoney(Number(restrictedValue)) : '';

  // eslint-disable-next-line no-param-reassign
  input.value = formattedValue;
}

function transformMoneyToNumber(value: string) {
  return value.replace(/\D/g, '');
}

function isMoneyInput(input: HTMLInputElement) {
  return input.getAttribute('data-type') === 'money';
}

// Allow handling money on change event for React input components which uses React Hook Form as
// form handler
function handleMoneyOnChange<T extends FieldValues>(
  event: React.ChangeEvent<HTMLInputElement>,
  name: Path<T>,
  setValue: UseFormSetValue<T>) {
  const { value } = event.target;
  const restrictedValue = transformMoneyToNumber(value);
  const formattedValue = restrictedValue ? formatMoney(Number(restrictedValue)) : '';

  setValue(name, formattedValue as PathValue<T, Path<T>>);
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const moneyInputs = document.querySelectorAll('[data-type="money"]');

  moneyInputs.forEach((input) => {
    formatInputValueToMoney(input as HTMLInputElement);
    input.addEventListener('input', () => formatInputValueToMoney(input as HTMLInputElement));
  });
});

// Utils functions for handling money inputs
export { transformMoneyToNumber, isMoneyInput, handleMoneyOnChange };
