/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { zodResolver } from '@hookform/resolvers/zod';
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';
import clsx from 'clsx';
import { getCSRF } from '../../utils/forms';
import { InputField } from '../Forms/Fields';

const emailForResetPasswordSchema = z.object({
  email: z.string().email('Ingrese un correo electrónico válido'),
});

type EmailForResetPasswordValues = z.infer<typeof emailForResetPasswordSchema>;

export function EmailForResetPasswordForm() {
  const methods = useForm<EmailForResetPasswordValues>({
    resolver: zodResolver(emailForResetPasswordSchema),
  });

  const handleValidateErrors = () => methods.formState.errors;
  const formRef = useRef<HTMLFormElement>(null);
  const { name: CSRFName, value: CSRFValue } = getCSRF();

  const isEmailEmpty = !methods.watch('email')?.length;
  const onSubmit = () => formRef.current?.submit();

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        className="mb-6"
        action=""
        method="post"
        onSubmit={methods.handleSubmit(onSubmit)}
      >

        <div className="w-100 d-flex flex-column align-items-center gap-4">
          <input type="hidden" name={CSRFName} value={CSRFValue} />

          <InputField<EmailForResetPasswordValues>
            id="email"
            path="email"
            label="Ingresa tu E-mail"
            placeholder="E-mail"
          />

          <button
            disabled={isEmailEmpty}
            type="submit"
            onClick={handleValidateErrors}
            className={clsx('btn btn-primary w-100 rounded-2 p-2 fw-bold', isEmailEmpty && 'btn-disabled')}
          >
            Restablecer mi contraseña

          </button>
        </div>
      </form>
    </FormProvider>
  );
}
