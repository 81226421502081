import React from 'react';
import { WithQueryClient } from '../common/WithQueryClient';
import { SearchCustomerDerivationForm } from './SearchDerivationForm';

export function SearchDerivationFormWrapper() {
  return (
    <WithQueryClient>
      <SearchCustomerDerivationForm />
    </WithQueryClient>
  );
}
