/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import { z } from 'zod';
import React, { useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputField } from '../Forms/Fields';
import { getCSRF } from '../../utils/forms';

const loginValidationSchema = z.object({
  email: z.string().nonempty('Este campo es requerido'),
  password: z.string().nonempty('Este campo es requerido'),
});

type LoginValues = z.infer<typeof loginValidationSchema>;

export function LoginForm() {
  const [showPassword, setShowPassword] = useState(false);
  const methods = useForm<LoginValues>({
    resolver: zodResolver(loginValidationSchema),
  });

  const handleToggleShowPassword = () => setShowPassword(!showPassword);
  const handleValidateErrors = () => methods.formState.errors;

  const formRef = useRef<HTMLFormElement>(null);
  const { name: CSRFName, value: CSRFValue } = getCSRF();

  const onSubmit = () => {
    formRef.current?.submit();
  };

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        id="login-form"
        className="mx-auto d-flex flex-column align-items-center justify-content-center gap-4 mb-4 "
        action=""
        method="post"
        onSubmit={methods.handleSubmit(onSubmit)}
      >
        <input type="hidden" name={CSRFName} value={CSRFValue} />

        <InputField<LoginValues>
          path="email"
          placeholder="Email"
          id="email"
          withErrorAdornment
        />

        <InputField<LoginValues>
          path="password"
          placeholder="Clave"
          type={showPassword ? 'text' : 'password'}
          id="password"
          endAdornment={() => (
            <button type="button" className="btn btn-ghost p-1 me-2" onClick={handleToggleShowPassword}>
              <img src={showPassword ? '/static/img/icons/open-eye.svg' : '/static/img/icons/eye.svg'} alt="show password icon" />
            </button>
          )}
        />

        <button type="submit" onClick={handleValidateErrors} className="btn btn-primary w-100 rounded-2 p-2 fw-bold">Ingresar</button>
      </form>

    </FormProvider>
  );
}
