import React from 'react';
import { getUserContext } from '../../../utils/user';
import { Message } from '../../../utils/chatbot';
import { MarkdownRenderer } from '../../common/MarkdownRenderer';

interface UsersMessageProps {
  message: string
}

function UsersMessage({ message }: UsersMessageProps) {
  const userName = getUserContext('FULL_NAME') as string;
  const firstNameChar = userName[0];

  return (
    <div className="d-flex gap-7 p-4 rounded-3 align-items-center">
      <div
        style={{ width: 100, height: 100 }}
        className="rounded-circle bg-secondary-light d-flex align-items-center justify-content-center"
      >
        <span className="d-block fs-3 fw-bold text-secondary">{firstNameChar}</span>
      </div>

      <div>
        <p className="mb-0">{message}</p>
      </div>
    </div>
  );
}

interface BotMessageProps {
  message: string
}

function BotMessage({ message }: BotMessageProps) {
  return (
    <div className="d-flex gap-7 bg-secondary-light p-4 rounded-3 align-items-center">
      <div>
        <img
          width={100}
          height={100}
          src="/static/img/icons/bice-lifeplan-circle.svg"
          alt="bice lifeplan logo"
        />
      </div>

      <div className="flex-grow-1 overflow-auto">
        <MarkdownRenderer content={message} />
      </div>
    </div>
  );
}

interface MessagesWrapperProps {
  messages: Message[]
}

export function MessagesWrapper({ messages }: MessagesWrapperProps) {
  return (
    <div className="d-flex flex-column gap-6">
      {messages.map((msg) => {
        const isUserMessage = msg.role === 'user';
        const isAssistantMessage = msg.role === 'assistant';

        return (
          <React.Fragment key={msg.id}>
            {isUserMessage && <UsersMessage message={msg.content} />}
            {isAssistantMessage && <BotMessage message={msg.content} />}
          </React.Fragment>
        );
      })}
    </div>
  );
}
