import { z } from 'zod';
import { useEffect } from 'react';
import { formatMoney } from '../../../utils/formatters';
import { validateAgeMayority, validateMaxAge } from '../../../utils/validations';
import { BaseSimulationParams } from '../../../interfaces/simulations';
import { simulationManager } from '../../../utils/simulationsManager';
import { createSimulationMetrics } from '../../../utils/simulationMetrics';

const MIN_AMOUNT = 0;

export const insuranceWithSavingsValidationSchema = z.object({
  date_of_birth:
    z.string().nonempty('Este campo es requerido'),
  gender:
    z.string()
      .nonempty('Este campo es requerido'),
  net_income:
    z.number({ required_error: 'Este campo es requerido' })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  insurance_plan:
    z.object({
      label: z.string(),
      value: z.string(),
    }),
  additional_savings:
    z.number({ required_error: 'Este campo es requerido' }),
})
  .refine(({ date_of_birth }) => validateAgeMayority(date_of_birth, 'yyyy-MM-dd'), {
    message: 'El cliente debe ser mayor de edad',
    path: ['date_of_birth'],
  })
  .refine(({ date_of_birth }) => validateMaxAge({ date: date_of_birth }), {
    message: 'El cliente no puede tener una edad mayor a 100 años',
    path: ['date_of_birth'],
  });

export type InsuranceWithSavingsSimulationValues =
  z.infer<typeof insuranceWithSavingsValidationSchema>;

interface InsuranceWithSavingsPayload extends BaseSimulationParams {
  date_of_birth: string
  gender: string
  net_income: number
  insurance_plan: string
  additional_savings: number
  risk_profile: string
}

export function useInsuranceWithSavings() {
  const mapFormToPayload = (values: InsuranceWithSavingsSimulationValues) => {
    const {
      additional_savings, date_of_birth, gender, insurance_plan, net_income,
    } = values;
    const payload: InsuranceWithSavingsPayload = {
      additional_savings,
      date_of_birth,
      gender,
      insurance_plan: insurance_plan.value,
      net_income,
      risk_profile: 'moderate',
      simulation_type: 'INSURANCE_WITH_SAVINGS',
      wording: 'EXECUTIVE',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: InsuranceWithSavingsSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<InsuranceWithSavingsPayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['insurance-with-savings'],
          title: 'insurance-with-savings-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await createSimulationMetrics(payload, 'INSURANCE_WITH_APV');
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<BaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'EXECUTIVE',
          simulation_type: 'INSURANCE_WITH_SAVINGS',
          is_empty: true,
        },
      },
      iframeAttributes: {
        classNames: ['insurance-with-savings', 'empty'],
        title: 'insurance-with-savings-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
