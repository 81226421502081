import { z } from 'zod';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUserContext } from '../../utils/user';
import { getFeedbackQuestion, PostFeedbackPayload, postUserFeedback } from './isnService';

export const isnValidationSchema = z.object({
  rating: z.number(),
  additional_comments: z.string(),
});

export type IsnFormValues = z.infer<typeof isnValidationSchema>;

const GET_FEEDBACK_QUESTION_KEY = 'GET_FEEDBACK_QUESTION_KEY';

export function useIsn() {
  const { data: feedbackQuestions } = useQuery({
    queryFn: () => getFeedbackQuestion(),
    queryKey: [GET_FEEDBACK_QUESTION_KEY],
  });

  const {
    mutateAsync: createFeedback, isLoading, isError,
  } = useMutation({
    mutationFn: (payload: PostFeedbackPayload) => postUserFeedback(payload),
  });

  const mapFormToPayload = (formValues: IsnFormValues) => {
    const { additional_comments, rating } = formValues;
    const userId = getUserContext('USER_ID') as number;
    const payload: PostFeedbackPayload = {
      comment: additional_comments,
      score: rating,
      user: userId,
    };

    return payload;
  };

  const handleSubmitFeedback = async (formValues: IsnFormValues) => {
    const payload = mapFormToPayload(formValues);
    await createFeedback(payload);
  };

  return {
    // Get the first (and the only (?)) question of the available feedbacks response
    feedbackQuestion: feedbackQuestions?.data[0],
    handleSubmitFeedback,
    isLoading,
    isError,
  };
}
