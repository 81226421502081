import React from 'react';

interface ShowPasswordButtonProps {
  onClick: () => void
  isShowing: boolean
}

export function ShowPasswordButton({ onClick, isShowing }: ShowPasswordButtonProps) {
  return (
    <button type="button" className="btn btn-ghost" onClick={onClick}>
      <img src={isShowing ? '/static/img/icons/open-eye.svg' : '/static/img/icons/eye.svg'} alt="show password icon" />
    </button>
  );
}
