/* eslint-disable react/require-default-props */
import React from 'react';

interface BaseErrorProps {
  message?: string
}

export function BaseError({ message }: BaseErrorProps) {
  if (!message) {
    return null;
  }

  return (
    <span className="d-block text-danger small mt-1">{message}</span>
  );
}
