/* eslint-disable consistent-return */
import { api, authOptions } from './api';

export const SIMULATION_METRICS_ENDPOINTS = {
  APV_LIFE: '/metrics/apv-life/',
  APV_START: '/metrics/apv/',
  FULL_FLEXIBLE: '/metrics/full-flexible/',
  INSURANCE_WITH_APV: '/metrics/insurance-with-apv/',
  INSURED_UNIVERSITY: '/metrics/insured-university/',
  MUTUAL_FUNDS_MONTHLY_CONTRIBUTION: '/metrics/monthly-contribution/',
  MUTUAL_FUNDS_TIME_FRAME: '/metrics/time-frame/',
};

interface CreateSimulationMetricsResponse {
  id: number
}

export async function createSimulationMetrics(
  data: unknown,
  metricsEndpoint: keyof typeof SIMULATION_METRICS_ENDPOINTS
) {
  return (await api.post<CreateSimulationMetricsResponse>(
    SIMULATION_METRICS_ENDPOINTS[metricsEndpoint],
    data,
    {
      ...authOptions,
      headers: {
        ...authOptions.headers,
      },
    })).data;
}
