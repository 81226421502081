/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

export interface CheckboxOption<T extends string = string> {
  id: T
  label: string
}

interface CheckboxProps<T extends FieldValues> extends CheckboxOption {
  path: Path<T>
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void
}

function Checkbox<T extends FieldValues>({
  id, path, label, onChange,
}: CheckboxProps<T>) {
  const { register } = useFormContext<T>();
  const { onChange: formOnChange, ...rest } = register(path);

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    onChange?.(event);
    await formOnChange(event);
  };

  return (
    <div className="form-check">
      <input className="form-check-input" type="checkbox" id={id} onChange={handleChange} {...rest} />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  );
}

/**
 * Since each checkbox must have its own path, we relate the checkbox `id` with the form `path`
 * Because of this, notice that `CheckboxFieldProps` needs two generics:
 *  - The first one is the required generic which indicates the allowed field paths for
 *    the given form schema
 *  - The second one refers to the union of all literal string types which represents the `ids` of
 *    each option that are going to be related to each option path in the `pathsMap` object
 * */

/**
 * Also, we can pass a custom `onChange` handler to each checkbox if needed and relate them
 * using the `id` of the checkbox.
 *
 * The prop `onChangeMap` is the responsible of taking checkboxes ids and map them to the
 * custom `onChange` event.
 */
interface CheckboxFieldProps<T extends FieldValues, K extends string = string> {
  options: CheckboxOption[]
  pathsMap: Record<K, Path<T>>
  label?: string
  onChangeMap?: Partial<Record<K, (event: ChangeEvent<HTMLInputElement>) => void>>
  orientation?: 'vertical' | 'horizontal'
  extraClassName?: string
}

export function CheckboxField<T extends FieldValues, K extends string = string>({
  label, options, pathsMap, extraClassName, onChangeMap = {}, orientation = 'horizontal',
}: CheckboxFieldProps<T, K>) {
  return (
    <div>
      { label && (
        <span className="fs-sm fw-bold text-primary mb-2 d-inline-block">{label}</span>
      )}

      <div className={clsx('d-flex w-100', extraClassName, orientation === 'vertical' && 'flex-column')}>
        {options.map(({ id, label: optionLabel }) => {
          const path = pathsMap[id as K];
          const customOnChange = onChangeMap[id as K];

          return (
            <Checkbox
              key={id}
              id={id}
              label={optionLabel}
              path={path}
              onChange={customOnChange}
            />
          );
        }
        )}
      </div>
    </div>

  );
}
