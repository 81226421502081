import React from 'react';
import { FieldValues, Path, UseFormSetValue } from 'react-hook-form';
import { INITIAL_CONTRIBUTION_OPTIONS, InitialContributionOptions } from '../../../utils/constants';
import {
  NumericFormatField, RadioField, SelectField,
} from '../../Forms/Fields';
import { useResetInitialContribution } from '../../../hooks/useResetInitialContribution';
import { useTransferOptions } from '../../../hooks/useTransferOptions';
import { SelectOption } from '../../Forms/Fields/SelectField';

interface InitialContributionFieldProps<T extends FieldValues> {
  contributionOption: keyof typeof INITIAL_CONTRIBUTION_OPTIONS
  setValue: UseFormSetValue<T>
  contributionAmountPath: Path<T>
  contributionOptionPath: Path<T>,
  transferCompanyPath: Path<T>
}

export function InitialContributionField<T extends FieldValues>({
  contributionOption,
  contributionOptionPath,
  contributionAmountPath,
  transferCompanyPath,
  setValue,
}: InitialContributionFieldProps<T>) {
  const isUniqueContribution = contributionOption === INITIAL_CONTRIBUTION_OPTIONS
    .UNIQUE_CONTRIBUTION;
  const isTransferContribution = contributionOption === INITIAL_CONTRIBUTION_OPTIONS
    .TRANSFER;

  const { transferOptions } = useTransferOptions();
  const transferOptionsForSelect = transferOptions?.map((option) => ({
    label: option,
    value: option,
  })) as SelectOption<T>[];

  useResetInitialContribution({
    contributionAmountPath,
    contributionOption,
    setValue,
    transferCompanyPath,
  });

  return (
    <>
      <RadioField
        path={contributionOptionPath}
        label="¿Tienes un aporte inicial o un APV en otra institución?"
        options={InitialContributionOptions.asRadioOptions()}
      />

      <div className="d-flex flex-column gap-4">
        {isTransferContribution && (
          <SelectField
            id="source_transfer"
            path={transferCompanyPath}
            options={transferOptionsForSelect ?? []}
            placeholder="Selecciona una opción de traspaso"
            isSearchable
          />

        )}

        {(isUniqueContribution || isTransferContribution)
            && (
              <NumericFormatField<T>
                id="contribution_amount"
                path={contributionAmountPath}
                prefix="$ "
                placeholder="$"
              />
            )}

      </div>
    </>
  );
}
