import React from 'react';

interface DocumentsListProps {
  documents: File[]
  handleRemoveFile: (index: number) => void
  handleOpenSinglePreview: (document: File) => void
}

export function DocumentsList({
  documents,
  handleRemoveFile,
  handleOpenSinglePreview,
}: DocumentsListProps) {
  if (documents.length === 0) return null;

  return (
    <>
      <p className="fw-bold text-subtitle mb-6">Archivos adjuntos:</p>
      <ul className="documents-list">
        {documents.map((file, index) => (
          <DocumentItem
            key={file.name}
            document={file}
            index={index}
            handleRemoveFile={handleRemoveFile}
            handleOpenSinglePreview={handleOpenSinglePreview}
          />
        ))}
      </ul>
    </>
  );
}

interface DocumentItemProps {
  document: File
  index: number
  handleRemoveFile: (index: number) => void
  handleOpenSinglePreview: (document: File) => void
}

function DocumentItem({
  document, index, handleOpenSinglePreview, handleRemoveFile,
}: DocumentItemProps) {
  const handleClick = () => {
    handleOpenSinglePreview(document);
  };
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleOpenSinglePreview(document);
    }
  };
  const handleDeleteButtonClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    handleRemoveFile(index);
  };

  return (
    <li>
      <div
        className="documents-list-item"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        role="button"
      >
        {document.name}
        <button className="btn btn-close" onClick={handleDeleteButtonClick} type="button" aria-label="Remover" />
      </div>
    </li>
  );
}
