import { api, authOptions } from '../../../utils/api';
import { PRODUCT_RECOMMENDATION_ENDPOINTS } from '../common/endpoints';
import { BaseProductRecommendationPayload, ProductRecommendationResponse } from '../common/types';

export interface PensionRecommendationFormPayload extends BaseProductRecommendationPayload {
  ideal_pension: number | null
}

export async function getPensionRecommendationProduct(payload: PensionRecommendationFormPayload) {
  return api.post<ProductRecommendationResponse>(
    PRODUCT_RECOMMENDATION_ENDPOINTS.PENSION,
    payload,
    {
      ...authOptions,
      headers: {
        ...authOptions.headers,
      },
    });
}
