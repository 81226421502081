/* eslint-disable no-param-reassign */
import { formatMoney } from '../../utils/formatters';
import { getElementByName } from '../../utils/forms';
import { isMoneyInput, transformMoneyToNumber } from './input-money';

const rangeInputs = document.querySelectorAll<HTMLInputElement>('input[type="range"]');

rangeInputs.forEach((rangeInput) => {
  const linkedInputFieldSelector = rangeInput.getAttribute('data-linked-field');

  if (!linkedInputFieldSelector) return;

  const linkedInputField = getElementByName(linkedInputFieldSelector);

  if (!linkedInputField) return;

  rangeInput.addEventListener('change', (e) => {
    const { value } = e.target as HTMLInputElement;

    if (isMoneyInput(linkedInputField)) {
      linkedInputField.value = formatMoney(Number(value));
      return;
    }

    linkedInputField.value = value;
  });

  linkedInputField.addEventListener('change', (e) => {
    const { value } = e.target as HTMLInputElement;

    if (isMoneyInput(linkedInputField)) {
      rangeInput.value = transformMoneyToNumber(value);
      return;
    }

    rangeInput.value = value;
  });
});
