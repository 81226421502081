import React from 'react';
import { PensionForm } from './PensionForm';
import { WithQueryClient } from '../../common/WithQueryClient';
import { ProductRecommendationRenderer } from '../Products/ProductResultRenderer';
import { PENSION_RECOMMENDATION_KEY } from './usePensionForm';

export function PensionFormWrapper() {
  return (
    <WithQueryClient>
      <div className="row gy-5">
        <div className="col-md-4">
          <PensionForm />
        </div>

        <div className="col-md-8">
          <ProductRecommendationRenderer formKey={PENSION_RECOMMENDATION_KEY} />
        </div>
      </div>
    </WithQueryClient>
  );
}
