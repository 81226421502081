import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requiredString, selectOption } from '../../../utils/commonSchemaValidators';
import { GenderOptions, SavingsOption } from '../../../utils/constants';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { formatMoney } from '../../../utils/formatters';
import {
  validateAgeMayority,
  validateHasOtherInvestments,
  validateSavingsOption,
} from '../../../utils/validations';
import { PensionRecommendationFormPayload, getPensionRecommendationProduct } from './pensionRecommendationService';
import { createRecommendationSuccessEvent } from '../common/events';

const MIN_SAVINGS_AMOUNT = 0;
export const PENSION_RECOMMENDATION_KEY = 'PENSION-RECOMMENDATION-FORM';

export const pensionFormSchema = z.object({
  fullname: requiredString(),
  date_of_birth: requiredString()
    .refine((date_of_birth) => validateAgeMayority(date_of_birth), { message: 'El cliente debe ser mayor de edad' }),
  gender: z.enum([GenderOptions.MALE, GenderOptions.FEMALE, GenderOptions.OTHER]),
  children: requiredString(),
  wants_insurance_savings: requiredString(),
  children_births: z.array(z.string()),
  savings_option: z.enum([SavingsOption.ideal_pension, SavingsOption.savings_capacity]),
  monthly_savings:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_SAVINGS_AMOUNT),
  ideal_pension:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_SAVINGS_AMOUNT),
  net_salary:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD }),
  has_other_investments: requiredString(),
  other_investments: z.array(selectOption()),
})
  .refine(({ savings_option, ideal_pension }) => validateSavingsOption(
    savings_option,
    'ideal_pension',
    ideal_pension
  ), {
    message: `El valor debe ser mayor a ${formatMoney(MIN_SAVINGS_AMOUNT)}`,
    path: ['ideal_pension'],
  })
  .refine(({ savings_option, monthly_savings }) => validateSavingsOption(
    savings_option,
    'savings_capacity',
    monthly_savings
  ), {
    message: `El valor debe ser mayor a ${formatMoney(MIN_SAVINGS_AMOUNT)}`,
    path: ['monthly_savings'],
  })
  .refine(({ has_other_investments, other_investments }) => validateHasOtherInvestments(
    has_other_investments, other_investments.map(({ value }) => value) ?? null
  ),
  {
    path: ['other_investments'],
    message: VALIDATION_MESSAGES.REQUIRED_FIELD,
  });

export type PensionFormValues = z.infer<typeof pensionFormSchema>;

export function usePensionForm() {
  const mapFormToPayload = (formValues: PensionFormValues) => {
    const {
      fullname,
      other_investments,
      children_births,
      date_of_birth,
      gender,
      ideal_pension,
      monthly_savings,
      net_salary,
      wants_insurance_savings,
    } = formValues;

    const payload: PensionRecommendationFormPayload = {
      name: fullname,
      additional_savings: other_investments.map(({ value }) => value),
      children: children_births.map((birth) => ({ date_of_birth: birth })),
      net_income: net_salary,
      savings_with_insurance: wants_insurance_savings === 'TRUE', // Converts stringified boolean into real boolean type,
      monthly_savings,
      ideal_pension,
      gender,
      date_of_birth,
    };

    return payload;
  };

  const queryClient = useQueryClient();

  const {
    mutate: computeRecommendation,
    data: response,
    isLoading,
    isError,
  } = useMutation({
    mutationKey: [PENSION_RECOMMENDATION_KEY],
    mutationFn: async (
      payload: PensionRecommendationFormPayload
    ) => getPensionRecommendationProduct(payload),
    onSuccess: async (res) => {
      queryClient.setQueryData([PENSION_RECOMMENDATION_KEY], res.data);
      window.dispatchEvent(createRecommendationSuccessEvent(res.data.result_products[0].kind));
    },
  });

  const handleSubmit = (formValues: PensionFormValues) => {
    const payload = mapFormToPayload(formValues);
    computeRecommendation(payload);
  };

  return {
    handleSubmit, isLoading, isError, response,
  };
}
