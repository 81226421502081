/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InsuranceWithSavingsSimulationValues, insuranceWithSavingsValidationSchema, useInsuranceWithSavings } from './useInsuranceWithSavings';
import {
  DatepickerField,
  NumericFormatField, RadioField, SelectField,
} from '../../Forms/Fields';
import {
  Genders, InsurancePlans,
} from '../../../utils/constants';

export function InsuranceWithSavings() {
  const [repeatSimulation, setRepeatSimulation] = useState(false);

  const methods = useForm<InsuranceWithSavingsSimulationValues>({
    defaultValues: {
      gender: '',
      insurance_plan: InsurancePlans.asSelectOptions().defaultChoice,
    },
    resolver: zodResolver(insuranceWithSavingsValidationSchema),
  });

  const handleTriggerErrors = () => methods.formState.errors;
  const { handleSubmitSimulation } = useInsuranceWithSavings();

  const onSubmit = async (values: InsuranceWithSavingsSimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
  };

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <DatepickerField<InsuranceWithSavingsSimulationValues>
          id="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
          path="date_of_birth"
        />

        <RadioField<InsuranceWithSavingsSimulationValues>
          label="¿Cuál es su género?"
          options={Genders.asRadioOptions()}
          path="gender"
        />

        <NumericFormatField<InsuranceWithSavingsSimulationValues>
          id="net_income"
          path="net_income"
          label="¿Cuál es su sueldo líquido?"
          prefix="$ "
          placeholder="$"
        />

        <SelectField<InsuranceWithSavingsSimulationValues>
          id="insurance_plan"
          path="insurance_plan"
          label="¿Cuál es el Plan elegido?"
          options={InsurancePlans.asSelectOptions<InsuranceWithSavingsSimulationValues>().choices}
        />

        <NumericFormatField<InsuranceWithSavingsSimulationValues>
          id="additional_savings"
          path="additional_savings"
          label="¿Cuánto puedes sumar de ahorro a tu Plan?"
          prefix="$ "
          placeholder="$"
        />

        <button onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>
    </FormProvider>
  );
}
