import clsx from 'clsx';
import React from 'react';
import { ProductRenderInfo, TextMode } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const APVStartProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/person-and-pet.svg',
  iconSrc: '/static/img/icons/money-hand.svg',
  description: 'Seguro de vida que permite ahorrar para mejorar tu pensión',
  buttonText: 'Simular',

  benefits: (textMode: TextMode) => [
    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Mejora tu pensión y protege</span>
      {' '}
      a los que más quieres
    </span>,

    <span>
      Accede a los
      {' '}
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>beneficios tributarios</span>
      {' '}
      del APV
    </span>,

    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Haz crecer tu dinero</span>
      {' '}
      con múltiples alternativas de inversión
    </span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
