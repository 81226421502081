import clsx from 'clsx';
import React from 'react';
import { ProductRenderInfo, TextMode } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const BiceLifeplanProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/cat-and-girl-dancing.svg',
  iconSrc: '/static/img/icons/graph-up.svg',
  buttonText: 'Ver más',

  benefits: (textMode: TextMode) => [
    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Invierte</span>
      {' '}
      sin un monto mínimo
    </span>,

    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Acompañamiento</span>
      {' '}
      <span>y</span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>asesoría</span>
      {' '}
      en todo momento
    </span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
