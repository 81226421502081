/**
 *  Takes a payload and converts it into a new url with all the payload fields as query params.
 *  This function works well for primitive type values; but if the payload contains composited
 *  types you will have to define how they will be parsed as query params in a generalized way.
 *
 *  The only composited type supported is an `Array` values.
 */
export function mapPayloadToQueryParams(payload: Record<string, unknown>, url = '') {
  const searchParams = new URLSearchParams();

  Object.entries(payload).forEach(([key, value]) => {
    if (typeof value === 'object') {
      if (Array.isArray(value)) {
        const stringifiedArray = value.join(',');

        searchParams.set(key, stringifiedArray);
      }
    }

    searchParams.set(key, String(value));
  });

  return `${url}?${searchParams.toString()}`;
}
