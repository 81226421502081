import {
  useState, ChangeEvent, FormEvent, useMemo,
} from 'react';
import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { formatRut } from '../../utils/formatters';
import { validateForm, validateRut } from '../../utils/validations';
import { UploadFilesForSignError, UploadFilesForSignResponse, uploadFilesForSign } from './uploadDocumentsService';
import { EVENTS, tag } from '../../behaviors/cms/gtm-inputs';

const UploadDocumentsFormShape = z.object({
  clientRUT: z.string().nonempty('Este campo es requerido'),
  documents: z.array(z.instanceof(File)).min(1, 'Debes cargar al menos 1 archivo'),
})
  .refine((data) => validateRut(data.clientRUT), {
    message: 'El formato del RUT ingresado no es válido',
    path: ['clientRUT'],
  });

export type UploadDocumentsForm = z.infer<typeof UploadDocumentsFormShape>;

const MAX_FILE_SIZE_MB = 10;
const MAX_FILE_SIZE = MAX_FILE_SIZE_MB * 1024 * 1024;
const MAX_FILES_ALLOWED = 10;

const DEFAULT_UPLOAD_DOCUMENTS_FORM: UploadDocumentsForm = {
  clientRUT: '',
  documents: [],
};

type UploadDocumentsFormErrors = Record<keyof UploadDocumentsForm, string[]>;

const EMPTY_ERRORS: UploadDocumentsFormErrors = {
  clientRUT: [],
  documents: [],
};
export const FILE_EXTENSION_ERROR = 'Invalid file extension.';
export const MAX_FILE_SIZE_ERROR = `El archivo adjunto supera el tamaño permitido (${MAX_FILE_SIZE_MB}MB).`;
export const MAX_FILES_ALLOWED_ERROR = `Se ha superado el número máximo de archivos permitidos para subir (${MAX_FILES_ALLOWED}).`;

interface SinglePreviewModalState {
  document: File | null
  isOpen: boolean
}

interface MultiplePreviewModalState {
  documents: File[]
  isOpen: boolean
}

type DocumentsUploaderViews = 'uploading' | 'success' | 'error';

const isPdfFile = (file: File) => {
  const fileName = file.name;
  const fileExtension = fileName.split('.').pop()?.toLowerCase();

  return fileExtension === 'pdf';
};

export function useUploadDocuments() {
  const [view, setView] = useState<DocumentsUploaderViews>('uploading');

  const [uploadDocumentsForm, setUploadDocumentsForm] = useState<UploadDocumentsForm>(
    DEFAULT_UPLOAD_DOCUMENTS_FORM
  );
  const [
    formErrors,
    setFormErrors] = useState<UploadDocumentsFormErrors>({
    clientRUT: [],
    documents: [],
  });

  const [singlePreviewModal, setSinglePreviewModal] = useState<SinglePreviewModalState>({
    document: null,
    isOpen: false,
  });

  const [multiplePreviewModal, setMultiplePreviewModal] = useState<MultiplePreviewModalState>({
    documents: [],
    isOpen: false,
  });

  const updateErrors = (errors: Partial<UploadDocumentsFormErrors>) => {
    setFormErrors({
      ...EMPTY_ERRORS,
      ...errors,
    });
  };

  const handleUploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    const latestDocuments = event.target.files;

    if (!latestDocuments) return;

    const pdfDocuments = [...latestDocuments].filter(isPdfFile);
    const invalidDocumentExtensionCount = latestDocuments.length - pdfDocuments.length;
    const pdfDocumentsWithValidSize = pdfDocuments.filter((file) => file.size <= MAX_FILE_SIZE);
    const invalidDocumentSizeCount = pdfDocuments.length - pdfDocumentsWithValidSize.length;
    const maxNewFilesAllowed = MAX_FILES_ALLOWED - uploadDocumentsForm.documents.length;

    let documentsError: string | undefined;
    if (invalidDocumentExtensionCount) {
      documentsError = FILE_EXTENSION_ERROR;
    } else if (invalidDocumentSizeCount) {
      documentsError = MAX_FILE_SIZE_ERROR;
    } else if (pdfDocumentsWithValidSize.length > maxNewFilesAllowed) {
      documentsError = MAX_FILES_ALLOWED_ERROR;
    }

    if (documentsError) {
      updateErrors({
        documents: [documentsError],
      });
    }

    setUploadDocumentsForm({
      ...uploadDocumentsForm,
      documents: [
        ...uploadDocumentsForm.documents,
        ...pdfDocumentsWithValidSize.slice(0, maxNewFilesAllowed),
      ],
    });
  };

  const handleRemoveFile = (index: number) => {
    const cleanFiles = uploadDocumentsForm.documents.filter((_, i) => i !== index);
    setUploadDocumentsForm({
      ...uploadDocumentsForm,
      documents: cleanFiles,
    });
  };

  const handleChangeRut = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedRut = formatRut(value);

    setUploadDocumentsForm({
      ...uploadDocumentsForm,
      clientRUT: formattedRut,
    });
  };

  const handleOpenSinglePreview = (document: File) => {
    setSinglePreviewModal({
      document,
      isOpen: true,
    });
  };

  const handleCloseSinglePreview = () => {
    setSinglePreviewModal({
      document: null,
      isOpen: false,
    });
  };

  const handleCloseMultiplePreview = () => {
    setMultiplePreviewModal({
      documents: [],
      isOpen: false,
    });
  };

  const handleCloseErrorModal = (error: string) => {
    setFormErrors((prevErrors) => {
      const prevDocumentsErrors = prevErrors.documents;
      const documentsErrors = prevDocumentsErrors.filter((currentError) => currentError !== error);

      return {
        ...prevErrors,
        documents: documentsErrors,
      };
    });
  };

  const {
    mutate: dispatchUploadingRequest,
    reset: resetUploadState,
    isLoading:
    isLoadingUpload,
    isError:
    isUploadError,
    error: uploadError,
  } = useMutation<UploadFilesForSignResponse, UploadFilesForSignError, FormData>({
    mutationFn: async (payload: FormData) => (await uploadFilesForSign(payload)).data,
    onSuccess: ({ detail_url }) => {
      window.location.href = detail_url;
    },
    onError: (err) => {
      console.error(err);
      setView('error');
    },
  });

  const handleOpenMultiplePreview = (documents: File[]) => {
    resetUploadState();

    setMultiplePreviewModal({
      documents,
      isOpen: true,
    });
  };

  const clearErrors = () => {
    setFormErrors(EMPTY_ERRORS);
  };

  const formHasErrors = Object.values(formErrors).some((v) => v.length !== 0);
  const fieldHasError = (field: keyof UploadDocumentsForm) => formErrors[field].length !== 0;
  const isDisabledForm = useMemo(() => !uploadDocumentsForm.clientRUT
    || !uploadDocumentsForm.documents.length,
  [uploadDocumentsForm]
  );

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    try {
      clearErrors();
      validateForm(uploadDocumentsForm, UploadDocumentsFormShape);

      handleOpenMultiplePreview(uploadDocumentsForm.documents);
    } catch (err) {
      if (err instanceof z.ZodError) {
        updateErrors(err.formErrors.fieldErrors);
      }
    }
  };

  const handleSendDocuments = async () => {
    const [saapvFile, ...extraFiles] = uploadDocumentsForm.documents;
    const payload = new FormData();

    // TODO: maybe improve and implement function for automate form data payload building
    payload.append('customer_rut', uploadDocumentsForm.clientRUT);
    payload.append('saapv_file', saapvFile);

    extraFiles.forEach((file) => {
      payload.append('extra_files', file);
    });

    tag({
      event: EVENTS.SUBMIT,
      variables: {
        id: 'submit_doc',
      },
    });
    dispatchUploadingRequest(payload);
  };

  return {
    uploadDocumentsForm,
    formErrors,
    singlePreviewModal,
    multiplePreviewModal,
    isDisabledForm,
    formHasErrors,
    view,
    resetUploadState,
    isLoadingUpload,
    isUploadError,
    uploadError,
    fieldHasError,
    handleCloseErrorModal,
    handleOpenSinglePreview,
    handleCloseSinglePreview,
    handleCloseMultiplePreview,
    handleUploadFiles,
    handleRemoveFile,
    handleChangeRut,
    handleSubmit,
    handleSendDocuments,
  };
}
