import { useQuery } from '@tanstack/react-query';
import { getTransferSourcesOptions } from '../utils/initialContribution';

export function useTransferOptions() {
  const { data: options } = useQuery({
    queryKey: ['transfer-options'],
    queryFn: async () => getTransferSourcesOptions(),
  });

  return { transferOptions: options?.data };
}
