import React from 'react';
import clsx from 'clsx';
import { ProductRenderInfo } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const FullFlexibleProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/scaling-mountain.svg',
  iconSrc: '/static/img/icons/money-hand.svg',
  description: 'Seguro de vida con ahorro que además de proteger a los que más quieres, te permite conseguir tus objetivos de ahorro.',
  buttonText: 'Simular',

  benefits: (mode) => [
    <span>
      <span className={clsx(mode === 'rich' && 'fw-bold')}>Elige</span>
      {' '}
      a quienes quieres proteger
    </span>,

    <span>
      <span className={clsx(mode === 'rich' && 'fw-bold')}>Liquidez inmediata</span>
      {' '}
      en caso de fallecimiento
    </span>,

    <span>
      <span className={clsx(mode === 'rich' && 'fw-bold')}>Haz crecer tu dinero</span>
      {' '}
      con múltiples alternativas de inversión
    </span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
