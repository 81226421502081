/* eslint-disable class-methods-use-this */
import { BaseSimulationParams } from '../interfaces/simulations';
import { getEnv } from './publicEnv';

interface GenerateIframeSrcOptions<T extends BaseSimulationParams> {
  parameters: T,
  nonCapturedValues?: Record<string, string>
}

interface IframeAttrs {
  title: string
  classNames: string[]
}

interface BuildIframeOptions {
  attributes: IframeAttrs,
  src: string
}

interface RenderIframeOptions<T extends BaseSimulationParams> {
  parentElement: HTMLDivElement,
  iframeAttributes: IframeAttrs
  srcOptions: GenerateIframeSrcOptions<T>
}

class SimulationManager {
  private baseUrl: string;

  constructor() {
    this.baseUrl = getEnv('SIMULATORS_URL') ?? 'https://localhost:8000/simulations';
  }

  private generateIframeSrc<T extends BaseSimulationParams>(options: GenerateIframeSrcOptions<T>) {
    const { parameters, nonCapturedValues } = options;
    const url = new URL(this.baseUrl);

    Object.entries({ ...nonCapturedValues, ...parameters }).forEach(([key, value]) => {
      url.searchParams.set(key, value);
    });

    return url.toString();
  }

  private buildIframe(options: BuildIframeOptions) {
    const { src, attributes } = options;
    const { classNames, title } = attributes;
    const iframe = document.createElement('iframe');

    classNames.forEach((className) => {
      iframe.classList.add(className);
    });

    iframe.setAttribute('title', title);
    iframe.setAttribute('src', src);

    return iframe;
  }

  render<T extends BaseSimulationParams>(options: RenderIframeOptions<T>) {
    const { srcOptions: generateSrcOptions, iframeAttributes, parentElement } = options;

    const src = this.generateIframeSrc(generateSrcOptions);
    const iframe = this.buildIframe({
      src,
      attributes: iframeAttributes,
    });

    // Hardly cleanup parent's element inner HTML before rendering new built iframe on render call
    parentElement.innerHTML = '';

    parentElement.appendChild(iframe);
  }
}

export const simulationManager = new SimulationManager();
