import { AxiosProgressEvent } from 'axios';
import { api, attachAuthOptions } from './api';

export const CHATBOT_MESSAGES_URL = (sessionId: string) => `/chats/sessions/${sessionId}/messages/`;

export type MessagesListOptions = {
  sessionId: string,
};

export interface MessageCreateOptions {
  sessionId: string
  content: string
  previousMessageId: number
}

export type MessageRole = 'system' | 'user' | 'assistant';

export interface Message {
  id: string,
  role: MessageRole,
  content: string,
}

const dataFromEvent = (event: AxiosProgressEvent) => {
  type CustomProgressEvent = {
    currentTarget: {
      response: string
    },
  };

  const browserEvent = event.event as CustomProgressEvent;
  const data = browserEvent.currentTarget.response;
  return data;
};

export const createMessage = async (
  options: MessageCreateOptions,
  handleEvent: (data: string) => void = () => null,
) => {
  const response = await api.post<string>(
    CHATBOT_MESSAGES_URL(options.sessionId),
    { content: options.content, previous_message: options.previousMessageId },
    attachAuthOptions({
      responseType: 'stream',
      onDownloadProgress: (progressEvent) => {
        handleEvent(dataFromEvent(progressEvent));
      },
    }),
  );

  return response.data;
};

export const getMessagesList = async (options: MessagesListOptions) => {
  const response = await api.get<Message[]>(
    CHATBOT_MESSAGES_URL(options.sessionId), attachAuthOptions()
  );
  return response.data;
};
