import React from 'react';
import { DeriveCustomerForm } from './DeriveCustomerForm';
import { WithQueryClient } from '../common/WithQueryClient';

export function DeriveCustomerFormWrapper() {
  return (
    <WithQueryClient>
      <DeriveCustomerForm />
    </WithQueryClient>
  );
}
