/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import clsx from 'clsx';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { EmptyMessagesWrapper } from './EmptyMessages/EmptyMessagesWrapper';
import { MessagesWrapper } from './Messages/MessagesWrapper';
import { ChatbotFormValues, chatbotFormSchema, useChatbot } from './useChatbot';
import { InputField } from '../Forms/Fields';

export function MessagesBox() {
  const {
    handleSubmitMessage,
    isCreatingMessage,
    hasCreateMessageError,
    hasMessages,
    messages,
  } = useChatbot();

  const form = useForm<ChatbotFormValues>({
    resolver: zodResolver(chatbotFormSchema),
    defaultValues: {
      message: '',
    },
  });

  const onSubmit = async (values: ChatbotFormValues) => {
    form.reset();
    handleSubmitMessage(values);
  };

  return (
    <section className={clsx(hasMessages && 'card shadow-sm p-6', 'my-6')}>
      <div className="mb-6">
        { hasMessages
          ? <MessagesWrapper messages={messages} />
          : <EmptyMessagesWrapper />}
      </div>

      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <InputField<ChatbotFormValues>
            id="message"
            path="message"
            disabled={isCreatingMessage}
            placeholder="Escribe tu pregunta"
            endAdornment={() => (
              <button disabled={isCreatingMessage} className="btn btn-icon border-0 px-4" type="submit">
                <img src="/static/img/icons/send-message.svg" alt="send message button" />
              </button>
            )}
          />
        </form>
      </FormProvider>

      { hasCreateMessageError && (
      <div className="alert alert-danger mt-4 mb-0" role="alert">
        <div>
          <header>
            <h4 className="fw-bold text-danger fs-6 mb-3">Oops! Algo ha ido mal...</h4>
          </header>

          <p className="mb-0 text-danger">
            Ha ocurrido un error durante la generación de la respuesta de la Mesa de Ayuda.
            Inténtelo más tarde.
          </p>
        </div>
      </div>
      )}
    </section>
  );
}
