import { SIMULATION_METRICS_ENDPOINTS, createSimulationMetrics } from '../../utils/simulationMetrics';

export const SIMULATION_ID_INPUT = '#id_simulation';

export async function injectSimulationIdToInfograph(
  payload: unknown,
  metricsEndpoint: keyof typeof SIMULATION_METRICS_ENDPOINTS
) {
  const { id } = await createSimulationMetrics(payload, metricsEndpoint);
  const simulationIdInput = document.querySelector<HTMLInputElement>(SIMULATION_ID_INPUT)!;
  simulationIdInput.value = id.toString();
}
