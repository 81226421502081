import { z } from 'zod';
import { requiredString } from '../../utils/commonSchemaValidators';
import { useCreateMessage, useMessages } from '../../hooks/useChatbotMessages';

export const chatbotFormSchema = z.object({
  message: requiredString(),
});

export type ChatbotFormValues = z.infer<typeof chatbotFormSchema>;

export function useChatbot() {
  const sessionId = window.location.href.split('/').at(-2)!;

  const { data } = useMessages({ sessionId });
  const createMessageMutation = useCreateMessage();

  const messages = data ?? [];

  // Use `non-null assertion` because ALWAYS chatbot will provide at least one message.
  // Including in empty chats; because all chats are initialized
  // passing the prompt as `system` role.
  const lastMessage = messages.at(-1)!;

  // Since we dont want to consider the default `system` message prompt as a "valid" message,
  // We skip it and start counting messages after it.
  const hasMessages = messages.slice(1).length > 0;

  const handleSubmitMessage = (values: ChatbotFormValues) => {
    createMessageMutation.mutate({
      sessionId,
      content: values.message,
      previousMessageId: Number(lastMessage.id),
    });
  };

  return {
    hasCreateMessageError: createMessageMutation.isError,
    createMessageError: createMessageMutation.error,
    isCreatingMessage: createMessageMutation.isLoading,
    handleSubmitMessage,
    messages,
    hasMessages,
  };
}
