import { api, authOptions } from '../../utils/api';
import {
  COMPANIES, Company, CompanyProduct,
} from '../../utils/constants';

export type DerivationStatus = 'derived' | 'contacted' | 'hired' | 'not hired' | 'unresponsive';

export interface DeriveCustomerPayload {
  name: string,
  last_name: string,
  rut: string,
  phone_number: string,
  email: string,
  product: CompanyProduct,
  company: typeof COMPANIES[Company],
  appointment_date: string
  appointment_time: string
}

export interface DerivationPossibleErrors {
  rut?: string[]
}

const DERIVE_CUSTOMER_ENDPOINT = '/customers/derived-customer/';

export const createCustomerDerivation = async (payload: DeriveCustomerPayload) => api
  .post<void>(DERIVE_CUSTOMER_ENDPOINT, payload, {
  ...authOptions,
  headers: {
    ...authOptions.headers,
  },
});

interface CustomerForDerivationStatus {
  first_name: string,
  last_name: string,
  rut: string,
}
export interface DerivationStatusInfo extends DeriveCustomerPayload {
  id : number
  status: DerivationStatus
  product_display: string,
  customer: CustomerForDerivationStatus
}

export const getDerivationStatus = async (rut: string) => api
  .get<DerivationStatusInfo[]>(DERIVE_CUSTOMER_ENDPOINT, {
  params: {
    rut,
  },
});
