import React from 'react';
import { ProductMessage } from './ProductMessage';

export function DefaultComplementaryInfo() {
  return (
    <ProductMessage
      imgSrc="/static/img/illustrations/person-marketing.svg"
      title="Consejo LifePlan:"
      imgAlignment="center"
      renderContent={(
        <p className="mb-0">
          Haz las siguientes preguntas para complementar tu asesoría y conocer más a tu
          cliente:
          {' '}
          <span className="fw-bold">
            {' '}
            ¿A qué te dedicas?, ¿Cómo se compone tu núcleo familiar?,
            ¿Cuántos años te quedan aún para trabajar?
          </span>
        </p>
      )}
    />
  );
}
