/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import clsx from 'clsx';
import React from 'react';

interface ProductCardProps {
  title: string
  imgSrc: string
  benefits: Array<string | JSX.Element>
  url: string
  buttonText: string
  description?: string
  recommended?: boolean
  hideButton?: boolean
}

function RecommendationBadge() {
  return (
    <div className="card-product-badge">
      <img src="/static/img/icons/sloping-star.svg" alt="" />
      <span>Recomendado</span>
    </div>
  );
}

export function ProductCard({
  title, description, imgSrc, benefits, url, recommended, buttonText, hideButton,
}: ProductCardProps) {
  return (
    <section className={clsx('card-product', recommended && 'card-product-recommended')}>
      {recommended && (
        <div className="mb-4">
          <RecommendationBadge />
        </div>
      )}

      <div className={clsx('row gx-7 px-4', recommended && 'pb-5')}>
        <div className="col-md-4">
          <img className="w-100" src={imgSrc} alt="" />
        </div>

        <div className="col-md-8">
          <header className="mb-4">
            <h2 className="h5 mb-0 text-skyblue-dark">{title}</h2>
          </header>

          <div className="pe-8">
            {description && <p>{description}</p>}

            <ul className="list-icon list-icon-check">
              {benefits.map((benefit, index) => <li key={index} className="list-icon-item">{benefit}</li>)}
            </ul>
          </div>

          {!hideButton && (
            <div className="row px-4 ps-md-0 pe-md-5">
              <a target="_blank" href={url} className={clsx('col-md-5 btn mx-auto', recommended ? 'btn-secondary' : 'btn-outline-primary')} rel="noreferrer">
                {buttonText}
              </a>
            </div>
          )}
        </div>
      </div>
    </section>
  );
}
