import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { UseFormSetError } from 'react-hook-form';
import { AxiosError } from 'axios';
import { requiredString } from '../../utils/commonSchemaValidators';
import { validateRutByRegexp } from '../../utils/validations';
import { VALIDATION_MESSAGES } from '../../utils/validationMessages';
import { DerivationPossibleErrors, DerivationStatusInfo, getDerivationStatus } from './deriveCustomerService';

export const searchDerivationValidationSchema = z.object({
  rut: requiredString().refine((rut) => validateRutByRegexp(rut), {
    message: VALIDATION_MESSAGES.INVALID_RUT,
  }),
});

export type SearchDerivationValues = z.infer<typeof searchDerivationValidationSchema>;

export type DerivationStatusTableData = DerivationStatusInfo;

export function useSearchDerivationForm(setError: UseFormSetError<SearchDerivationValues>) {
  const [tableData, setTableData] = useState<DerivationStatusTableData[]>();

  const { mutate } = useMutation({
    mutationFn: (rut: string) => getDerivationStatus(rut),
    onSuccess: (res) => {
      const derivations = res.data;

      setTableData(derivations);
    },
    onError: (err: AxiosError<DerivationPossibleErrors>) => {
      const rutError = err.response?.data.rut?.[0];

      if (rutError) {
        setError('rut', {
          message: rutError,
        });
      }
    },
  });

  const handleSearchDerivation = (data: SearchDerivationValues) => {
    mutate(data.rut);
  };

  return { handleSearchDerivation, tableData };
}
