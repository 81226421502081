import { useEffect, useState } from 'react';
import { getRecommendedRegime } from '../utils/regimeRecommendation';
import { RegimeOptions } from '../utils/constants';

export function useRegimeRecommendation(initialNetIncome?: number) {
  const [recommendedRegime, setRecommendedRegime] = useState<keyof typeof RegimeOptions>();

  const handleGetRecommendedRegime = async (netIncome: number) => {
    try {
      if (!netIncome) return;

      const { recommended_regime } = await getRecommendedRegime(netIncome);
      setRecommendedRegime(recommended_regime);
    } catch (err) {
      console.error(err);
    }
  };

  // Tries to get initial regimen recommendation if an initial net income
  // parameter is given.
  useEffect(() => {
    if (initialNetIncome) {
      handleGetRecommendedRegime(initialNetIncome)
        .catch(console.error);
    }
  }, []);

  return { recommendedRegime, setRecommendedRegime, handleGetRecommendedRegime };
}
