import { ProductKind } from './types';

export type RecommendationSuccessEventDetail = {
  recommendation: ProductKind
};

export const createRecommendationSuccessEvent = (
  recommendation: ProductKind
) => new CustomEvent<RecommendationSuccessEventDetail>(
  'recommendation.success', {
    detail: {
      recommendation,
    },
  });
