/* eslint-disable react/require-default-props */
import React, { CSSProperties } from 'react';

interface ChipProps {
  text?: string
  icon?: {
    src: string,
    alt: string,
  },
  style: CSSProperties
}

export function Chip({ text, icon, style }: ChipProps) {
  return (
    <div
      className="px-2 py-1 bg-primary text-white d-flex gap-1 align-items-center justify-content-center small rounded-2"
      style={style}
    >
      {icon && <img src={`/static/img/icons/${icon.src}`} alt={icon.alt} width={16} height={16} />}
      {text && <span>{text}</span>}
    </div>
  );
}
