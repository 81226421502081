import { api } from '../../../utils/api';
import { ErrorCode } from './BackendErrorsList';

const ENDPOINT = (uid: string) => `/account/${uid}/password-validation/`;

interface ValidatePasswordPayload {
  new_password: string
}

export interface ValidatePasswordResponse {
  new_password: ErrorCode[]
}

export const passwordChangeService = {
  validate: async (uid: string, payload: ValidatePasswordPayload) => (
    (await api.post<ValidatePasswordResponse>(ENDPOINT(uid), payload)).data),
};
