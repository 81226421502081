/**
 * Parameter handler for access to non-sensitive environment variables injected by backend through
 * base template
 */
interface ParameterContext {
  ENABLE_RETIREMENT_AGE: boolean,
}

type ParameterContextKey = keyof ParameterContext;

const PARAMETER_CONTEXT_ELEMENT_ID = '#parameter_context';

export const getParameterContext = (key: ParameterContextKey) => {
  const parameterContext = document.querySelector(PARAMETER_CONTEXT_ELEMENT_ID);

  if (!parameterContext) return null;

  const parsedData = JSON.parse(parameterContext.textContent || '') as ParameterContext;

  return parsedData[key];
};
