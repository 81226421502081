import axios, { AxiosRequestConfig } from 'axios';
import { getEnv } from './publicEnv';
import { removeTrailingSlash } from './urls';

// Base api instance with default configuration
export const api = axios.create({
  baseURL: '/api/v1/',
});

export const authOptions = {
  headers: {
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Content-Type': 'application/json',
  },
  xsrfCookieName: 'csrftoken',
  xsrfHeaderName: 'X-CSRFToken',
  withCredentials: false,
};

export function attachAuthOptions(request: AxiosRequestConfig = {}) {
  return (
    {
      ...request,
      ...authOptions,
      headers: {
        ...authOptions.headers,
      },
    }
  );
}

// API instance for connecting with simulators api
export const simulatorsApi = axios.create({
  baseURL: removeTrailingSlash(getEnv('SIMULATORS_API_URL') ?? ''),
});
