import { api, authOptions } from '../../../utils/api';
import { PRODUCT_RECOMMENDATION_ENDPOINTS } from '../common/endpoints';
import { BaseProductRecommendationPayload, ProductRecommendationResponse } from '../common/types';

export interface EducationRecommendationFormPayload extends BaseProductRecommendationPayload {
  university_cost: number | null
}

export async function getEducationRecommendationProduct(
  payload: EducationRecommendationFormPayload
) {
  return api.post<ProductRecommendationResponse>(
    PRODUCT_RECOMMENDATION_ENDPOINTS.EDUCATION,
    payload,
    {
      ...authOptions,
      headers: {
        ...authOptions.headers,
      },
    });
}
