/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PensionFormValues, pensionFormSchema, usePensionForm } from './usePensionForm';
import {
  AppendableField,
  DatepickerField,
  InputField,
  NumericFormatField,
  RadioField,
  SelectField,
} from '../../Forms/Fields';
import {
  AdditionalSavings,
  BooleanOptions, GenderOptions, Genders,
  SavingsOption,
  SavingsTypeOptions,
  StringifiedBooleans,
} from '../../../utils/constants';
import { RadioBoxField } from '../../Forms/Fields/RadioBoxField';
import { ButtonLoading } from '../../common/ButtonLoading';

export function PensionForm() {
  const { handleSubmit, isLoading } = usePensionForm();
  const methods = useForm<PensionFormValues>({
    defaultValues: {
      gender: GenderOptions.MALE,
      children: StringifiedBooleans.FALSE,
      wants_insurance_savings: StringifiedBooleans.TRUE,
      children_births: [],
      savings_option: SavingsOption.savings_capacity,
      monthly_savings: 0,
      ideal_pension: 0,
      net_salary: 0,
      has_other_investments: StringifiedBooleans.FALSE,
      other_investments: [],
    },
    resolver: zodResolver(pensionFormSchema),
  });

  const savingsOption = methods.watch('savings_option');
  const hasChildren = methods.watch('children') === StringifiedBooleans.TRUE;
  const hasOtherInvestments = methods.watch('has_other_investments') === StringifiedBooleans.TRUE;

  const handleTriggerErrors = () => methods.formState.errors;

  const onSubmit = (values: PensionFormValues) => {
    handleSubmit(values);
  };

  // Cleanup counterpart field on saving option radio box selection
  useEffect(() => {
    if (savingsOption === 'savings_capacity') {
      methods.setValue('ideal_pension', 0);
    }

    if (savingsOption === 'ideal_pension') {
      methods.setValue('monthly_savings', 0);
    }
  }, [savingsOption]);

  // Cleanup `children_births` field on `no` radio button selection
  useEffect(() => {
    if (!hasChildren) {
      methods.setValue('children_births', []);
    }
  }, [hasChildren]);

  // Cleanup `other_investments` on `no` radio button selection
  useEffect(() => {
    if (!hasOtherInvestments) {
      methods.setValue('other_investments', []);
    }
  }, [hasOtherInvestments]);

  return (
    <FormProvider {...methods}>
      <form className="card-form" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField<PensionFormValues>
          id="fullname"
          path="fullname"
          label="¿Cuál es tu nombre?"
          placeholder="Ingresa su nombre"
        />

        <DatepickerField<PensionFormValues>
          id="date_of_birth"
          path="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
        />

        <RadioField<PensionFormValues>
          path="gender"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          label="¿Cuál es tu género?"
        />

        <RadioField<PensionFormValues>
          path="children"
          options={BooleanOptions.asRadioOptions()}
          label="¿Tienes hijos?"
        />

        {hasChildren && (
          <div className="d-flex flex-column gap-4">
            <AppendableField
              hasDefaultField
              name="children_births"
              id="children_births"
              label="¿Cuál es la fecha de nacimiento de tu hijo/a?"
              defaultAppendButtonText="Agregar otro hijo"
              renderInput={(fieldId, index) => (
                <DatepickerField<PensionFormValues>
                  key={fieldId}
                  id={fieldId}
                  path={`children_births.${index}`}
                />
              )}
            />
          </div>
        )}

        <RadioField<PensionFormValues>
          path="wants_insurance_savings"
          options={BooleanOptions.asRadioOptions().reverse()}
          label="¿Quieres ahorrar con seguro?"
        />

        <RadioBoxField<PensionFormValues>
          path="savings_option"
          options={
            SavingsTypeOptions
              .asRadioBoxOptions({ select: ['savings_capacity', 'ideal_pension'] })
          }
          label="¿Cómo te gustaría ahorrar?"
        />

        {savingsOption === 'savings_capacity' && (
          <NumericFormatField<PensionFormValues>
            id="monthly_savings"
            label="¿Cuánto puedes ahorrar al mes?"
            path="monthly_savings"
            prefix="$ "
            placeholder="$"
          />
        )}

        {savingsOption === 'ideal_pension' && (
          <NumericFormatField<PensionFormValues>
            id="ideal_pension"
            label="¿Cuál es tu pensión ideal?"
            path="ideal_pension"
            prefix="$ "
            placeholder="$"
          />
        )}

        <NumericFormatField<PensionFormValues>
          id="net_salary"
          label="¿Cuál es tu sueldo líquido? (Opcional)"
          path="net_salary"
          prefix="$ "
          placeholder="$"
        />

        <RadioField<PensionFormValues>
          path="has_other_investments"
          options={BooleanOptions.asRadioOptions()}
          label="¿Tienes otros ahorros e inversiones? (Opcional)"
        />

        {hasOtherInvestments && (
          <AppendableField
            hasDefaultField
            id="other_investments"
            name="other_investments"
            renderInput={(fieldId, index) => (
              <SelectField<PensionFormValues>
                key={fieldId}
                id={fieldId}
                path={`other_investments.${index}`}
                options={AdditionalSavings.asSelectOptions()}
                placeholder="Selecciona"
                anchorBehavior="element"
              />
            )}
          />
        )}

        <ButtonLoading
          type="submit"
          isLoading={isLoading}
          onClick={handleTriggerErrors}
          className="btn btn-secondary py-3 mt-2"
          spinnerProps={{
            size: 24,
          }}
        >
          Recomendar Producto
        </ButtonLoading>

      </form>
    </FormProvider>
  );
}
