/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import { BaseError } from '../Errors';

interface SliderFieldProps<T extends FieldValues> {
  min: number
  max: number
  step: number
  path: Path<T>
  hideErrorMessage?: boolean
}

export function SliderField<T extends FieldValues>({
  min, max, step, path, hideErrorMessage,
}: SliderFieldProps<T>) {
  const { register, getFieldState, watch } = useFormContext<T>();
  const { error } = getFieldState(path);
  const currentValue = watch(path);

  // `min` and `max` creates are excluded because don't represent steps in the track
  // so we need to substract two steps in order to generate background correctly
  const excludingRatio = step * 2;
  const backgroundSize = `${((currentValue - excludingRatio) * 100) / (max - excludingRatio)}% 100%`;

  return (
    <div>
      <input
        min={min}
        max={max}
        step={step}
        type="range"
        className="input-range"
        style={{ backgroundSize }}
        {...register(path)}
      />

      {!hideErrorMessage && error && <BaseError message={error?.message} />}
    </div>
  );
}
