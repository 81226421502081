import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';
import { LoadingSpinner } from '../common';

interface MultiplePreviewModalProps {
  isLoadingUpload: boolean
  documents: File[]
  isOpen: boolean
  handleCloseMultiplePreview: () => void
  handleSendDocuments: () => Promise<void>
}

export function MultiplePreviewModal({
  documents,
  isOpen,
  isLoadingUpload,
  handleCloseMultiplePreview,
  handleSendDocuments,
}: MultiplePreviewModalProps) {
  const [document, setDocument] = useState<File>();
  const [index, setIndex] = useState(0);
  const [pages, setPages] = useState(0);

  const isLastDocument = index + 1 === documents.length;
  const isFirstDocument = index === 0;

  useEffect(() => {
    if (isOpen) {
      setDocument(documents[index]);
    } else {
      setDocument(undefined);
      setIndex(0);
    }
  }, [isOpen]);

  useEffect(() => {
    setDocument(documents[index]);
  }, [index]);

  const handleLoadPages = (documentPages: number) => {
    setPages(documentPages);
  };

  const handleNextDocument = async () => {
    try {
      if (isLastDocument) {
        await handleSendDocuments();
        return;
      }

      const next = index + 1;

      if (next < documents.length) {
        setIndex(next);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handlePrevDocument = () => {
    if (isFirstDocument) {
      handleCloseMultiplePreview();
      return;
    }

    const prev = index - 1;

    if (prev >= 0) {
      setIndex(prev);
    }
  };

  const buttonsNavigationTexts = useMemo(() => ({
    back: isFirstDocument ? 'Cancelar' : 'Anterior',
    next: isLastDocument ? 'Enviar' : 'Siguiente',
  }), [isFirstDocument, isLastDocument]);

  return (
    <Modal show={isOpen} onHide={handleCloseMultiplePreview} size="xl" centered>
      <Modal.Header className="p-6" closeButton closeLabel="Cerrar" />
      <Modal.Body className="px-6 pt-0 pb-8 text-center">
        <header className="mb-4">
          <img
            className="mb-4"
            src="/static/img/icons/danger-triangle.svg"
            width={64}
            alt=""
          />
          <h3 className="fs-4 text-primary mb-4">
            Revisa y confirma que
            <br />
            los documentos estén correctos
          </h3>

          <div className="fw-bold text-subtitle">
            {index + 1}
            /
            {documents.length}
          </div>
        </header>

        <div className="previsualization-container">
          {document && (
            <Document
              file={URL.createObjectURL(document)}
              onLoadSuccess={({ numPages }) => handleLoadPages(numPages)}
            >
              {Array.from(new Array(pages), (el, i) => (
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  key={`page_${i + 1}`}
                  pageNumber={i + 1}
                />
              ))}
            </Document>
          )}
        </div>

        <footer className="row justify-content-center g-4">
          <div className="col-md-3 col-lg-2">
            <button disabled={isLoadingUpload} onClick={handlePrevDocument} type="button" className="btn btn-outline-primary w-100">
              {buttonsNavigationTexts.back}
            </button>
          </div>
          <div className="col-md-3 col-lg-2">
            <button disabled={isLoadingUpload} onClick={() => { handleNextDocument().catch(console.error); }} type="button" className="btn btn-primary w-100">
              { isLoadingUpload ? <LoadingSpinner /> : <span>{buttonsNavigationTexts.next}</span> }
            </button>
          </div>
        </footer>
      </Modal.Body>
    </Modal>
  );
}
