import { simulatorsApi } from './api';
import { RegimeOptions } from './constants';

const REGIME_RECOMMENDATION_ENDPOINT = '/other/recommend-apv-regime/';

interface RegimeRecommendationResponse {
  recommended_regime: keyof typeof RegimeOptions
}

export async function getRecommendedRegime(net_income: number) {
  const response = await simulatorsApi.get(REGIME_RECOMMENDATION_ENDPOINT, {
    params: {
      net_income,
    },
  });

  return response.data as RegimeRecommendationResponse;
}
