/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

interface SwitchFieldProps<T extends FieldValues> {
  id: string
  label: string
  path: Path<T>
  className?: string
}

export function SwitchField<T extends FieldValues>({
  id,
  label,
  path,
  className,
}: SwitchFieldProps<T>) {
  const { register } = useFormContext<T>();
  const field = register(path);

  return (
    <div className={clsx('form-check form-switch', className)}>
      <input
        id={id}
        className="form-check-input"
        type="checkbox"
        role="switch"
        {...field}
      />
      <label className="form-check-label" htmlFor={id}>{label}</label>
    </div>
  );
}
