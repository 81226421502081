import React from 'react';
import { PASSWORD_VALIDATION_MESSAGES } from './validations';

/**
 * Backend error variables variants for new password validation
 */
export type ErrorCode =
  'password_too_short' |
  'password_too_common' |
  'password_entirely_numeric' |
  'password_too_similar';

/**
 * Raw field error given by backend
 *
 * It contains a backend message and code
 */
export interface ErrorField {
  message: string
  code: ErrorCode
}

interface ErrorItemProps {
  message: string
  isChecked: boolean
}

function BackendErrorItem({ message, isChecked }: ErrorItemProps) {
  return (
    <li className="d-flex align-items-start gap-3">
      <img src={isChecked ? '/static/img/icons/checked-list-decoration.svg' : '/static/img/icons/uncheck-list-decoration.svg'} alt="check requirement status" />

      <span>
        {message}
      </span>
    </li>
  );
}

/**
 * This interface represents the validated fields and the errors array for those fields
 */
export interface BackendFormValidationSchema {
  new_password2: ErrorField[]
}

interface RequirementsListProps {
  errors: string[]
}

/**
 * This component is responsible of taking errors thrown during backend form validation
 * and list them.
 *
 * Notice we just map field errors `codes` to custom messages
 *
 * If the user accomplish the requirement, list element is checked
 * If list element is mapped to an existing code in backend errors array, it keeps unchecked
 */
export function BackendErrorsList({ errors }: RequirementsListProps) {
  /**
   * Maps error codes to custom frontend messages
   */
  const errorsList: ErrorField[] = [
    {
      code: 'password_too_similar',
      message: PASSWORD_VALIDATION_MESSAGES.nonPersonalInfo,
    },
    {
      code: 'password_too_short',
      message: PASSWORD_VALIDATION_MESSAGES.minLength,

    },
    {
      code: 'password_too_common',
      message: PASSWORD_VALIDATION_MESSAGES.nonCommon,
    },
    {
      code: 'password_entirely_numeric',
      message: PASSWORD_VALIDATION_MESSAGES.nonOnlyNumericChars,
    },
  ];

  return (
    <ul className="px-0 d-flex flex-column gap-3 m-0">
      {errorsList.map(({ code, message }) => (
        <BackendErrorItem
          key={code}
          message={message}
          isChecked={!errors?.find((err) => err === code)}
        />
      )
      )}
    </ul>
  );
}
