import { z } from 'zod';
import { useEffect } from 'react';
import { formatMoney } from '../../../utils/formatters';
import { BaseSimulationParams, MutualFundsBaseSimulationParams, SimulateMutualFundsVariants } from '../../../interfaces/simulations';
import { isValidOtherGoal } from '../../../behaviors/simulations/simulation-utils';
import { simulationManager } from '../../../utils/simulationsManager';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';

const MAX_YEARS_AMOUNT = 100;
const MIN_YEARS_AMOUNT = 1;
const MIN_AMOUNT = 0;

export const mutualFundsMonthlyContributionValidationSchema = z.object({
  goal_type:
    z.object({
      label: z.string(),
      value: z.string(),
    }, { required_error: 'Este campo es requerido' }),
  goal:
    z.number({ required_error: 'Este campo es requerido' })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  other_goal:
    z.string().default(''),
  time_frame:
    z.number({ required_error: 'Este campo es requerido' })
      .min(MIN_YEARS_AMOUNT, `El mínimo de tiempo no debe ser menor a ${MIN_YEARS_AMOUNT} año`)
      .max(MAX_YEARS_AMOUNT, `El máximo de tiempo es de ${MAX_YEARS_AMOUNT} años`),
  risk_profile:
    z.object({
      label: z.string(),
      value: z.string(),
    }),
}).refine((data) => isValidOtherGoal(data.goal_type.value, data.other_goal), {
  message: 'Debes indicar qué meta quieres alcanzar',
  path: ['other_goal'],
});

export type MutualFundsMonthlyContributionSimulationValues =
  z.infer<typeof mutualFundsMonthlyContributionValidationSchema>;

interface MutualFundsMonthlyContributionPayload extends BaseSimulationParams {
  goal_type: string,
  goal: number
  time_frame: number
  risk_profile: string
  other_goal: string
  simulate: SimulateMutualFundsVariants
}

export function useMutualFundsMonthlyContribution() {
  const mapFormToPayload = (values: MutualFundsMonthlyContributionSimulationValues) => {
    const {
      goal, goal_type, time_frame, other_goal, risk_profile,
    } = values;

    const payload: MutualFundsMonthlyContributionPayload = {
      goal,
      goal_type: goal_type.value,
      other_goal,
      time_frame,
      risk_profile: risk_profile.value,
      simulate: 'monthly_contribution',
      simulation_type: 'MUTUAL_FUNDS',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: MutualFundsMonthlyContributionSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<MutualFundsMonthlyContributionPayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['mutual-funds'],
          title: 'mutual-funds-monthly-contribution-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'MUTUAL_FUNDS_MONTHLY_CONTRIBUTION');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_form_FM',
          variant: 'monthly_contribution',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<MutualFundsBaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'MUTUAL_FUNDS',
          is_empty: true,
          simulate: 'monthly_contribution',
        },
      },
      iframeAttributes: {
        classNames: ['mutual-funds', 'empty'],
        title: 'empty-mutual-funds-monthly-contribution-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
