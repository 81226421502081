import { z } from 'zod';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { requiredString, selectOption } from '../../../utils/commonSchemaValidators';
import { GenderOptions, SavingsOption } from '../../../utils/constants';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { formatMoney } from '../../../utils/formatters';
import {
  validateAgeMayority, validateHasOtherInvestments, validateSavingsOption,
} from '../../../utils/validations';
import { EducationRecommendationFormPayload, getEducationRecommendationProduct } from './educationRecommendationService';
import { createRecommendationSuccessEvent } from '../common/events';

const MIN_SAVINGS_AMOUNT = 0;
export const EDUCATION_RECOMMENDATION_KEY = 'EDUCATION-RECOMMENDATION-FORM';

export const educationFormSchema = z.object({
  fullname: requiredString(),
  date_of_birth: requiredString()
    .refine((date_of_birth) => validateAgeMayority(date_of_birth), { message: 'El cliente debe ser mayor de edad ' }),
  gender: z.enum([GenderOptions.MALE, GenderOptions.FEMALE, GenderOptions.OTHER]),
  children: requiredString(),
  wants_insurance_savings: requiredString(),
  children_births: z.array(requiredString()),
  savings_option: z.enum([SavingsOption.savings_capacity, SavingsOption.university_value]),
  university_value: z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
    .min(MIN_SAVINGS_AMOUNT),
  monthly_savings:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(MIN_SAVINGS_AMOUNT),
  net_salary:
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD }),
  has_other_investments: requiredString(),
  other_investments: z.array(selectOption()),
})
  .refine(({ savings_option, university_value }) => validateSavingsOption(
    savings_option,
    'university_value',
    university_value
  ), {
    message: `El valor debe ser mayor a ${formatMoney(MIN_SAVINGS_AMOUNT)}`,
    path: ['university_value'],
  })
  .refine(({ savings_option, monthly_savings }) => validateSavingsOption(
    savings_option,
    'savings_capacity',
    monthly_savings
  ), {
    message: `El valor debe ser mayor a ${formatMoney(MIN_SAVINGS_AMOUNT)}`,
    path: ['monthly_savings'],
  })

  .refine(({ has_other_investments, other_investments }) => validateHasOtherInvestments(
    has_other_investments, other_investments.map(({ value }) => value) ?? null
  ),
  {
    path: ['other_investments'],
    message: VALIDATION_MESSAGES.REQUIRED_FIELD,
  });

export type EducationFormValues = z.infer<typeof educationFormSchema>;

export function useEducationForm() {
  const queryClient = useQueryClient();
  const mapFormToPayload = (formValues: EducationFormValues) => {
    const {
      fullname,
      other_investments,
      children_births,
      date_of_birth,
      gender,
      university_value,
      monthly_savings,
      net_salary,
      wants_insurance_savings,
    } = formValues;

    const payload: EducationRecommendationFormPayload = {
      name: fullname,
      additional_savings: other_investments.map(({ value }) => value),
      children: children_births.map((birth) => ({ date_of_birth: birth })),
      net_income: net_salary,
      savings_with_insurance: wants_insurance_savings === 'TRUE', // Converts stringified boolean into real boolean type,
      university_cost: university_value,
      monthly_savings,
      gender,
      date_of_birth,
    };

    return payload;
  };

  const {
    mutate: computeRecommendation,
    data: response,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: async (
      payload: EducationRecommendationFormPayload
    ) => getEducationRecommendationProduct(payload),
    onSuccess: (res) => {
      queryClient.setQueryData([EDUCATION_RECOMMENDATION_KEY], res.data);
      window.dispatchEvent(createRecommendationSuccessEvent(res.data.result_products[0].kind));
    },
  });

  const handleSubmit = (formValues: EducationFormValues) => {
    const payload = mapFormToPayload(formValues);
    computeRecommendation(payload);
  };

  return {
    handleSubmit, isLoading, isError, response,
  };
}
