/* eslint-disable no-void */
import { useEffect, useState } from 'react';
import { MinimumCapitalPayload, MinimumCapital, getMinimumCapital } from '../utils/minimumCapital';

type KeysForComputeMinimumCapital = Pick<MinimumCapital, 'itp' | 'accidental_death' | 'serious_illness_percentage'>;

export function useMinimumCapital(
  keysPayload: KeysForComputeMinimumCapital,
  productPayload: MinimumCapitalPayload
) {
  const { date_of_birth, gender, product } = productPayload;
  const { accidental_death, itp, serious_illness_percentage } = keysPayload;
  const [minimumCapitalVariants, setMinimumCapitalVariants] = useState<MinimumCapital[]>();

  const handleGetMinimumCapitalVariants = async (
    parameters: MinimumCapitalPayload
  ) => {
    try {
      const variants = await getMinimumCapital(parameters);

      setMinimumCapitalVariants(variants);
    } catch (err) {
      console.error(err);
    }
  };

  const minimumCapital = minimumCapitalVariants?.find((variant) => (
    variant.accidental_death === accidental_death
    && variant.itp === itp
    && variant.serious_illness_percentage === (serious_illness_percentage / 100))
  )?.minimun_death_capital ?? 0;

  useEffect(() => {
    if (!date_of_birth || !gender) return;

    void handleGetMinimumCapitalVariants({
      date_of_birth,
      gender,
      product,
    });
  }, [date_of_birth, gender, product]);

  return Math.ceil(minimumCapital);
}
