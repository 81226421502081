import React from 'react';
import { ProductRenderInfo } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const ProtectionProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/people-operation.svg',
  iconSrc: '/static/img/icons/graph-up.svg',
  hideButton: true,
  description: 'Seguro de vida que cuenta con un capital asegurado en caso de fallecimiento',

  benefits: () => [
    <span>Protege a tu familia con un bajo costo, por el tiempo que tu necesites</span>,
    <span>Libre elección de beneficiarios</span>,
    <span>Liquidez inmediata en caso de muerte</span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
