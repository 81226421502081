/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import {
  FormProvider, useForm, useFormContext,
} from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { IsnFormValues, useIsn } from './useIsn';
import { ButtonLoading } from '../common/ButtonLoading';

const ISN_RATING_ILLUSTRATIONS = [
  {
    baseImg: '/static/img/isn/isn-rating-1.svg',
    selectedImg: '/static/img/isn/isn-rating-1-selected.svg',
    value: 1,
  },
  {
    baseImg: '/static/img/isn/isn-rating-2.svg',
    selectedImg: '/static/img/isn/isn-rating-2-selected.svg',
    value: 2,
  },
  {
    baseImg: '/static/img/isn/isn-rating-3.svg',
    selectedImg: '/static/img/isn/isn-rating-3-selected.svg',
    value: 3,
  },
  {
    baseImg: '/static/img/isn/isn-rating-4.svg',
    selectedImg: '/static/img/isn/isn-rating-4-selected.svg',
    value: 4,
  },
  {
    baseImg: '/static/img/isn/isn-rating-5.svg',
    selectedImg: '/static/img/isn/isn-rating-5-selected.svg',
    value: 5,
  },
];

interface IsnRatingInputProps {
  baseImg: string
  value: number,
  selectedImg: string
  isChecked?: boolean
}

function IsnRatingInput({
  baseImg, selectedImg, value, isChecked,
} : IsnRatingInputProps) {
  const { setValue } = useFormContext<IsnFormValues>();

  const handleSelectRating = () => {
    setValue('rating', value);
  };

  return (
    <>
      <label className="visually-hidden" htmlFor={`rating-option-${value}`}>{value}</label>
      <input
        onChange={handleSelectRating}
        className="isn-rating-input"
        type="radio"
        name="rating"
        id={`rating-option-${value}`}
        style={{ backgroundImage: `url(${isChecked ? selectedImg : baseImg})` }}
      />
    </>
  );
}

export function IsnModal() {
  const {
    feedbackQuestion,
    handleSubmitFeedback,
    isError,
    isLoading,
  } = useIsn();
  const [displayModalButton, setDisplayModalButton] = useState(true);
  const [showModal, setShowModal] = useState(false);

  const form = useForm<IsnFormValues>({
    defaultValues: {
      additional_comments: '',
    },
  });

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => {
    setShowModal(false);
    form.reset();
  };

  const { rating } = form.watch();
  const isFilled = rating;

  const onSubmit = async (formValues: IsnFormValues) => {
    await handleSubmitFeedback(formValues);
    // Automatically closes modal on successful submit posting
    handleCloseModal();

    // Hide modal button once successful submit posting
    setDisplayModalButton(false);
  };

  return (
    <>
      {displayModalButton && (
        <button onClick={handleShowModal} type="button" className="btn btn-secondary isn-modal-button" aria-label="Danos tu opinión">
          <img className="isn-modal-button-icon" src="/static/img/icons/isn-thumb-up.svg" alt="" />
          <span className="isn-modal-button-text">Danos tu opinión</span>
        </button>
      )}

      <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header className="px-6 pt-6 pb-0" closeButton />

        <Modal.Body className="px-6 pb-6 pt-4">
          <FormProvider {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <fieldset className="mb-4">
                <legend className="fs-lg fw-bold text-center text-subtitle mb-4">Nos ayudaría conocer tu opinión</legend>

                <div id="rating-label" className="d-block text-center fs-xl text-primary fw-bold mb-4">
                  {feedbackQuestion?.question ?? '...'}
                  <span className="visually-hidden">Asigne un puntaje del 1 al 5</span>
                </div>
                <div aria-labelledby="rating-label" role="radiogroup" className="d-flex gap-2 justify-content-center">
                  {ISN_RATING_ILLUSTRATIONS.map(({
                    value,
                    baseImg,
                    selectedImg,
                  }) => (
                    <IsnRatingInput
                      key={value}
                      baseImg={baseImg}
                      selectedImg={selectedImg}
                      value={value}
                      isChecked={rating === value}
                    />
                  ))}
                </div>
              </fieldset>

              <div className="row">
                <div className="col-12 col-md-6 mx-auto">
                  <div className="mb-4">
                    <label className="d-block text-center text-muted fs-sm mb-4" htmlFor="additional-comments">¿Quieres agregar un comentario adicional?</label>
                    <textarea placeholder="Mensaje" className="form-control" rows={5} id="additional-comments" {...form.register('additional_comments')} />
                  </div>

                  <ButtonLoading
                    isLoading={isLoading}
                    disabled={!isFilled}
                    type="submit"
                    className="btn btn-secondary w-100 mb-4"
                    spinnerProps={{
                      size: 24,
                    }}
                  >
                    Enviar
                  </ButtonLoading>

                  <button type="button" className="btn btn-link w-100" onClick={handleCloseModal}>
                    Omitir encuesta
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>

          {isError && (
            <div className="alert alert-danger mb-0" role="alert">
              <strong className="text-danger fs-6">Oops! Algo ha salido mal. Intente más tarde..</strong>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
