/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMask } from '@react-input/mask';
import { DeriveCustomerValues, deriveCustomerValidationSchema, useDeriveCustomerForm } from './useDeriveCustomerForm';
import { RadioBoxField } from '../Forms/Fields/RadioBoxField';
import {
  COMPANIES,
  CompaniesOptions,
  CompaniesProducts,
  DeriveCustomerTimeOptions,
} from '../../utils/constants';
import { DatepickerField, InputField, SelectField } from '../Forms/Fields';
import { DeriveCustomerFormHeader } from './DeriveCustomerFormHeader';
import { getEnv } from '../../utils/publicEnv';
import { handleRutChange } from '../../utils/formHandlers';
import { mapQueryParamsToRecommendationPayload } from '../RecommenderForms/common/queryParams';

const COMPANIES_TRASPASING_DATA = {
  [COMPANIES.INVESTMENTS]: {
    companyName: 'BICE Inversiones',
    companyTraspassingLink: getEnv('BICE_INVESMENTS_DERIVATION_LINK'),
  },
  [COMPANIES.LIFE]: {
    companyName: 'BICE Vida',
    companyTraspassingLink: getEnv('BICE_LIFE_DERIVATION_LINK'),
  },
};
export function DeriveCustomerForm() {
  // Load query parameters and tries to fill the default values based on them
  const searchParams = new URLSearchParams(window.location.search);
  const valuesFromRecommender = mapQueryParamsToRecommendationPayload(searchParams);
  const [firstName, lastName] = valuesFromRecommender.name.split(' ');

  const methods = useForm<DeriveCustomerValues>({
    defaultValues: {
      firstName,
      lastName,
      company: COMPANIES.INVESTMENTS,
      phone: '',
    },
    resolver: zodResolver(deriveCustomerValidationSchema),
  });

  const { handleSubmit } = useDeriveCustomerForm(methods.setError);
  const handleTriggerErrors = () => methods.formState.errors;

  const formValues = methods.watch();
  const selectedCompany = methods.watch('company');
  const { companyTraspassingLink, companyName } = COMPANIES_TRASPASING_DATA[selectedCompany];

  const isPhoneNumberMaskFullfilled = () => formValues.phone.split('').every((ch) => ch !== '_');
  const canSubmit = Object.values(formValues).every((v) => Boolean(v))
    && isPhoneNumberMaskFullfilled();

  const phoneNumberMaskRef = useMask({ showMask: true, mask: '+56 _________', replacement: { _: /\d/ } });

  useEffect(() => methods.setValue('product', null), [formValues.company]);

  return (
    <div>
      <DeriveCustomerFormHeader
        companyName={companyName}
        companyTraspassingLink={companyTraspassingLink ?? '#'}
      />

      <FormProvider {...methods}>
        <form className="d-flex flex-column gap-4" onSubmit={methods.handleSubmit(handleSubmit)}>
          <RadioBoxField<DeriveCustomerValues>
            label="¿A qué compañía quieres derivar?"
            labelClassName="fs-sm fw-bold mb-2 d-inline-block"
            path="company"
            options={CompaniesOptions.asRadioBoxOptions()}
            selectedClassName="bg-secondary fw-bold text-white shadow-sm"
          />

          <div className="row">
            <div className="col-12 col-md-6">
              <InputField<DeriveCustomerValues>
                className="form-control form-control-sm "
                id="firstName"
                path="firstName"
                type="text"
                placeholder="Nombre"
              />
            </div>

            <div className="col-12 col-md-6">
              <InputField<DeriveCustomerValues>
                className="form-control form-control-sm"
                id="lastName"
                path="lastName"
                type="text"
                placeholder="Apellido"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-md-6">
              <InputField<DeriveCustomerValues>
                className="form-control form-control-sm"
                id="rut"
                path="rut"
                type="text"
                placeholder="Rut"
                onChange={handleRutChange}
              />
            </div>

            <div className="col-12 col-md-6">
              <InputField<DeriveCustomerValues>
                className="form-control form-control-sm"
                id="email"
                path="email"
                type="email"
                placeholder="Correo"
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12 col-md-6">
              <InputField<DeriveCustomerValues>
                className="form-control form-control-sm"
                id="phone"
                path="phone"
                type="text"
                placeholder="Teléfono"
                customRef={phoneNumberMaskRef}
              />
            </div>

            <div className="col-12 col-md-6">
              <SelectField<DeriveCustomerValues>
                id="product"
                path="product"
                formControlClassName="form-control-sm"
                options={CompaniesProducts.asSelectOptions(formValues.company)}
                placeholder="Selecciona un producto"
              />
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-12">
              <h4 className="fs-sm fw-bold mb-2 d-inline-block">Agenda un horario de atención (Lunes a Viernes desde 10:00 a 18:00 hrs)</h4>
            </div>

            <div className="col-12 col-md-6">
              <DatepickerField<DeriveCustomerValues>
                id="appointmentDate"
                path="appointmentDate"
                className="form-control form-control-sm"
              />

            </div>

            <div className="col-12 col-md-6">
              <SelectField<DeriveCustomerValues>
                id="appointmentTime"
                path="appointmentTime"
                formControlClassName="form-control-sm"
                placeholder="Selecciona un horario"
                options={DeriveCustomerTimeOptions.asSelectOptions()}
              />
            </div>
          </div>

          <div className="row justify-content-center">
            <div className="col-2">
              <button type="button" className="w-100 btn btn-outline-primary py-1">Cancelar</button>
            </div>
            <div className="col-2">
              <button disabled={!canSubmit} onClick={handleTriggerErrors} type="submit" className="w-100 btn btn-primary py-1">Derivar</button>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
