/* eslint-disable react/require-default-props */
import clsx from 'clsx';
import React from 'react';

interface ProductMessageProps {
  title: string
  titleClassName?: string;
  imgSrc: string
  renderContent: JSX.Element
  imgAlignment?: 'top' | 'center'
}

export function ProductMessage({
  title, renderContent, imgSrc, titleClassName = 'fs-lg text-primary mb-4', imgAlignment = 'top',
}: ProductMessageProps) {
  return (
    <article className={clsx('card-message', imgAlignment === 'top' && 'align-items-md-start')}>
      <img width={100} height={100} src={imgSrc} alt="" />

      <div>
        <header>
          <h3 className={titleClassName}>
            {title}
          </h3>
        </header>

        <div>
          {renderContent}
        </div>
      </div>
    </article>
  );
}
