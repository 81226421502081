export function formatMoney(value: number) {
  const money = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
  });

  return money.format(value);
}

export function formatUf(value: number) {
  const uf = new Intl.NumberFormat('es-CL', {
    maximumFractionDigits: 2,
  });

  return `${uf.format(value)} UF`;
}

interface FormatPercentageOptions {
  withFractionDigits?: boolean
}

export function formatPercentage(value: number, options?: FormatPercentageOptions) {
  const MINIMUM_FRACTION_DIGITS = 1;
  const MAXIMUM_FRACTION_DIGITS = 2;

  const percentage = new Intl.NumberFormat('es-CL', {
    style: 'percent',
    minimumFractionDigits: options?.withFractionDigits ? MINIMUM_FRACTION_DIGITS : 0,
    maximumFractionDigits: options?.withFractionDigits ? MAXIMUM_FRACTION_DIGITS : 0,
  });

  return percentage.format(value / 100);
}

export function formatRut(value: string) {
  let rut = value.replace(/-/g, '');

  if (rut !== '' && rut.length > 1) {
    rut = rut.replace(/\./g, '');

    const digits = rut.slice(0, -1).replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    const dv = rut.slice(-1);

    return `${digits}-${dv}`;
  }

  return value;
}
