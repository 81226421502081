import { simulatorsApi } from './api';

const SAVINGS_CAPACITY_SUGGESTION = '/public/apv-afp-projection/';

interface SavingsCapacitySuggestionResponse {
  optional_income_reduction: number
}

export async function getSavingsCapacitySuggestion(payload: unknown) {
  const response = await simulatorsApi
    .post<SavingsCapacitySuggestionResponse>(SAVINGS_CAPACITY_SUGGESTION, payload);

  return response.data;
}
