/* eslint-disable react/require-default-props */
import React, { useEffect } from 'react';
import {
  ArrayPath,
  FieldArray,
  FieldValues, useFieldArray, UseFieldArrayAppend,
} from 'react-hook-form';

const DEFAULT_APPEND_BUTTON_TEXT = 'Agregar otro';
interface DefaultAppendButtonProps {
  handleAppend: () => void
  buttonText?: string
}

function DefaultAppendButton({
  handleAppend,
  buttonText = DEFAULT_APPEND_BUTTON_TEXT,
}: DefaultAppendButtonProps) {
  return (
    <button
      onClick={handleAppend}
      type="button"
      className="btn btn-link gap-1 align-self-start"
    >
      <span>{buttonText}</span>
      <img alt="" src="/static/img/icons/append-primary.svg" width={20} height={20} />
    </button>

  );
}

interface DefaultRemoveButtonProps {
  handleRemove: () => void
}

function DefaultRemoveButton({ handleRemove }: DefaultRemoveButtonProps) {
  return (
    <button
      onClick={handleRemove}
      type="button"
      className="btn p-1 rounded-circle mt-3"
    >
      <img src="/static/img/icons/trash.svg" width={24} alt="Remover" />
    </button>
  );
}

interface AppendableFieldProps<T extends FieldValues> {
  id: string
  name: ArrayPath<T>
  label?: string
  hasDefaultField?: boolean
  defaultAppendButtonText?: string
  renderInput: (fieldId: string, index: number) => JSX.Element
  renderAppendButton?: (handleAppend: UseFieldArrayAppend<FieldValues, ArrayPath<T>>) => JSX.Element
  renderRemoveButton?: (handleRemove: () => void) => JSX.Element
}

/**
 *
 * Form field which allows to insert and remove same instance of fields dynamically.
 * As all the other field components, it works within `FormContext` provided by React Hook Forms.
 */
export function AppendableField<T extends FieldValues>({
  id,
  name,
  label,
  defaultAppendButtonText,
  renderInput,
  renderAppendButton,
  renderRemoveButton,
  hasDefaultField,
}: AppendableFieldProps<T>) {
  const { fields, append, remove } = useFieldArray({ name });

  useEffect(() => {
    // Appends an empty field if wants to display a default field
    if (hasDefaultField) {
      append({} as FieldArray<FieldValues, ArrayPath<T>>);
    }
  }, [hasDefaultField]);

  const removeButton = (index: number) => (renderRemoveButton
    ? renderRemoveButton(() => remove(index))
    : <DefaultRemoveButton handleRemove={() => remove(index)} />);

  return (
    <>
      {fields.map((field, index) => (
        <div key={`${id}-${field.id}`}>
          {label && (
            <label htmlFor={`${id}-${field.id}`} className="fs-sm fw-bold text-primary w-100">
              <span className="mb-2 d-inline-block">{label}</span>
            </label>
          )}
          <div className="d-flex align-items-start gap-2">
            {renderInput(field.id, index)}
            {/* Render remove button only if there are more than 1 field appended */}
            {fields.length > 1 && removeButton(index)}
          </div>
        </div>
      ))}

      {renderAppendButton
        ? renderAppendButton(append)
        : (
          <DefaultAppendButton
            handleAppend={() => {
              append({} as FieldArray<FieldValues, ArrayPath<T>>);
            }}
            buttonText={defaultAppendButtonText}
          />
        )}
    </>
  );
}
