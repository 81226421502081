/* eslint-disable react/button-has-type */
/* eslint-disable react/jsx-props-no-spreading */
import React, { ComponentProps } from 'react';
import clsx from 'clsx';
import { LoadingSpinner } from './LoadingSpinner';

interface ButtonLoadingProps extends ComponentProps<'button'> {
  isLoading: boolean
  spinnerProps: ComponentProps<typeof LoadingSpinner>
  children: JSX.Element | string
}

export function ButtonLoading({
  isLoading, spinnerProps, children, ...buttonProps
}: ButtonLoadingProps) {
  return (
    <button
      {...buttonProps}
      className={clsx(buttonProps.className, isLoading && 'disabled disabled-with-color')}
      disabled={isLoading || buttonProps.disabled}
    >
      {isLoading ? <LoadingSpinner {...spinnerProps} /> : children}
    </button>
  );
}
