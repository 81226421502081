import { z } from 'zod';
import { RefObject } from 'react';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';

export const filterMarketingsCampaingsSchema = z.object({
  published_date__gte: z.string().nonempty(VALIDATION_MESSAGES.REQUIRED_FIELD),
  published_date__lte: z.string().nonempty(VALIDATION_MESSAGES.REQUIRED_FIELD),
});

export type FilterMarketingsCampaingsValues = z.infer<typeof filterMarketingsCampaingsSchema>;

export function useFilterMarketingsCampaigns(formRef: RefObject<HTMLFormElement>) {
  const handleSubmitFilter = () => {
    formRef.current?.submit();
  };

  return { handleSubmitFilter };
}
