/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InputField } from '../Forms/Fields';
import { SearchDerivationValues, searchDerivationValidationSchema, useSearchDerivationForm } from './useSearchDerivationForm';
import { DerivationStatusTable } from './DerivationStatusTable';

export function SearchCustomerDerivationForm() {
  const methods = useForm<SearchDerivationValues>({
    resolver: zodResolver(searchDerivationValidationSchema),
  });

  const { handleSearchDerivation, tableData } = useSearchDerivationForm(methods.setError);

  const rut = methods.watch('rut');
  const handleTriggerErrors = () => methods.formState.errors;

  return (
    <div>
      <FormProvider {...methods}>
        <form className="row gx-3 justify-content-center" onSubmit={methods.handleSubmit(handleSearchDerivation)}>
          <div className="col-md-3">
            <InputField<SearchDerivationValues>
              id="search-derivation-rut"
              path="rut"
              type="text"
              placeholder="Rut"
            />
          </div>

          <div className="col-md-2">
            <button disabled={!rut} onClick={handleTriggerErrors} type="submit" className="btn py-4 btn-primary w-100 px-2">Revisar estado</button>
          </div>
        </form>
      </FormProvider>

      { tableData && <DerivationStatusTable tableData={tableData} />}
    </div>
  );
}
