import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Document, Page } from 'react-pdf';

interface SinglePreviewModalProps {
  document: File | null
  isOpen: boolean
  handleCloseSinglePreview: () => void
}

export function SinglePreviewModal({
  document,
  isOpen,
  handleCloseSinglePreview,
}: SinglePreviewModalProps) {
  const [pages, setPages] = useState(0);

  const handleLoadPages = (documentPages: number) => {
    setPages(documentPages);
  };

  return (
    <Modal show={isOpen} onHide={handleCloseSinglePreview} size="lg" centered scrollable>
      <Modal.Header className="p-6" closeButton closeLabel="Cerrar" />
      <Modal.Body className="px-6 pt-0 pb-8 text-center">
        {document && (
          <>
            <h3 className="fs-4 text-primary mb-4">
              {document.name}
            </h3>
            <Document
              file={URL.createObjectURL(document)}
              onLoadSuccess={({ numPages }) => handleLoadPages(numPages)}
            >
              {Array.from(new Array(pages), (el, index) => (
                <Page
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                />
              ))}
            </Document>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
}
