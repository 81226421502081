/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface MarkdownRendererProps {
  content: string
}

/**
 * Exposes the markdown renderer with pre defined plugins to accomplish application requirements.
 *
 * Here is the list of attached plugins:
 *  - [remark-gmf](https://github.com/remarkjs/remark-gfm): allows autolink literals, footnotes, strikethrough, tables and tasklists.
 */
export function MarkdownRenderer({ content }: MarkdownRendererProps) {
  return (
    <Markdown
      components={{
        // Overwrite base `table` component to attach `table` classname from bootstrap.
        table: (props) => {
          const { children, ...rest } = props;
          return <table {...rest} className="table">{children}</table>;
        },
        p: (props) => {
          const { children, ...rest } = props;
          return <p {...rest} className="mb-0">{children}</p>;
        },
      }}
      className="markdown-renderer"
      remarkPlugins={[remarkGfm]}
    >
      {content}
    </Markdown>
  );
}
