import React from 'react';

interface DeriveCustomerFormHeaderProps {
  companyName: string
  companyTraspassingLink: string
}

export function DeriveCustomerFormHeader({
  companyTraspassingLink,
  companyName,
}: DeriveCustomerFormHeaderProps) {
  return (
    <section>
      <section className="card shadow mb-6">
        <div className="d-flex gap-2 align-items-center card-with-icon-cms py-4">
          <img width={120} src="/static/img/illustrations/problem-solving.svg" alt="invesments header icon" />
          <div className="content">
            <div className="d-flex flex-column gap-4">
              <p className="text-center mb-0">
                <span>{'Para entregar una asesoría completa de nuestros productos, '}</span>
                <span className="fw-bold">{'puedes referir a tu cliente si este necesita otro producto de otra compañía. '}</span>
                <span>{'Recuerda que puedes '}</span>
                <span className="fw-bold">{'ganar un incentivo '}</span>
                <span>si el ejecutivo al que referiste a tu cliente cierra su venta.</span>
              </p>

              <p className="text-center mb-0">
                <span>
                  {`Revisa el incentivo de ${companyName} `}
                  <a className="text-body fw-bold" target="_blank" href={companyTraspassingLink} rel="noreferrer">aquí.</a>
                </span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <header className="mb-5">
          <h2 className="text-center text-body fs-5 fw-bold">Completa los datos de tu cliente</h2>
        </header>
      </section>
    </section>
  );
}
