import { z } from 'zod';
import { useEffect } from 'react';
import { formatMoney } from '../../../utils/formatters';
import { BaseSimulationParams, MutualFundsBaseSimulationParams, SimulateMutualFundsVariants } from '../../../interfaces/simulations';
import { isValidOtherGoal } from '../../../behaviors/simulations/simulation-utils';
import { simulationManager } from '../../../utils/simulationsManager';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';

const MIN_AMOUNT = 0;

export const mutualFundsTimeFrameValidationSchema = z.object({
  goal_type:
    z.object({
      label: z.string(),
      value: z.string(),
    }, { required_error: 'Este campo es requerido' }),
  goal:
    z.number({ required_error: 'Este campo es requerido' })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  other_goal:
    z.string().default(''),
  monthly_contribution:
    z.number({ required_error: 'Este campo es requerido' })
      .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
  risk_profile:
    z.object({
      label: z.string(),
      value: z.string(),
    }),
}).refine((data) => isValidOtherGoal(data.goal_type.value, data.other_goal), {
  message: 'Debes indicar qué meta quieres alcanzar',
  path: ['other_goal'],
})
  .refine((data) => data.monthly_contribution < data.goal, {
    message: 'La contribución mensual no puede ser mayor a la meta',
    path: ['monthly_contribution'],
  });

export type MutualFundsTimeFrameSimulationValues =
  z.infer<typeof mutualFundsTimeFrameValidationSchema>;

interface MutualFundsTimeFramePayload extends BaseSimulationParams {
  goal_type: string,
  goal: number
  monthly_contribution: number
  risk_profile: string
  other_goal: string
  simulate: SimulateMutualFundsVariants
}

export function useMutualFundsTimeFrame() {
  const mapFormToPayload = (values: MutualFundsTimeFrameSimulationValues) => {
    const {
      goal, goal_type, monthly_contribution, other_goal, risk_profile,
    } = values;

    const payload: MutualFundsTimeFramePayload = {
      goal,
      goal_type: goal_type.value,
      other_goal,
      monthly_contribution,
      risk_profile: risk_profile.value,
      simulate: 'time_frame',
      simulation_type: 'MUTUAL_FUNDS',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: MutualFundsTimeFrameSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<MutualFundsTimeFramePayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['mutual-funds'],
          title: 'mutual-funds-time-frame-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'MUTUAL_FUNDS_TIME_FRAME');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_form_FM',
          variant: 'time_frame',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<MutualFundsBaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'MUTUAL_FUNDS',
          is_empty: true,
          simulate: 'time_frame',
        },
      },
      iframeAttributes: {
        classNames: ['mutual-funds', 'empty'],
        title: 'empty-mutual-funds-monthly-contribution-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
