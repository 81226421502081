import { z } from 'zod';
import { useEffect } from 'react';
import { formatMoney, formatPercentage, formatUf } from '../../../utils/formatters';
import { simulationManager } from '../../../utils/simulationsManager';
import { BaseSimulationParams } from '../../../interfaces/simulations';
import {
  MAX_AGE_BY_GENDER,
  MIN_AGREED_PREMIUM_UF,
  validateAgeMayority,
  validateHasAgreedPremium,
  validateHasIdealPension,
  validateHasRetirementAge,
  validateInitialContribution,
  validateMaxAgeByGender,
  validateMinimumRetirementAge,
  validateRutByRegexp,
} from '../../../utils/validations';
import {
  SavingsOption, GenderOptionType, INITIAL_CONTRIBUTION_OPTIONS,
  StringifiedBooleans,
} from '../../../utils/constants';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { selectOption } from '../../../utils/commonSchemaValidators';

const MIN_AMOUNT = 0;
const MIN_NET_INCOME = 1;
const [MIN_PROJECTION_SAVINGS, MAX_PROJECTION_SAVINGS] = [1, 5];

export const apvLifeValidationSchema = (minimumCapital: number) => z.object({
  client: z.object({
    savings_option: z.enum([SavingsOption.ideal_pension, SavingsOption.savings_capacity]),
    ideal_pension:
      z.number({ required_error: 'Este campo es requerido' })
        .min(MIN_AMOUNT, `El valor no debe ser menor a ${formatMoney(MIN_AMOUNT)}`),
    rut: z.string()
      .refine((rut) => validateRutByRegexp(rut), { message: VALIDATION_MESSAGES.INVALID_RUT }),
    date_of_birth: z.string().nonempty('Este campo es requerido'),
    change_retirement_age:
      z.enum([StringifiedBooleans.TRUE, StringifiedBooleans.FALSE]),
    retirement_age:
      z.number().nullable(),
    gender: z.string().nonempty('Este campo es requerido'),
    net_income:
      z.number()
        .min(MIN_NET_INCOME, `El valor no debe ser menor a ${formatMoney(MIN_NET_INCOME)}`),
    afp_balance:
      z.number()
        .nonnegative(`El valor no debe ser menor a ${formatMoney(0)}`)
        .nullable(),
    initial_contribution_option:
      z.enum([
        INITIAL_CONTRIBUTION_OPTIONS.NONE,
        INITIAL_CONTRIBUTION_OPTIONS.TRANSFER,
        INITIAL_CONTRIBUTION_OPTIONS.UNIQUE_CONTRIBUTION,
      ]),
    contribution_amount:
      z.number(),
    source_transfer:
      selectOption().nullable(),
  }),

  savings: z.object({
    projection_rate: z.preprocess(
      (amount) => Number(amount as string),
      z.number()
        .min(MIN_PROJECTION_SAVINGS, `El valor no debe ser menor a ${formatPercentage(MIN_PROJECTION_SAVINGS)}`)
        .max(MAX_PROJECTION_SAVINGS, `El valor no debe ser mayor a ${formatPercentage(MAX_PROJECTION_SAVINGS)}`),
    ),

    compensation: z.string().nonempty('Este campo es requerido'),

    death_insured_capital:
      z.number({ required_error: 'Este campo es requerido ' })
        .min(minimumCapital, `El capital mínimo es de UF ${minimumCapital}`),

    extra_compensation_insured: z.object({
      accidental_death: z.boolean(),
      itp: z.boolean(),
    }),

    agreed_premium:
      z.number({ required_error: 'Este campo es requerido' }),
  }),

  regime: z.string().nonempty('Este campo es requerido'),
})
  .refine(({ client }) => validateHasIdealPension(client.savings_option, client.ideal_pension), {
    message: 'Este campo es requerido',
    path: ['client.ideal_pension'],
  })
  .refine(({ client }) => validateInitialContribution(
    client.initial_contribution_option,
    client.contribution_amount,
    client.source_transfer?.value),
  {
    message: 'La información de traspaso es requerida',
    path: ['client.initial_contribution_option'],
  })
  .refine(({ client, savings }) => validateHasAgreedPremium(
    client.savings_option,
    savings.agreed_premium
  ), {
    message: `La prima convenida no puede ser menor a ${formatUf(MIN_AGREED_PREMIUM_UF)}`,
    path: ['savings.agreed_premium'],
  })
  .refine(({ client }) => validateAgeMayority(client.date_of_birth), {
    message: 'El cliente debe ser mayor de edad',
    path: ['client.date_of_birth'],
  })

  .refine(({ client }) => validateMaxAgeByGender(
    client.date_of_birth,
    client.gender as GenderOptionType
  ),
  ({ client }) => ({
    // we need to compute MAX_CONTRACT_AGE - 1 because we want to show previous year to maximum
    // allowed year in error message
    message: `Supera la edad máxima de contratación de ${MAX_AGE_BY_GENDER[client.gender as GenderOptionType] - 1} años y 364 días`,
    path: ['client.date_of_birth'],
  }))
  .refine(({ client }) => validateHasRetirementAge(
    client.change_retirement_age,
    client.retirement_age),
  {
    message: 'Debes indicar una edad de retiro',
    path: ['client.retirement_age'],
  })
  .refine(({ client }) => validateMinimumRetirementAge(
    client.retirement_age,
    client.gender as GenderOptionType
  ),
  ({ client }) => ({
    message: `La edad mínima de jubilación es de ${MAX_AGE_BY_GENDER[client.gender as GenderOptionType]} años`,
    path: ['client.retirement_age'],
  }));

export type ApvLifeSimulationValues = z.infer<ReturnType<typeof apvLifeValidationSchema>>;

interface ApvLifePayload extends BaseSimulationParams {
  rut: string
  gender: string
  date_of_birth: string
  net_salary: number
  afp_balance: number
  projection_rate: number
  compensation_plan: string
  death_insured_capital: number
  accidental_death_insured_capital: number
  itp_insured_capital: number
  risk_profile: string
  apv_regime: string
  agreed_premium: number
  savings_option: string
  ideal_pension: number
  expected_retirement: number | null
  apv_transfer_origin: string
  apv_transfer_amount: number
  initial_contribution: number
}

export function useApvLife() {
  const mapFormToPayload = (values: ApvLifeSimulationValues) => {
    const { client, regime, savings } = values;
    const payload: ApvLifePayload = {
      savings_option: client.savings_option,
      ideal_pension: client.ideal_pension,
      gender: client.gender,
      date_of_birth: client.date_of_birth,
      net_salary: client.net_income,
      afp_balance: client.afp_balance || 0,

      apv_transfer_origin: client.source_transfer && client.initial_contribution_option === 'TRANSFER' ? client.source_transfer.value : '',
      apv_transfer_amount: client.initial_contribution_option === 'TRANSFER' ? client.contribution_amount : 0,
      initial_contribution: client.initial_contribution_option === 'UNIQUE_CONTRIBUTION' ? client.contribution_amount : 0,
      expected_retirement: client.retirement_age,

      // Transform projection rate into decimal percentage
      projection_rate: savings.projection_rate / 100,

      rut: client.rut,
      death_insured_capital: savings.death_insured_capital,
      compensation_plan: savings.compensation,
      agreed_premium: savings.agreed_premium,
      accidental_death_insured_capital: savings.extra_compensation_insured.accidental_death
        ? savings.death_insured_capital : 0,
      itp_insured_capital: savings.extra_compensation_insured.itp
        ? savings.death_insured_capital : 0,
      apv_regime: regime,
      risk_profile: 'moderate',
      simulation_type: 'APV_LIFE',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: ApvLifeSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<ApvLifePayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['apv-life'],
          title: 'apv-life-simulation',
        },
        // Mixing DOM API with React API is not the best way to make things work, but
        // Notice `#simulationContainer` element is outside React scope, so we need to use
        // Native DOM API to target the simulation container and then render the simulation
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'APV_LIFE');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_form_apv_life',
        },
      });

      if (values.client.savings_option === 'ideal_pension') {
        tag({
          event: EVENTS.BUTTON,
          variables: {
            id: 'btn_pension_ideal',
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<BaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'APV_LIFE',
          is_empty: true,
        },
      },
      iframeAttributes: {
        classNames: ['apv-life', 'empty'],
        title: 'apv-life-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
