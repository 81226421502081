import { enableButton, getSubmitButton } from '../../utils/forms';

function handleVariantSelection() {
  const variantSelectionForm = document.querySelector<HTMLFormElement>('#mutual-funds-variant-selection-form');

  if (!variantSelectionForm) return;

  const submitButton = getSubmitButton(variantSelectionForm);

  if (!submitButton) return;

  const radioInputs = variantSelectionForm.querySelectorAll<HTMLInputElement>('input[type=radio]');

  radioInputs.forEach((node) => {
    node.addEventListener('change', () => {
      enableButton(submitButton);
    });
  });
}

function loadValiantSelectionHandlers() {
  handleVariantSelection();
}

loadValiantSelectionHandlers();
