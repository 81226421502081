import axios from 'axios';
import { SimulationType } from '../interfaces/simulations';
import { api, authOptions } from './api';
import { CurrencyOptionType } from './constants';

const getGeneratePdfEndpoint = (simulationId: string) => `/infographic/${simulationId}/generate`;

export type SimulationClass = Exclude<SimulationType, 'MUTUAL_FUNDS' | 'APV' | 'INSURANCE_WITH_SAVINGS'>
| 'MUTUAL_FUNDS_TIME_FRAME'
| 'MUTUAL_FUNDS_MONTHLY_CONTRIBUTION';

export type DocumentType = 'infograph' | 'policy-projection';

export interface InfographGenerationParams {
  first_name: string,
  last_name: string,
  email: string,
  simulation_class: SimulationClass
  simulation_id: string,
  document_type: DocumentType
  currency: CurrencyOptionType
}

interface InfographGenerationResponse {
  url: string
}

export async function getInfograph(simulationId: string, payload: InfographGenerationParams) {
  const endpoint = getGeneratePdfEndpoint(simulationId);
  const response = await api.get<InfographGenerationResponse>(endpoint, {
    params: {
      ...payload,
    },
    responseType: 'json',
    ...authOptions,
    headers: {
      ...authOptions.headers,
    },
  });
  return response.data;
}

export async function getDocumentBlob(documentUrl: string) {
  // We use default `axios` instance because we dont want to hit `/api/v1` prefix given by
  // `api` object.
  // We just want to perform request hitting base path `/`
  return (await axios.get<Blob>(documentUrl, { responseType: 'blob' })).data;
}

export function generateDocumentFilename(params: Pick<InfographGenerationParams, 'simulation_id' | 'first_name' | 'last_name' |
'document_type' | 'simulation_class'>
) {
  return `${params.simulation_id}-${params.first_name}-${params.last_name}-${params.simulation_class}-${params.document_type}`;
}
