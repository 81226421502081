import { api, attachAuthOptions } from './api';

const TRANSFER_SOURCES_OPTIONS_ENDPOINT = '/simulations/transfer-origin-options';

export async function getTransferSourcesOptions() {
  return api.get<string[]>(
    TRANSFER_SOURCES_OPTIONS_ENDPOINT,
    attachAuthOptions()
  );
}
