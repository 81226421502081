/* eslint-disable react/no-array-index-key */
import React from 'react';
import clsx from 'clsx';
import { DerivationStatusTableData } from './useSearchDerivationForm';
import { DerivationStatus } from './deriveCustomerService';

interface StatusBadgeProps {
  status: DerivationStatus
}

function StatusBadge({ status }: StatusBadgeProps) {
  const badgeVariantMap: Record<DerivationStatus, Record<'className' | 'text', string>> = {
    derived: {
      className: 'bg-warning',
      text: 'Derivado',
    },
    contacted: {
      className: 'bg-info',
      text: 'Contactado',
    },
    hired: {
      className: 'bg-success',
      text: 'Contratado',
    },
    'not hired': {
      className: 'bg-danger',
      text: 'No contratado',
    },
    unresponsive: {
      className: 'bg-danger-dark',
      text: 'Cliente no contesta',
    },
  };

  const variant = badgeVariantMap[status];

  return (
    <span className={clsx(variant.className, 'w-100 d-block text-white fw-bold py-3 rounded-2')}>
      {variant.text}
    </span>
  );
}

interface DerivationStatusTableProps {
  tableData: DerivationStatusTableData[]
}

export function DerivationStatusTable({ tableData }: DerivationStatusTableProps) {
  return (
    <section className="my-5 shadow">
      <table className="w-100 overflow-hidden">
        <thead className="w-100">
          <tr className="row bg-secondary-light py-5 text-primary text-center fw-bold">
            <th className="col-3">Nombre</th>
            <th className="col-3">Rut</th>
            <th className="col-3">Producto</th>
            <th className="col-3">Estado</th>
          </tr>
        </thead>

        <tbody className="w-100">
          { tableData.map(({
            id,
            product_display,
            status,
            customer,
          }) => (
            <tr key={id} className="row align-items-center py-5 text-primary text-center">
              <td className="col-3">{`${customer.first_name} ${customer.last_name}`}</td>
              <td className="col-3">{customer.rut}</td>
              <td className="col-3">{product_display}</td>
              <td className="col-3 row justify-content-center">
                <span className="d-block col-10">
                  <StatusBadge status={status} />
                </span>
              </td>
            </tr>

          ))}
        </tbody>
      </table>
    </section>
  );
}
