import Glider from 'glider-js';

function autoplayGlider(glider: Glider, interval: number) {
  const totalSlides = glider.track.childElementCount;
  let slideTimeout: NodeJS.Timeout;
  let nextIndex = 1;
  let pause = true;

  function slide() {
    slideTimeout = setTimeout(
      () => {
        if (nextIndex >= totalSlides) {
          nextIndex = 0;
        }
        glider.scrollItem(nextIndex, true);
        nextIndex += 1;
      },
      interval
    );
  }

  glider.ele.addEventListener('glider-animated', () => {
    window.clearInterval(slideTimeout);
    slide();
  });
  glider.ele.addEventListener('mouseover', () => {
    if (pause) {
      clearInterval(slideTimeout);
      pause = false;
    }
  });
  glider.ele.addEventListener('mouseout', () => {
    if (!pause) {
      slide();
      pause = true;
    }
  });

  slide();
}

function initGlider(element: HTMLDivElement) {
  element.addEventListener('glider-loaded', () => {
    element.classList.add('loaded');
  });

  const glider = new Glider(element, {
    slidesToScroll: Number(element.dataset.gliderSlidesToShow) || 1,
    slidesToShow: 1,
    skipTrack: true,
    scrollLock: true,
    dots: element.nextElementSibling,
    responsive: [
      {
        breakpoint: 768, // Bootstrap `md` breakpoint
        settings: {
          slidesToShow: Number(element.dataset.gliderSlidesToShowMd) || 1,
        },
      },
    ],
  });

  const autoplayInterval = Number(element.dataset.gliderAutoplayInterval);
  if (autoplayInterval) {
    autoplayGlider(glider, autoplayInterval);
  }
}

// Initialize gliders
window.addEventListener('DOMContentLoaded', () => {
  const gliderElements = document.querySelectorAll<HTMLDivElement>('.glider');
  gliderElements.forEach(initGlider);
});
