/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { FieldValues, Path, useFormContext } from 'react-hook-form';

import { BaseError } from '../Errors';

interface RadioProps<T extends FieldValues> {
  label: string
  value: string
  id: string
  path: Path<T>
}

export type RadioOption<T extends FieldValues> = Omit<RadioProps<T>, 'path'>;

function Radio<T extends FieldValues>({
  id, label, value, path,
}: RadioProps<T>) {
  const { register } = useFormContext<T>();

  return (
    <div className="form-check">
      <input className="form-check-input" type="radio" id={id} value={value} {...register(path)} />
      <label htmlFor={id} className="form-check-label">
        {label}
      </label>
    </div>

  );
}

interface RadioButtonsProps<T extends FieldValues> {
  label: string
  options: Omit<RadioProps<T>, 'path'>[]
  path: Path<T>
}

export function RadioField<T extends FieldValues>({
  label, path, options,
}: RadioButtonsProps<T>) {
  const { getFieldState } = useFormContext<T>();
  const { error } = getFieldState(path);

  return (
    <div>
      <span className="fs-sm fw-bold text-primary mb-2 d-inline-block">{label}</span>

      <div className="d-flex w-100 gap-5">
        {options.map(({ id, value, label: optionLabel }) => (
          <Radio key={`${id}-${path}`} id={`${id}-${path}`} label={optionLabel} path={path} value={value} />)
        )}
      </div>

      <BaseError message={error?.message} />
    </div>
  );
}
