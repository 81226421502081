/**
 * Exposes the breakpoints widths for responsive layout rendering.
 *
 * It is based on the `$grid-breakpoints` variable in `_variables.scss` file.
 */
export const BREAKPOINTS = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  xxxl: 2560,
};
