import React from 'react';

export function ErrorAdornment() {
  return (
    <div className="error-adornment">
      {/* TODO: src should be determined by storage url given by backend */}
      <img src="/static/img/icons/error-adornment.svg" alt="error adornment icon" width={20} height={20} />
    </div>
  );
}
