import React from 'react';

function EmptyMessagesHeader() {
  return (
    <header>
      <div className="py-5 px-6 d-flex gap-6 bg-secondary-light rounded-3">
        <img
          className="d-none d-md-inline-block"
          src="/static/img/illustrations/person-marketing.svg"
          width={100}
          alt=""
        />

        <div>
          <h3 className="fs-5 fw-bold text-primary mb-4">¡Resolvamos tus dudas!</h3>

          <div className="row">
            <p className="mb-0 col-12 col-md-9">
              <span>
                {'Este '}
              </span>
              <span className="fw-bold text-secondary">
                {'Chat te ayudará a resolver tus dudas '}
              </span>
              <span>
                sobre nuestros productos de BICE Inversiones y BICE VIDA,
                para que puedas mejorar tus asesorías.
              </span>
            </p>
          </div>

        </div>
      </div>
    </header>
  );
}

interface KeypointsCardsProps {
  iconSrc: string
  title: string
  keypoints: string[]
}

function KeypointsCards({ title, keypoints, iconSrc }: KeypointsCardsProps) {
  return (
    <div className="card shadow-sm p-6">
      <header className="d-flex flex-column align-items-center gap-4 mb-6">
        <img src={iconSrc} width={120} alt="" />

        <h4 className="mb-0 fw-bold text-primary fs-6">{title}</h4>
      </header>

      <div className="d-flex flex-column gap-6">
        {keypoints.map((text, index) => (
          // Index key allowed because these are static text components
          // which will not change dynamically

          // eslint-disable-next-line react/no-array-index-key
          <div className="bg-secondary-light px-6 py-4 rounded-2" key={index}>
            <p className="mb-0 fs-sm">{text}</p>
          </div>
        ))}
      </div>
    </div>

  );
}

export function EmptyMessagesWrapper() {
  const EXAMPLES_KEYPOINTS = [
    'En palabras simples, ¿cómo le digo a un cliente qué es un APV?',
    '¿Cómo se qué régimen de APV le conviene a mi cliente?',
    '¿Cuál es el tope del beneficio del Estado para el régimen A?',
  ];

  const LIMITATIONS_KEYPOINTS = [
    'No realiza proyecciones de inversiones o ahorro',
    'No tiene información personal acerca del cliente',
    'No tiene información sobre los fondos en los cuáles se invierte',
  ];

  return (
    <div>
      <section className="mb-6">
        <EmptyMessagesHeader />
      </section>

      <section className="row justify-content-center">
        <div className="col-12 col-md-9">
          <section className="row gx-8">
            <div className="col-12 col-md-6">
              <KeypointsCards
                title="Ejemplos"
                iconSrc="/static/img/illustrations/problem-solving.svg"
                keypoints={EXAMPLES_KEYPOINTS}
              />
            </div>

            <div className="col-12 col-md-6">
              <KeypointsCards
                title="Limitaciones"
                iconSrc="/static/img/illustrations/office-work.svg"
                keypoints={LIMITATIONS_KEYPOINTS}
              />

            </div>
          </section>
        </div>
      </section>
    </div>
  );
}
