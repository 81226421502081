import clsx from 'clsx';
import React from 'react';
import { ProductRenderInfo, TextMode } from '../common/types';
import { ProductMessage } from '../common/components/ProductMessage';
import { BulletLists } from '../common/components/BulletLists';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const BiceInvestmentsDerivation: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: (savingsWithInsurance: boolean) => (savingsWithInsurance
    ? '/static/img/icons/person-with-notebook.svg' : '/static/img/icons/people-operation.svg'),
  iconSrc: '/static/img/icons/graph-up.svg',
  buttonText: 'Derivar',

  benefits: (textMode: TextMode) => [
    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Invierte</span>
      {' '}
      sin un monto mínimo
    </span>,

    <span>
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>Acompañamiento</span>
      {' '}
      <span>y</span>
      {' '}
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>asesoría</span>
      {' '}
      en todo momento
    </span>,
  ],

  complementaryInfo: (savingsWithInsurance: boolean) => (
    savingsWithInsurance
      ? (<DefaultComplementaryInfo />)
      : (
        <ProductMessage
          imgSrc="/static/img/illustrations/person-marketing.svg"
          title="Beneficios de contratar un seguro:"
          titleClassName="fs-5 text-primary mb-4"
          imgAlignment="top"
          renderContent={(
            <BulletLists
              lists={[
                {
                  title: 'Seguro con ahorro:',
                  items: [
                    'Capital indemnizatorio para proteger a quienes más quieres',
                    'Libre elección de beneficiarios',
                    'Pago inmediato del seguro a los beneficiarios',
                    'Beneficios tributarios en caso de rescates parciales y totales',
                    'Impuesto a la herencia se descuenta inmediatamente del monto a indemnizar',
                  ],
                },
                {
                  title: 'Seguro con APV:',
                  items: [
                    'Mejorar la pensión',
                    'Capital indemnizatorio para beneficiarios de pensión',
                    'Beneficios tributarios para seguro y ahorro',
                    'En caso de muerte, los beneficiarios de pensión pueden retirar los ahorro sin tener que traspasar a la AFP',
                  ],
                },
              ]}
            />
      )}
        />
      )
  ),
};
