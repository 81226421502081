import React from 'react';

export function EmptyRecommendationState() {
  return (
    <div className="card-form h-100 rounded justify-content-center">
      <div className="d-flex flex-column gap-4 align-items-center">
        <img width={180} height={240} src="/static/img/icons/person-with-notebook-no-empty-space.svg" alt="" />

        <div className="row">
          <span className="col-md-8 mx-auto fs-lg text-primary fw-bold text-center">Aquí verás cómo mejora tu futuro</span>
        </div>
      </div>
    </div>
  );
}
