import { z } from 'zod';
import { useEffect } from 'react';
import { formatPercentage } from '../../../utils/formatters';
import { BaseSimulationParams } from '../../../interfaces/simulations';
import { simulationManager } from '../../../utils/simulationsManager';
import {
  MAX_AGE_BY_GENDER, validateAgeMayority, validateMaxAgeByGender,
  validateRutByRegexp,
  validateSavingsOption,
  validateTimeFrameForGoal,
} from '../../../utils/validations';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { GenderOptionType, SavingsOption } from '../../../utils/constants';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';

const MIN_AMOUNT = 1;
const [MIN_RENTABILITY_RATE, MAX_RENTABILITY_RATE] = [1, 5];
const MIN_TIME_FRAME = 1;

export const fullFlexibleValidationSchema = (minimumDeathCapital: number) => z.object({
  rut: z.string()
    .refine((rut) => validateRutByRegexp(rut), { message: VALIDATION_MESSAGES.INVALID_RUT }),
  date_of_birth: z
    .string()
    .nonempty('Este campo es requerido'),
  gender: z.string().nonempty('Este campo es requerido'),
  savings_option: z.enum([SavingsOption.savings_capacity, SavingsOption.goal_value]),
  monthly_savings: z.number(),
  goal_amount: z.number().min(MIN_AMOUNT, `El valor objetivo no debe ser menor a ${(MIN_AMOUNT)} UF`),
  time_frame: z.number(),
  rentability_rate: z.preprocess(
    (amount) => Number(amount as string),
    z.number()
      .min(MIN_RENTABILITY_RATE, `El valor no debe ser menor a ${formatPercentage(MIN_RENTABILITY_RATE)}`)
      .max(MAX_RENTABILITY_RATE, `El valor no debe ser mayor a ${formatPercentage(MAX_RENTABILITY_RATE)}`),
  ),
  compensation_plan: z.string().nonempty('Este campo es requerido'),
  death_insured_capital: z.preprocess(
    (amount) => Number(amount as string),
    z.number({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
      .min(minimumDeathCapital, `El capital mínimo es de ${minimumDeathCapital} UF`)
  ),
  extra_compensation_insured: z.object({
    itp: z.boolean(),
    severe_disease: z.boolean(),
  }),

  severe_diesase_assurance_rate: z.object({
    label: z.string(),
    value: z.number(),
  })
    .nullable(),
})
  .refine(({ date_of_birth }) => validateAgeMayority(date_of_birth), {
    message: 'El cliente debe ser mayor de edad',
    path: ['date_of_birth'],
  })
  .refine(
    ({ date_of_birth, gender }) => validateMaxAgeByGender(
      date_of_birth,
      gender as GenderOptionType
    ),
    ({ gender }) => ({
      // we need to compute MAX_CONTRACT_AGE - 1 because we want to show previous year to maximum
      // allowed year in error message
      message: `Supera la edad máxima de contratación de ${MAX_AGE_BY_GENDER[gender as GenderOptionType] - 1} años y 364 días`,
      path: ['date_of_birth'],
    }))
  .refine(({
    savings_option,
    monthly_savings,
  }) => validateSavingsOption(savings_option, SavingsOption.savings_capacity, monthly_savings),
  {
    message: 'Este campo es requerido',
    path: ['monthly_savings'],
  })
  .refine(({
    savings_option,
    time_frame,
  }) => validateTimeFrameForGoal(savings_option, time_frame, MIN_TIME_FRAME),
  {
    message: `El tiempo estimado de la meta no debe ser menor a ${MIN_TIME_FRAME} mes`,
    path: ['time_frame'],
  });

export type FullFlexibleSimulationValues = z.infer<ReturnType<typeof fullFlexibleValidationSchema>>;

interface FullFlexiblePayload extends BaseSimulationParams {
  agreed_premium: number
  compensation_plan: string
  date_of_birth: string
  death_insured_capital: number
  gender: string
  goal: number
  itp_insured_capital: number
  rentability: number
  rut: string
  savings_option: keyof typeof SavingsOption
  serious_illness_insured_capital: number
  timeframe: number
}

export function useFullFlexible() {
  const mapFormToPayload = (values: FullFlexibleSimulationValues) => {
    const {
      rut,
      compensation_plan,
      date_of_birth,
      death_insured_capital,
      extra_compensation_insured,
      gender,
      goal_amount,
      rentability_rate,
      severe_diesase_assurance_rate,
      time_frame,
      monthly_savings,
      savings_option,
    } = values;

    const itpCapital = extra_compensation_insured.itp ? death_insured_capital : 0;
    const seriousIllnessCapital = extra_compensation_insured.severe_disease
      ? Math.ceil(death_insured_capital * (severe_diesase_assurance_rate!.value / 100)) : 0;

    const payload: FullFlexiblePayload = {
      rut,
      date_of_birth,
      gender,
      compensation_plan,
      death_insured_capital,
      itp_insured_capital: itpCapital,
      serious_illness_insured_capital: seriousIllnessCapital,
      agreed_premium: monthly_savings,
      savings_option,
      goal: goal_amount,
      timeframe: time_frame,
      rentability: rentability_rate / 100,
      simulation_type: 'FULL_FLEXIBLE',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: FullFlexibleSimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<FullFlexiblePayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['full-flexible'],
          title: 'full-flexible-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'FULL_FLEXIBLE');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_form_fullflex',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<BaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'FULL_FLEXIBLE',
          is_empty: true,
        },
      },
      iframeAttributes: {
        classNames: ['full-flexible', 'empty'],
        title: 'full-flexible-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
