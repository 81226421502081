/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import clsx from 'clsx';
import React from 'react';
import {
  FieldValues,
  Path,
  useFormContext,
} from 'react-hook-form';

interface RadioBoxProps<T extends FieldValues> {
  id: string
  label: string
  value: string
  isSelected: boolean
  path: Path<T>
  selectedClassName?: string
}

function RadioBox<T extends FieldValues>({
  id, label, value, path, isSelected, selectedClassName,
}: RadioBoxProps<T>) {
  const { register } = useFormContext<T>();

  return (
    <label className="d-block w-100" htmlFor={id}>
      <input type="radio" id={id} className="d-none" value={value} {...register(path)} />

      <span className={clsx('px-4 py-3 h-100 d-flex align-items-center justify-content-center text-center rounded-2 fs-sm', {
        [selectedClassName as string]: isSelected && selectedClassName, // custom selected styles
        'bg-white shadow-switch fw-bold text-subtitle border border-0_5 border-black border-opacity-4': isSelected && !selectedClassName, // default selected styles
        'text-body': !isSelected,
      })}
      >
        {label}
      </span>
    </label>
  );
}

export type RadioBoxOption<T extends FieldValues> = Omit<RadioBoxProps<T>, 'path' | 'isSelected'>;

interface RadioBoxFieldProps<T extends FieldValues> {
  options: RadioBoxOption<T>[]
  path: Path<T>
  label?: string
  labelClassName?: string
  selectedClassName?: string
}

export function RadioBoxField<T extends FieldValues>({
  options,
  path,
  label,
  labelClassName,
  selectedClassName,
}: RadioBoxFieldProps<T>) {
  const { watch } = useFormContext<T>();
  const currentValue = watch(path);

  return (
    <div>
      <div>
        <span className={clsx(labelClassName ?? 'fs-sm fw-bold text-primary mb-2 d-inline-block')}>{label}</span>
      </div>

      <div className="bg-gray-semi-light p-1 rounded-2 d-flex gap-2">
        {options.map(({ id, value, label: optionLabel }) => (
          <RadioBox
            key={id}
            id={id}
            label={optionLabel}
            value={value}
            path={path}
            isSelected={currentValue === value}
            selectedClassName={selectedClassName}
          />
        ))}
      </div>
    </div>
  );
}
