import {
  FieldValues, Path, PathValue, UseFormSetValue,
} from 'react-hook-form';
import { useEffect } from 'react';
import { INITIAL_CONTRIBUTION_OPTIONS } from '../utils/constants';

interface UseResetInitialContributionOptions<T extends FieldValues> {
  contributionOption: keyof typeof INITIAL_CONTRIBUTION_OPTIONS
  setValue: UseFormSetValue<T>
  contributionAmountPath: Path<T>
  transferCompanyPath: Path<T>
}

/**
 * Watch if user has been selected the initial contribution and resets to 0 the related field
 * based on selection
 */
export function useResetInitialContribution<T extends FieldValues>(
  options: UseResetInitialContributionOptions<T>
) {
  const {
    contributionOption, contributionAmountPath, transferCompanyPath, setValue,
  } = options;

  useEffect(() => {
    // Reset `transfer company` and `initial contribution amount`
    if (contributionOption === 'NONE') {
      setValue(contributionAmountPath, 0 as PathValue<T, Path<T>>);
      setValue(transferCompanyPath, null as PathValue<T, Path<T>>);
      return;
    }

    // Reset `transfer company`
    if (contributionOption === 'UNIQUE_CONTRIBUTION') {
      setValue(transferCompanyPath, null as PathValue<T, Path<T>>);
    }
  }, [contributionOption]);
}
