import { z } from 'zod';
import { VALIDATION_MESSAGES } from './validationMessages';

export const requiredString = () => z
  .string({ required_error: VALIDATION_MESSAGES.REQUIRED_FIELD })
  .nonempty(VALIDATION_MESSAGES.REQUIRED_FIELD);

export const selectOption = () => z.object({
  label: z.string(),
  value: z.string(),
}, {
  required_error: VALIDATION_MESSAGES.REQUIRED_FIELD,
});
