/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutualFundsMonthlyContribution, MutualFundsMonthlyContributionSimulationValues, mutualFundsMonthlyContributionValidationSchema } from './useMutualFundsMonthlyContribution';
import { InputField, NumericFormatField, SelectField } from '../../Forms/Fields';
import { MutualFundsGoals, RiskProfiles } from '../../../utils/constants';

export function MutualFundsMonthlyContribution() {
  const [repeatSimulation, setRepeatSimulation] = useState(false);

  const methods = useForm<MutualFundsMonthlyContributionSimulationValues>({
    defaultValues: {
      other_goal: '',
      risk_profile: RiskProfiles.asSelectOptionsWithPercentage('ffmm').defaultChoice,
    },
    resolver: zodResolver(mutualFundsMonthlyContributionValidationSchema),
  });

  const goal_type = methods.watch('goal_type');
  const time_frame = methods.watch('time_frame');

  const { handleSubmitSimulation } = useMutualFundsMonthlyContribution();

  const handleTriggerErrors = () => methods.formState.errors;
  const onSubmit = async (values: MutualFundsMonthlyContributionSimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
  };

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-4" onSubmit={methods.handleSubmit(onSubmit)}>
        <SelectField<MutualFundsMonthlyContributionSimulationValues>
          id="goal_type"
          path="goal_type"
          label="¿Cuál es tu meta?"
          placeholder="Seleccionar"
          options={MutualFundsGoals.asSelectOptions()}
          onChange={(option) => option?.value !== 'OTHER' && methods.setValue('other_goal', '')}
        />

        {goal_type?.value === 'OTHER' && (
          <InputField<MutualFundsMonthlyContributionSimulationValues>
            id="other_goal"
            label="Escribe cuál es tu meta"
            path="other_goal"
          />
        )}

        <NumericFormatField<MutualFundsMonthlyContributionSimulationValues>
          id="goal"
          label="¿Cuál es el valor de tu meta?"
          path="goal"
          placeholder="$"
          prefix="$ "
        />

        <NumericFormatField<MutualFundsMonthlyContributionSimulationValues>
          id="time_frame"
          label="¿En cuánto tiempo quieres lograrla?"
          path="time_frame"
          placeholder="Años"
          suffix={time_frame === 1 ? ' año' : ' años'}
        />

        <SelectField<MutualFundsMonthlyContributionSimulationValues>
          id="risk_profile"
          path="risk_profile"
          label="¿Cuál es tu perfil de riesgo?"
          options={
            RiskProfiles
              .asSelectOptionsWithPercentage<MutualFundsMonthlyContributionSimulationValues>('ffmm')
              .choices
            }
        />

        <button onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>
    </FormProvider>
  );
}
