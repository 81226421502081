import { Modal } from 'bootstrap';
import { RecentNews } from '../../types/notificationsTypes';
import { api } from '../../utils/api';
import { NOTIFICATIONS_ENDPOINTS } from '../../utils/endpoints';

const RECENT_NEWS_MODAL_ID = 'newsModal';

export async function showNewsModal() {
  const recentNewsModalElement = document.getElementById(RECENT_NEWS_MODAL_ID);
  const recentNewsModalUserId = recentNewsModalElement?.dataset.userId;

  if (!recentNewsModalUserId) return;

  try {
    const recentNewsUrl = NOTIFICATIONS_ENDPOINTS.RECENT_NEWS(recentNewsModalUserId);
    const { data } = await api.get<RecentNews>(recentNewsUrl);

    if (data.recent_news) {
      // Set news url
      const recentNewsModalLink = recentNewsModalElement.querySelector('a[href="#"]');
      recentNewsModalLink?.setAttribute('href', data.news_url);

      // Show modal
      Modal.getInstance(recentNewsModalElement)?.show();
    }
  } catch (error) {
    console.error('Error getting recent news:', error);
  }
}
