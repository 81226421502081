import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ProvisionalSavingsForm } from './ProvisionalSavings';

const queryClient = new QueryClient();

export function ProvisionalSavingsWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <ProvisionalSavingsForm />
    </QueryClientProvider>
  );
}
