const BREADCRUMBS_BACK_ITEM_CLASS = 'breadcrumb-item-back';

const BREADCRUMBS_SELECTOR = '.breadcrumb';
const BREADCRUMBS_BACK_ITEM_SELECTOR = `.${BREADCRUMBS_BACK_ITEM_CLASS}`;

/**
 * A function for setting the html that the last item with a link needs
 * to be properly styled.
 */
function setupBreadcrumbsBackItemElement(breadcrumbsElement: HTMLOListElement): void {
  const itemWithLinkElements = [...breadcrumbsElement.querySelectorAll('li')].filter((itemElement) => (
    itemElement.querySelector('a:not(.active)')
  ));
  const lastItemWithLinkElement = itemWithLinkElements.pop();

  if (!lastItemWithLinkElement) return;

  const lastLinkElement = lastItemWithLinkElement.querySelector('a') as HTMLAnchorElement;
  const lastLinkText = lastLinkElement.textContent || '';

  lastItemWithLinkElement.classList.add('breadcrumb-item', BREADCRUMBS_BACK_ITEM_CLASS);
  lastLinkElement.innerHTML = `
    <span class="breadcrumb-text">${lastLinkText}</span>
    <span class="breadcrumb-text-back">Volver</span>
  `;
}

// Initialize breadcrumbs
window.addEventListener('DOMContentLoaded', () => {
  const breadcrumbsElement = document.querySelector<HTMLOListElement>(BREADCRUMBS_SELECTOR);
  const breadcrumbsBackItemElement = breadcrumbsElement?.querySelector(
    BREADCRUMBS_BACK_ITEM_SELECTOR
  );

  if (breadcrumbsElement && !breadcrumbsBackItemElement) {
    setupBreadcrumbsBackItemElement(breadcrumbsElement);
  }
});
