import React from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { DocumentsUploader } from './DocumentsUploader';

const queryClient = new QueryClient();

export function DocumentsUploaderWrapper() {
  return (
    <QueryClientProvider client={queryClient}>
      <DocumentsUploader />
    </QueryClientProvider>
  );
}
