import React from 'react';

export function DownloadErrorAlert() {
  return (
    <div className="alert alert-danger mt-4" role="alert">
      <div>
        <header>
          <h4 className="fw-bold text-danger fs-6 mb-3">Oops! Algo ha ido mal...</h4>
        </header>

        <p className="mb-0 text-danger">
          Ha ocurrido un error durante la generación de los documentos seleccionados
          y el proceso de descarga. Inténtelo más tarde.
        </p>
      </div>
    </div>
  );
}
