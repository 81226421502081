import { z } from 'zod';
import { useEffect } from 'react';
import { BaseSimulationParams } from '../../../interfaces/simulations';
import { formatPercentage } from '../../../utils/formatters';
import { simulationManager } from '../../../utils/simulationsManager';
import {
  validateAgeMayority, validateAgeMinority, validateMaxAge, validateNonFutureDate,
  validateRutByRegexp,
} from '../../../utils/validations';
import { injectSimulationIdToInfograph } from '../../../behaviors/infograhps/handleInfographDownload';
import { EVENTS, tag } from '../../../behaviors/cms/gtm-inputs';
import { VALIDATION_MESSAGES } from '../../../utils/validationMessages';

const MAX_CONTRACT_AGE = 65;
const [MIN_RENTABILITY_RATE, MAX_RENTABILITY_RATE] = [1, 5];

export const insuredUniversityValidationSchema = (minimumDeathcapital: number) => z.object({
  rut: z.string()
    .refine((rut) => validateRutByRegexp(rut), { message: VALIDATION_MESSAGES.INVALID_RUT }),
  date_of_birth: z
    .string()
    .nonempty('Este campo es requerido')
    .refine((date_of_birth) => validateAgeMayority(date_of_birth), { message: 'El cliente debe ser mayor de edad' })
    .refine((date_of_birth) => validateMaxAge({
      date: date_of_birth,
      maxAge: MAX_CONTRACT_AGE,
      mode: 'EXCLUSIVE',
    }), {
      // we need to compute MAX_CONTRACT_AGE - 1 because we want to show previous year to maximum
      // allowed year in error message
      message: `Supera la edad máxima de contratación de ${MAX_CONTRACT_AGE - 1} años y 364 días`,
    }),

  gender: z.string().nonempty('Este campo es requerido'),

  beneficiary_date_of_birth: z
    .string()
    .nonempty('Este campo es requerido')
    .refine((beneficiary_date_of_birth) => validateNonFutureDate(beneficiary_date_of_birth), {
      message: 'Debes seleccionar una fecha válida',
    })
    .refine((beneficiary_date_of_birth) => validateAgeMinority(beneficiary_date_of_birth), {
      message: 'El beneficiario debe ser menor de edad',
    }),

  rentability_rate: z.preprocess(
    (amount) => Number(amount as string),
    z.number()
      .min(MIN_RENTABILITY_RATE, `El valor no debe ser menor a ${formatPercentage(MIN_RENTABILITY_RATE)}`)
      .max(MAX_RENTABILITY_RATE, `El valor no debe ser mayor a ${formatPercentage(MAX_RENTABILITY_RATE)}`),
  ),
  death_insured_capital: z.preprocess(
    (amount) => Number(amount as string),
    z.number()
      .min(minimumDeathcapital, `El capital mínimo es de UF ${minimumDeathcapital}`)
  ),
  extra_compensation_insured: z.object({
    itp: z.boolean(),
    severe_disease: z.boolean(),
  }),

  severe_diesase_assurance_rate: z.object({
    label: z.string(),
    value: z.number(),
  })
    .nullable(),
});

export type InsuredUniversitySimulationValues = z
  .infer<ReturnType<typeof insuredUniversityValidationSchema>>;

interface InsuredUniversityPayload extends BaseSimulationParams {
  rut: string
  date_of_birth: string
  gender: string
  beneficiary_date_of_birth: string
  rentability: number
  death_insured_capital: number
  itp_insured_capital: number
  serious_illness_insured_capital: number
}

export function useInsuredUniversity() {
  const mapFormToPayload = (values: InsuredUniversitySimulationValues) => {
    const {
      rut,
      beneficiary_date_of_birth,
      date_of_birth,
      death_insured_capital,
      extra_compensation_insured,
      gender,
      rentability_rate,
      severe_diesase_assurance_rate,
    } = values;

    const itpCapital = extra_compensation_insured.itp ? death_insured_capital : 0;
    const seriousIllnessCapital = extra_compensation_insured.severe_disease
      ? Math.ceil(death_insured_capital * (severe_diesase_assurance_rate!.value / 100)) : 0;
    const rentability = rentability_rate / 100;

    const payload: InsuredUniversityPayload = {
      rut,
      beneficiary_date_of_birth,
      date_of_birth,
      death_insured_capital,
      gender,
      rentability,
      itp_insured_capital: itpCapital,
      serious_illness_insured_capital: seriousIllnessCapital,
      simulation_type: 'INSURED_UNIVERSITY',
      wording: 'CLIENT',
    };

    return payload;
  };

  const handleSubmitSimulation = async (values: InsuredUniversitySimulationValues) => {
    try {
      const payload = mapFormToPayload(values);

      simulationManager.render<InsuredUniversityPayload>({
        srcOptions: {
          parameters: payload,
        },
        iframeAttributes: {
          classNames: ['insured-university'],
          title: 'insured-university-simulation',
        },
        parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
      });

      await injectSimulationIdToInfograph(payload, 'INSURED_UNIVERSITY');

      tag({
        event: EVENTS.SUBMIT,
        variables: {
          id: 'create_lead_form_uni',
        },
      });
    } catch (err) {
      console.error(err);
    }
  };

  const renderEmptySimulation = () => {
    simulationManager.render<BaseSimulationParams>({
      srcOptions: {
        parameters: {
          wording: 'CLIENT',
          simulation_type: 'INSURED_UNIVERSITY',
          is_empty: true,
        },
      },
      iframeAttributes: {
        classNames: ['insured-university', 'empty'],
        title: 'insured-university-simulation',
      },
      parentElement: document.querySelector<HTMLDivElement>('#simulation-container')!,
    });
  };

  useEffect(() => {
    renderEmptySimulation();
  }, []);

  return { handleSubmitSimulation };
}
