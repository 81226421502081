/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/require-default-props */
import clsx from 'clsx';
import React from 'react';
import { ProductCard } from './ProductCard';

export interface ProductSuggestionCardProps {
  imgSrc: string
  title: string
  benefits: Array<string | JSX.Element>
  url: string
  buttonText: string
  applyListPadding?: boolean
  hideButton?: boolean
}

function SmallProductSuggestionCard({
  benefits, imgSrc, url, title, applyListPadding, hideButton, buttonText,
}: ProductSuggestionCardProps) {
  return (
    <article className="small-product-suggestion-card">
      <header className="d-flex flex-column align-items-center gap-2">
        <img src={imgSrc} alt="" />
        <h4 className="mb-0 fs-lg fw-bold text-primary text-center">{title}</h4>
      </header>

      <div className={clsx(applyListPadding && 'px-4')}>
        <ul className="list-icon list-icon-check fs-sm">
          {benefits.map((benefit, index) => <li key={index} className="list-icon-item">{benefit}</li>)}
        </ul>
      </div>

      {!hideButton && <a target="_blank" href={url} className="mt-auto btn btn-outline-primary w-100" rel="noreferrer">{buttonText}</a>}
    </article>
  );
}

interface SmallProductSuggestionsCardsListProps {
  suggestions: ProductSuggestionCardProps[]
}

function SmallProductSuggestionsCardsList({ suggestions }: SmallProductSuggestionsCardsListProps) {
  const BOOTSTRAP_COLUMNS = 12;
  // Compute the amount of columns dedicated to each suggestion card based on the
  // `bootstrap` grid system.
  const columnsPerSuggestion = Math.floor(BOOTSTRAP_COLUMNS / suggestions.length);

  // We want to apply padding to cards contents list based on an amount of suggestion.
  // If  the amount suggestions exceed `LIST_PADDING_LIMIT` we dont want apply padding because
  // cards content will be desaligned.
  const LIST_PADDING_LIMIT = 2;
  const applyListPadding = suggestions.length <= LIST_PADDING_LIMIT;

  return (
    <div className="row">
      {
        suggestions.map((suggestion) => (
          <div key={suggestion.title} className={clsx(`col-${columnsPerSuggestion}`)}>
            <SmallProductSuggestionCard {...suggestion} applyListPadding={applyListPadding} />
          </div>
        ))
      }

    </div>
  );
}

interface ProductSuggestionsProps {
  suggestions: ProductSuggestionCardProps[]
}

// Suggestions could be displayed in two forms; as a big card with an unique product suggestion
// Or as a row with multiple suggestion cards
export function ProductSuggestions({ suggestions }: ProductSuggestionsProps) {
  const isUniqueSuggestion = suggestions.length === 1;

  return (
    <section>
      <header>
        <h3 className="mb-5 text-primary fw-bold fs-lg">También te podría interesar:</h3>
      </header>

      {
        isUniqueSuggestion
          ? <ProductCard {...suggestions[0]} />
          : <SmallProductSuggestionsCardsList suggestions={suggestions} />
      }
    </section>
  );
}
