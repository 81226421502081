import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import React from 'react';
import { DownloadSimulationDocsForm, DownloadSimulationDocsFormProps } from './DownloadSimulationDocsForm';
import { JsonPropsType } from '../../component-loader';

const queryClient = new QueryClient();

export function DownloadSimulationDocsFormWrapper({ jsonObject }: JsonPropsType) {
  const { simulation } = jsonObject as DownloadSimulationDocsFormProps;
  return (
    <QueryClientProvider client={queryClient}>
      <DownloadSimulationDocsForm simulation={simulation} />
    </QueryClientProvider>
  );
}
