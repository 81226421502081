import { z } from 'zod';
import { saveAs } from 'file-saver';
import { useMutation } from '@tanstack/react-query';
import { requiredString } from '../../utils/commonSchemaValidators';
import {
  DocumentType,
  InfographGenerationParams,
  SimulationClass,
  generateDocumentFilename,
  getDocumentBlob,
  getInfograph,
} from '../../utils/infographs';

export const downloadSimulationDocsSchema = z.object({
  first_name: requiredString(),
  last_name: requiredString(),
  download_as_uf: z.boolean(),
  documents: z.object({
    infograph: z.boolean(),
    projection_table: z.boolean(),
  }),
});

export type DownloadSimulationDocsValues = z.infer<typeof downloadSimulationDocsSchema>;

interface HandleDownloadDocsParams {
  values: DownloadSimulationDocsValues,
  simulation: SimulationClass,
  simulationId: number
}

export function useDownloadSimulationDocs() {
  // Since infograph and policy projection will be two different requests (one per document)
  // Two different payloads are generated
  const mapFormToPayloads = (params: HandleDownloadDocsParams) => {
    const { simulation, simulationId, values } = params;

    const selectedDocuments = Object.entries(values.documents)
      .filter(([, v]) => Boolean(v))
      .map(([key]) => key);

    const payloads = selectedDocuments.map((key) => {
      const keyToDocumentMap: Record<string, DocumentType> = {
        infograph: 'infograph',
        projection_table: 'policy-projection',
      };

      const payload: InfographGenerationParams = {
        document_type: keyToDocumentMap[key],
        email: 'test@gmail.com', // hardcoded email because form does not take it
        first_name: values.first_name,
        last_name: values.last_name,
        currency: values.download_as_uf ? 'clf' : 'clp',
        simulation_class: simulation,
        simulation_id: simulationId.toString(),
      };

      return payload;
    });

    return payloads;
  };

  const executeDocsDownload = async (params: HandleDownloadDocsParams) => {
    const payloads = mapFormToPayloads(params);

    const documents = await Promise.all(payloads
      .map(async (payload) => {
        const response = await getInfograph(payload.simulation_id, payload);
        const blob = await getDocumentBlob(response.url);

        return {
          blob,
          filename: generateDocumentFilename(payload),
        };
      }));

    documents.forEach((doc) => saveAs(doc.blob, doc.filename));
  };

  const downloadDocsMutation = useMutation({
    mutationFn: async (params: HandleDownloadDocsParams) => executeDocsDownload(params),
  });

  return { downloadDocsMutation };
}
