/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FilterMarketingsCampaingsValues, useFilterMarketingsCampaigns } from './useFilterMarketingsCampaings';
import { formatFromPayloadToDateTime, formatFromUrlToPayloadDate } from '../../../utils/dates';
import { DatepickerField } from '../../Forms/Fields';

export function FilterMarketingCampaignsForm() {
  const queryParams = new URLSearchParams(window.location.search);

  const defaultStartDate = queryParams.get('published_date__gte');
  const defaultEndDate = queryParams.get('published_date__lte');

  const methods = useForm<FilterMarketingsCampaingsValues>({
    defaultValues: {
      published_date__gte: defaultStartDate ? formatFromUrlToPayloadDate(defaultStartDate) : '',
      published_date__lte: defaultEndDate ? formatFromUrlToPayloadDate(defaultEndDate) : '',
    },
  });
  const formRef = useRef<HTMLFormElement>(null);

  const { handleSubmitFilter } = useFilterMarketingsCampaigns(formRef);

  const {
    published_date__gte: startDate,
    published_date__lte: endDate,
  } = methods.watch();

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSubmitFilter)} ref={formRef}>
        <label className="fw-bold mb-3">
          Selecciona un rango de fecha para ver las campañas:
        </label>

        <div className="row g-4">
          <div className="col-12 col-lg-10 col-xl-8 d-flex flex-column gap-4 flex-md-row">
            <DatepickerField
              id="published_date__gte"
              path="published_date__gte"
              maxDate={endDate.length ? formatFromPayloadToDateTime(endDate) : undefined}
            />

            <div className="d-none d-md-flex justify-content-center align-items-center">
              <span className="d-block fw-bold text-tertiary mx-auto">-</span>
            </div>

            <DatepickerField
              id="published_date__lte"
              path="published_date__lte"
              minDate={startDate.length ? formatFromPayloadToDateTime(startDate) : undefined}
            />
            <button type="submit" className="h-100 btn btn-primary px-8">Buscar</button>
          </div>
        </div>
      </form>
    </FormProvider>
  );
}
