import { SimulationType } from '../interfaces/simulations';
import { simulatorsApi } from './api';
import { GenderOptions } from './constants';

const MINIMUM_CAPITAL_ENDPOINT = '/cloud/minimum-capital';

export interface MinimumCapitalPayload {
  gender: keyof typeof GenderOptions
  date_of_birth: string
  product: Extract<SimulationType, 'APV_LIFE' | 'FULL_FLEXIBLE' | 'INSURED_UNIVERSITY'>
}

export interface MinimumCapital {
  accidental_death: boolean,
  itp: boolean,
  minimun_death_capital: number,
  serious_illness_percentage: number
}

export async function getMinimumCapital(payload: MinimumCapitalPayload) {
  const response = await simulatorsApi.get(MINIMUM_CAPITAL_ENDPOINT, {
    params: {
      ...payload,
    },
  });

  return response.data as MinimumCapital[];
}
