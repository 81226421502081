/**
 * Public Env handler for access to non-sensitive environment variables injected by backend through
 * base template
 */

interface PublicEnv {
  SIMULATORS_URL: string,
  SIMULATORS_API_URL: string,
  BICE_INVESMENTS_DERIVATION_LINK: string
  BICE_LIFE_DERIVATION_LINK: string
}

type PublicEnvKey = keyof PublicEnv;

const PUBLIC_ENV_ELEMENT_ID = '#public_env';
const publicEnv = document.querySelector(PUBLIC_ENV_ELEMENT_ID);

export const getEnv = (key: PublicEnvKey) => {
  if (!publicEnv) return null;

  const parsedData = JSON.parse(publicEnv.textContent || '') as PublicEnv;

  return parsedData[key];
};
