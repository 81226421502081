import React from 'react';
import { ProductRenderInfo } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const HealthProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/hero.svg',
  iconSrc: '/static/img/icons/graph-up.svg',
  hideButton: true,
  description: 'Seguro que te permite proteger a tu familia financieramente frente a los gastos que una enfermedad o accidente pudiera ocasionarte',

  benefits: () => [
    <span>Indemnizatorio o reembolso de gastos de salud</span>,
    <span>Elige libremente dónde atenderte y con quién</span>,
    <span>Que tu preocupación sea mejorarte y nosotros nos preocupamos de financiarlo</span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
