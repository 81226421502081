import clsx from 'clsx';
import React from 'react';
import { ProductRenderInfo, TextMode } from '../common/types';
import { DefaultComplementaryInfo } from '../common/components/DefaultComplementaryInfo';

export const RRVVProductRenderInfo: ProductRenderInfo = {
  // If product is created in admin without any image, this will be used as default
  defaultImgSrc: () => '/static/img/icons/people-operation.svg',
  iconSrc: '/static/img/icons/graph-up.svg',
  description: 'Pensión para toda la vida, que te garantiza al momento de jubilar un monto mensual fijo en UF',
  buttonText: 'Ver más',

  benefits: (textMode: TextMode) => [
    <span>
      Pensión fija en UF y
      {' '}
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>de por vida</span>
    </span>,

    <span>
      No
      {' '}
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>varía en el tiempo</span>
      {' '}
      ni se recalcula por inflación
    </span>,

    <span>
      Mantiene acceso a
      {' '}
      <span className={clsx(textMode === 'rich' && 'fw-bold')}>sistemas de salud y beneficios</span>
      {' '}
      de asignación familiar
    </span>,
  ],

  complementaryInfo: () => <DefaultComplementaryInfo />,
};
