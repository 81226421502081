/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/require-default-props */
import clsx from 'clsx';
import React, { ComponentProps } from 'react';
import {
  Controller, FieldValues, Noop, Path, PathValue, useFormContext,
} from 'react-hook-form';
import { NumberFormatValues, NumericFormat } from 'react-number-format';

import { BaseError, ErrorAdornment } from '../Errors';
import { HelpPopover } from '../../common/Popover';
import { HelpMessage } from '../Messages';

function getErrorClassName() {
  return 'border border-2 border-danger';
}
interface NumericFormatFieldProps<T extends FieldValues> {
  path: Path<T>
  id: string,
  helpMessage?: string
  label?: string
  onBlur?: (value: PathValue<T, Path<T>>) => void
  prefix?: string
  suffix?: string
  placeholder?: string
  decimalSeparator?: string
  thousandSeparator?: string
  min?: number
  max?: number
  hideErrorMessage?: boolean
  helpPopover?: ComponentProps<typeof HelpPopover>
  renderHelpPopover?: () => JSX.Element
}

export function NumericFormatField<T extends FieldValues>({
  label,
  path,
  id,
  prefix,
  suffix,
  placeholder,
  min,
  max,
  hideErrorMessage,
  helpPopover,
  renderHelpPopover,
  helpMessage,
  onBlur,
  decimalSeparator = ',',
  thousandSeparator = '.',
}: NumericFormatFieldProps<T>) {
  const {
    control, getFieldState, setValue, trigger, formState,
  } = useFormContext<T>();
  const { error } = getFieldState(path);

  const handleValueChange = (numberFormat: NumberFormatValues) => {
    setValue(path, Number(numberFormat.value) as PathValue<T, Path<T>>);
  };

  const handleOnBlur = async (value: PathValue<T, Path<T>>, internalOnBlur: Noop) => {
    onBlur?.(value);
    internalOnBlur();

    if (formState.isSubmitted) {
      await trigger(path);
    }
  };

  return (
    <div>
      {label && (
        <label htmlFor={id} className="fs-sm fw-bold text-primary w-100 mb-2">
          <span className="me-2">{label}</span>
          {helpPopover && <HelpPopover {...helpPopover} />}
          {renderHelpPopover?.()}
        </label>
      )}

      <div className="position-relative">
        <Controller
          control={control}
          name={path}
          render={({ field: { name, value, onBlur: internalOnBlur } }) => (
            <NumericFormat
              className={clsx('form-control', error && getErrorClassName())}
              decimalSeparator={decimalSeparator}
              id={id}
              name={name}
              onBlur={() => handleOnBlur(value, internalOnBlur)}
              onValueChange={handleValueChange}
              placeholder={placeholder}
              prefix={prefix}
              suffix={suffix}
              thousandSeparator={thousandSeparator}
              thousandsGroupStyle="thousand"
              type="text"
              value={value || null}
              min={min}
              max={max}
            />
          )}
        />

        {error && <ErrorAdornment />}
      </div>

      {!hideErrorMessage && <BaseError message={error?.message} />}
      {helpMessage && <HelpMessage text={helpMessage} />}
    </div>
  );
}
