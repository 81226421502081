import { getUserContext } from '../../utils/user';

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

interface TagParams {
  event: string
  variables?: Record<string, unknown>
}

export function tag({ event, ...variables }: TagParams) {
  const company = getUserContext('COMPANY');
  const isExternal = getUserContext('IS_EXTERNAL');

  window.dataLayer?.push({
    event,
    user: {
      company,
      isExternal,
    },
    ...variables,
  });
}

export const EVENTS = {
  COLLECT_DATA: 'COLLECT_DATA',
  SUBMIT: 'SUBMIT',
  BUTTON: 'BUTTON',
  NAVIGATION: 'NAVIGATION',
};

export const GTM_SELECTORS = {
  INPUTS: '[data-input-environment="cms"]',
  SUBMIT_FOR_COLLECT_DATA: '[data-tags-action="submit-tags"]',
  ELEMENT: '[data-gtm-track="on"]',
};

function canSubmit(inputs: NodeListOf<HTMLInputElement>) {
  return [...inputs].every((input) => Boolean(input.value));
}

function toggleSubmitButton(btn: HTMLButtonElement, status: 'enabled' | 'disabled') {
  const updateStatusMap: Record<typeof status, () => void> = {
    enabled: () => btn.classList.remove('pe-none', 'disabled'),
    disabled: () => btn.classList.add('pe-none', 'disabled'),
  };

  updateStatusMap[status]();
}

function handleSubmitStatusListener(btn: HTMLButtonElement, inputs: NodeListOf<HTMLInputElement>) {
  // Initializes button in disabled state
  toggleSubmitButton(btn, 'disabled');

  // Listen each input value and only enables submit if all inputs has been fullfilled
  inputs.forEach((input) => {
    input.addEventListener('blur', () => {
      if (canSubmit(inputs)) {
        toggleSubmitButton(btn, 'enabled');
        return;
      }

      toggleSubmitButton(btn, 'disabled');
    });
  });
}

function handleSubmitListener(btn: HTMLButtonElement, inputs: NodeListOf<HTMLInputElement>) {
  btn.addEventListener('click', () => {
    const page = btn.getAttribute('data-tags-page');
    const tagsValues: Record<string, unknown> = {
      page,
    };

    // Iterate over all inputs and collect the data into `tagsValues` record
    inputs.forEach((input) => { tagsValues[input.name] = input.value; });

    // Send collected data to GTM
    tag({ event: EVENTS.COLLECT_DATA, variables: { ...tagsValues } });
  });
}

type HTMLNavigationElement = HTMLButtonElement | HTMLAnchorElement;

function loadCMSInputHandlers() {
  const inputs = document.querySelectorAll<HTMLInputElement>(GTM_SELECTORS.INPUTS);
  const btn = document.querySelector<HTMLButtonElement>(GTM_SELECTORS.SUBMIT_FOR_COLLECT_DATA);

  if (!inputs || !btn) { return; }

  handleSubmitStatusListener(btn, inputs);
  handleSubmitListener(btn, inputs);
}

function handleNavigationListener(links: NodeListOf<HTMLNavigationElement>) {
  links.forEach((link) => link.addEventListener('click', () => {
    const gtmTrackId = link.getAttribute('data-gtm-id');

    tag({
      event: EVENTS.NAVIGATION,
      variables: {
        id: gtmTrackId,
      },
    });
  }));
}

function loadCMSNavigationHandlers() {
  const links = document.querySelectorAll<HTMLNavigationElement>(GTM_SELECTORS.ELEMENT);

  if (!links) { return; }

  handleNavigationListener(links);
}

window.addEventListener('DOMContentLoaded', () => {
  loadCMSInputHandlers();
  loadCMSNavigationHandlers();
});

export {};
