import React, { useState } from 'react';
import { JsonPropsType } from '../../component-loader';

interface SuccessViewProps {
  url: string
}

export function SuccessView(props: JsonPropsType) {
  const { url } = props.jsonObject as SuccessViewProps;
  const [hasCopied, setHasCopied] = useState(false);

  const handleCopyLink = async (): Promise<void> => {
    try {
      await navigator.clipboard.writeText(url);
      setHasCopied(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <section className="row justify-content-center gx-0">
      <div className="col-md-10 col-lg-8 col-xl-6">
        <div className="card shadow-drop">
          <div className="card-body p-6 text-center">
            {/* TODO: src should be determined by storage url given by backend */}
            <img
              className="mb-6"
              src="/static/img/illustrations/rocket-achievement.svg"
              width={160}
              alt=""
            />

            <h1 className="fs-xl text-primary mb-4">
              ¡Excelente! Carga de archivos exitosa
            </h1>

            <p className="text-subtitle fs-lg mb-6">
              <span className="fw-bold">Envíale el enlace al cliente</span>
              {' '}
              para continuar con la firma de documentos.
            </p>

            <button
              className="btn btn-link gap-1 mb-6"
              onClick={() => { handleCopyLink().catch(console.error); }}
              type="button"
            >
              {hasCopied ? (
                <>
                  <img src="/static/img/icons/green-check.svg" alt="" width={24} height={24} />
                  <span>
                    Copiado
                  </span>
                </>
              ) : (
                <>
                  <img src="/static/img/icons/copy.svg" alt="" width={20} height={20} />
                  <span>
                    Haz click para copiar el link
                  </span>
                </>
              )}
            </button>

            <div className="row justify-content-center">
              <div className="col-md-6">
                <a className="btn btn-primary w-100" href="/cms">
                  Volver al home
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
