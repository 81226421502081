import { DateTime } from 'luxon';

export const DATE_FORMAT = 'yyyy-MM-dd';

export function getYearsDiffFromNow(date: string, format = DATE_FORMAT) {
  // Date diff throws negative value for past dates and positive for new ones
  const rawYears = Math.abs(
    DateTime
      .fromFormat(date, format)
      .diffNow('years')
      .years
  );
  const roundedYears = Math.floor(rawYears);

  return roundedYears;
}

export function getRawYearsDiffFromNow(date: string, format = DATE_FORMAT) {
  // Raw years diff returns the years difference including the sign
  // Positive difference indicates given date is future from now
  // Negative difference indicates given date is past from now
  return (
    DateTime
      .fromFormat(date, format)
      .diffNow('years')
      .years
  );
}

/**
 * Transforms strings dates from URL date format (dd/MM/yyyy) to payload date format (yyyy-MM-dd)
 */
export function formatFromUrlToPayloadDate(urlDate: string) {
  return DateTime.fromFormat(urlDate, 'dd/MM/yyyy').toFormat('yyyy-MM-dd');
}

/**
 * Transforms payload date format (yyyy-MM-dd) to JS Date object
 */
export function formatFromPayloadToJSDate(payloadDate: string) {
  return DateTime.fromFormat(payloadDate, 'yyyy-MM-dd').toJSDate();
}

/**
 * Transforms payload date format (yyyy-MM-dd) to luxon DateTime
 */
export function formatFromPayloadToDateTime(payloadDate: string) {
  return DateTime.fromFormat(payloadDate, 'yyyy-MM-dd');
}

/**
 * Transform JS Date object into payload date format (yyyy-MM-dd)
 */
export function formatFromJSDateToPayload(date: Date) {
  return DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
}
