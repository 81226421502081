import { z } from 'zod';
import { useMutation } from '@tanstack/react-query';
import { UseFormSetError } from 'react-hook-form';
import { AxiosError } from 'axios';
import { VALIDATION_MESSAGES } from '../../utils/validationMessages';
import { requiredString, selectOption } from '../../utils/commonSchemaValidators';
import { validateRutByRegexp } from '../../utils/validations';
import { COMPANIES, CompanyProduct } from '../../utils/constants';
import { DerivationPossibleErrors, DeriveCustomerPayload, createCustomerDerivation } from './deriveCustomerService';
import { EVENTS, tag } from '../../behaviors/cms/gtm-inputs';

export const deriveCustomerValidationSchema = z.object({
  firstName: requiredString(),
  company: z.enum([COMPANIES.INVESTMENTS, COMPANIES.LIFE]),
  lastName: requiredString(),
  rut: requiredString()
    .refine((rut) => validateRutByRegexp(rut), {
      message: VALIDATION_MESSAGES.INVALID_RUT,
    }),
  email: requiredString()
    .email(VALIDATION_MESSAGES.INVALID_EMAIL),
  phone: requiredString(),
  product: selectOption().nullable().refine((opt) => opt !== null, {
    message: VALIDATION_MESSAGES.REQUIRED_FIELD,
  }),
  appointmentDate: requiredString(),
  appointmentTime: selectOption(),
});

export type DeriveCustomerValues =
  z.infer<typeof deriveCustomerValidationSchema>;

export function useDeriveCustomerForm(setError: UseFormSetError<DeriveCustomerValues>) {
  const { mutate } = useMutation({
    mutationFn: async (payload: DeriveCustomerPayload) => createCustomerDerivation(payload),
    onSuccess: () => {
      const SUCCESSFUL_DERIVATION_PATH = '/customer/successful-derivation';
      window.location.href = SUCCESSFUL_DERIVATION_PATH;
    },
    onError: (err: AxiosError<DerivationPossibleErrors>) => {
      const rutError = err.response?.data.rut?.[0];

      if (rutError) {
        setError('rut', {
          message: rutError,
        });
      }
    },
  });

  const mapFormToPayload = (data: DeriveCustomerValues) => {
    const {
      appointmentDate,
      appointmentTime,
      company,
      email,
      firstName,
      lastName,
      phone,
      product,
      rut,
    } = data;
    const payload: DeriveCustomerPayload = {
      name: firstName,
      last_name: lastName,
      rut,
      phone_number: phone,
      email,
      // Always will be available because product field was already validated in zod schema
      product: product!.value as CompanyProduct,
      company,
      appointment_date: appointmentDate,
      appointment_time: appointmentTime.value,
    };

    return payload;
  };

  const handleSubmit = (values: DeriveCustomerValues) => {
    const gtmTagsByCompany = {
      [COMPANIES.INVESTMENTS]: 'deriva_bi',
      [COMPANIES.LIFE]: 'deriva_bv',
    };

    const payload = mapFormToPayload(values);
    mutate(payload);
    tag({
      event: EVENTS.SUBMIT,
      variables: {
        id: gtmTagsByCompany[values.company],
      },
    });
  };

  return { handleSubmit };
}
